import { Alert, Button, Divider, Popover, Space } from "antd";
import { useState, useEffect } from "react";
import { notificationController } from "controllers/notificationController";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Company, Representative } from "./types";
import { Link } from "react-router-dom";
import { doList } from "store/slices/companySlice";
import { doList as doListReps } from "store/slices/representativeSlice";
import { CommentsModal } from "./CommentsModal";
import { RepsList } from "./RepsList";
import { Modal } from "../common/Modal/Modal.styles";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions"
import { statusUpdate } from "api/company.api";
import { sendNotificationError } from "utils/Status&ErrorMessage";
import { UserRoles } from "constants/roles";

const {writeCompanies} = PermissionsToAccess

type OptionProps = {
  rowSelected: Company;
  closeDrawer?: (arg: boolean) => void;
}
export const OptionsDrawerBar = ({ rowSelected, closeDrawer }: OptionProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { status: repstatus, representatives } = useAppSelector(
    (state) => state.representative
  );
  const [unverifiedReps, setUnverifiedReps] = useState<Representative[]>([]);
  const [showRepsList, setShowRepsList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusCompany, setStatusCompany] = useState<string>("");
  const [active, setActive] = useState(false);

  const toggle = () => {
    setActive(!active);
  };

  const toggleModal = () => [setShowRepsList(!showRepsList)];

  const areUnverifiedReps = () => {
    const reps = representatives.filter(
      (rep) => rep.companyId === rowSelected._id
    );
    if (reps.length === 0) {
      toggleModal();
      return true;
    }
    const notVerifiedReps = reps.filter(
      (compRep) => compRep.status !== "Verificada"
    );
    if(notVerifiedReps.length > 0) {
      toggleModal();
      return true;
    }
    return false;
  };

  const handleStatusChange = (status: string) => {
    setStatusCompany(status);
    if (status === "Verificada") {
      verifyCompany();
    }
    if (status === "Rechazada") {
      toggle();
    }
  };

  const verifyCompany =  () => {
    setLoading(true);
    if (areUnverifiedReps()) {
      setLoading(false);
      return false;
    }
    setShowRepsList(false);
    const new_status = {
      id: rowSelected._id ?? "",
      status: "Verificada",
      comment: "Empresa verificada",
    }
    statusUpdate(new_status)
      .then(() => {
        notificationController.success({
          message: 'Empresa Actualizada',
        });
        if (closeDrawer) {
          closeDrawer(false);
        }
      })
      .catch((error) => {
        sendNotificationError({ error });
      });
    dispatch(doList());
    setLoading(false);
  };

  useEffect(() => {
    if (repstatus !== `fulfilled`) {
      dispatch(doListReps());
    }
  }, [dispatch, repstatus, representatives]);

  useEffect(() => {
    const reps = representatives.filter(
      (rep) => rep.companyId === rowSelected._id
    );
    const notVerifiedReps = reps.filter(
      (compRep) => compRep.status !== "Verificada"
    );
    setUnverifiedReps(notVerifiedReps);
  }, [representatives]);

  return (
    <>
      <Space>
       {user?.role && user.role === UserRoles.AdminRole &&( <>
       {rowSelected.status !== "Verificada" && (
          <Popover
            content={
              <Button
                type="primary"
                style={{ backgroundColor: "#329213" }}
                block
                loading={loading}
                onClick={() => {
                  handleStatusChange("Verificada");
                }}
              >
                Confirmar
              </Button>
            }
            trigger="click"
          >
            <Button type="primary" style={{ backgroundColor: "#3fb618" }} block>
              Verificar
            </Button>
          </Popover>
        )}
        {rowSelected.status !== "Rechazada" && (
          <Button
            type="primary"
            danger
            block
            onClick={() => handleStatusChange("Rechazada")}
          >
            Datos incompletos
          </Button>
        )}
        </>)}
        {CheckAccess(writeCompanies) && (
          <Link to={`/empresas/editar/${rowSelected._id}`}>
            <Button type="primary">Editar</Button>
          </Link>
        )}
      </Space>
      <CommentsModal
        active={active}
        toggle={toggle}
        selectedRow={rowSelected}
        statusCompany={statusCompany}
        close={closeDrawer}
      ></CommentsModal>
      <Modal
        title="No se puede verificar la empresa"
        open={showRepsList}
        onOk={() => {
          handleStatusChange("Verificada");
        }}
        onCancel={() => toggleModal()}
        destroyOnClose
      >
        <p>
          No se puede verificar la empresa si sus representantes o contactos no
          están verificados
        </p>
        {unverifiedReps.length > 0 || repstatus != 'fulfilled' ? (
          <>
            <Divider>Representantes o contactos no verificados</Divider>
            <RepsList
              reps={unverifiedReps}
              showAddRep={false}
              idCompany={rowSelected}
              type={"Representative"}
            />
          </>
        ) : representatives.length > 0 ? 
          <>
            <Divider></Divider>
            <Alert
              description="No quedan mas representantes por modificar"
              type="info"
              showIcon
            />
          </> : (
          <>
            <Divider></Divider>
            <Alert
              message="Advertencia"
              description="La empresa no tiene representantes o contactos"
              type="warning"
              showIcon
            />
          </>
        )
      }
      </Modal>
    </>
  );
};
