import { httpApi } from 'api/http.api';
import { AgreementsByMunicipality as AgreementsByMunicipalityT } from 'components/Agreements/types';
import { Company } from 'components/companies/types';

const controller = `statistic`;

export type CompanyStatistics = {
  _id: string;
  rejected: number;
  active: number;
  pending: number;
  draft: number;
  company: Company;
};
export type SubWorkCStatistics = {
  _id: string;
  rejected: number;
  active: number;
  pending: number;
  draft: number;
};

export type studentWithEducativeLevel = {
  _id: string;
  name: string;
  paternalLastname: string;
  maternalLastname: string;
  curp: string;
  academicStatus: string;
  workcenterName: string;
  workcenterNameShort: string;
  educativeLevel: string;
};
export type StudentsByEducativeLevel = {
  educativeLevel: string;
  waitingCount: number;
  activeCount: number;
  graduatedCount: number;
  declinedCount: number;
  totalCount: number;
};

export const companiesStatistics = (): Promise<CompanyStatistics[]> =>
  httpApi
    .get<CompanyStatistics[]>(`${controller}/companiesStatistics`)
    .then(({ data }) => data);

export const subsystemWorkCStatistics = (): Promise<SubWorkCStatistics[]> =>
  httpApi
    .get<SubWorkCStatistics[]>(`${controller}/subsystemWorkCStatistics`)
    .then(({ data }) => data);

export const workCStatistics = (): Promise<SubWorkCStatistics[]> =>
  httpApi
    .get<SubWorkCStatistics[]>(`${controller}/workCStatistics`)
    .then(({ data }) => data);

export const studentsByEducativeLevel = (): Promise<
  StudentsByEducativeLevel[]
> =>
  httpApi
    .get<StudentsByEducativeLevel[]>(
      `${controller}/studentsByEducativeLevel`
    )
    .then(({ data }) => data);

export const agreementsByMunicipality = (): Promise<AgreementsByMunicipalityT[]> =>
  httpApi
    .get<AgreementsByMunicipalityT[]>(`${controller}/agreementsByMunicipality`)
    .then(({ data }) => data);
