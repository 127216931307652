/*eslint valid-typeof: off*/
import { useState, useEffect, useCallback } from "react";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Select, Row, Col, Form, Divider, Input, Progress, Tooltip, Spin } from "antd";
import { doList as doCompanyList } from "store/slices/companySlice";
import { notificationController } from "controllers/notificationController";
import * as S from "../Agreements.styles";
import { STUDENTS_PATH } from "components/router/AppRouter";
import { Representative } from "components/companies/types";
import { getById } from "api/agreements.api";
import { OptionsSelect } from "types/optionsSelect";
import { Agreement, initialValues as AgreementInit} from "components/Agreements/types";
import { initialValues } from "../../companies/types";
import { useNavigate } from "react-router-dom";
import { doPatch } from "store/slices/agreementSlice";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Document } from "components/common/Document/Document";
import { doList as pricipalDoList } from "store/slices/employeeSlice";
import { doList as repsDoList } from "store/slices/representativeSlice";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { IsAdultStudent, agreementFormSetter, handleTimeChange, validAgreementDates } from "../utils/utils";
import { escapeRegExp } from "lodash";
import { fileList, getColabFiles, getFilesLearningAgreement } from "../utils/fileList";
import { LaptopFields } from "../utils/LaptopFields";
import { TimeFields } from "../utils/timeFields";
import { RotationFields } from "../utils/RotationFields";
import { FileChangesType } from "types";
import RequiredFieldsCheckList from "../RequiredFieldsCheckList";
import { WorkCenter } from "components/workcenters/types";
import statusMessages from "statusMessages";
import { ApiError } from "types";
import * as Sentry from '@sentry/react';
import { UserRoles } from "constants/roles";
import { Student } from "components/students/types";

type EditAgreementProps = { agreementId: { id: string } };
dayjs.extend(customParseFormat);
export const EditAgreement = ({ agreementId }: EditAgreementProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [agreementStatus, setAgreementStatus] = useState<string>("empty")
  const [isLoading, setLoading] = useState(false);
  // Inital Data
  const { companies, status: companyStateStatus } = useAppSelector((state) => state.company);
  const { representatives, status: repsStateStatus } = useAppSelector((state) => state.representative);
  const { user } = useAppSelector((state) => state.user)
  // WorkCenter
  const [selectedWorkCenter, setSelectedWorkCenter] = useState<WorkCenter>();
  const [selectedStudent, setSelectedStudent] = useState<Student>();
  const [optionWorkCenter, setOptionWorkCenter] = useState<OptionsSelect[]>([]);
  const [valueSubSystem, setValueSubSystem] = useState("");

  const [agreement, setAgreement] = useState<Agreement | null>(null);
  // WorkCenter
  const [optionStudents, setOptionStudents] = useState<OptionsSelect[]>([]);
  // Company
  // Select for Compnay
  const [optionsCompany, setOptionsCompany] = useState<OptionsSelect[]>([]);
  const [optionsEmployee, setOptionsEmployee ] = useState<OptionsSelect[]>([]);
  const [companyContacts, setCompanyContacts] = useState<Representative[]>([]);
  const [companyValue, setCompanyValue] = useState<string>();
  const [optionsCompanyContacts, setOptionsCompanyContacts] = useState<
    OptionsSelect[]
  >([]);
  const [companyLegalReps, setCompanyLegalReps] = useState<Representative[]>([]);
  const [optionsCompanyLegalReps, setOptionsCompanyLegalReps] = useState<
    OptionsSelect[]
  >([]);

  // Documents
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");

  // show laptop fields
  const [visibleLaptopFields, setVisibleLaptopFields] = useState(false);

  // label and display if adult or not
  const [INETutorEstudiante, setINELabel] = useState("");
  const [letterDisplay, setLetterDisplay]  = useState("");
  const [isAdultStudent, setIsAdultStudent] = useState(false)

 //progress bar
 const [percent, setPercent] = useState<number>(0);
 const [fieldsForProgress, setFieldsForProgress] = useState<Agreement>(AgreementInit);

 // track changes
 const [filesChanges, setfilesChanges] = useState<FileChangesType|null>(null);

 // total hours
 const [totalHours, setTotalHours] = useState<string>("00:00");

  useEffect(() => {
    if (status === 'empty'){
      dispatch(pricipalDoList())
    }
  }, [status, dispatch])

  useEffect(() => {
    if (companyStateStatus !== 'fulfilled'){
      dispatch(doCompanyList());
    }
    if ( repsStateStatus !== `fulfilled` ){
      dispatch(repsDoList())
    }
  },[companyStateStatus, dispatch, repsStateStatus]);

  useEffect(() => {
    getById(agreementId.id).then((agreementData) => {
      setAgreementStatus("fullfilled");
      setAgreement(agreementData);
    }).catch((error) => {
      setAgreementStatus("error");
      const currentStatus = (error as ApiError).statusCode;
      notificationController[currentStatus === 404 ? "info" : "error"]({
        message: statusMessages.agreement[currentStatus || 500] as string,
        duration: 0,
      });
      Sentry.captureException(error);
    })
  }, [agreementId]);

  useEffect(() => {
    if (agreement) {
      ifAdultChangeFiles(agreement.studentId);
      setValueSubSystem(agreement.workCenterId ? agreement.workCenterId.subsystemShort : "");
      form.setFieldValue("educativeLevel", agreement.educativeLevel);
      if( agreement.studentId ){
        setOptionStudents([{
          label: `${agreement.studentId.name}${
          agreement.studentId.paternalLastname ? " " + agreement.studentId.paternalLastname : ""}${
          agreement.studentId.maternalLastname ? " " + agreement.studentId.maternalLastname : ""}`,
          value: agreement.studentId._id as string,
          key: agreement.studentId._id,
        },])
      }
      setOptionsEmployee([{
        label: agreement.principal?.name ?? "",
        value: agreement.principal?._id as string,
        key: agreement.principal?._id,
      },]);
      setSelectedWorkCenter(agreement.workCenterId);
      setSelectedStudent(agreement.studentId);
      setOptionWorkCenter([{
        label: agreement.workCenterId.name,
        value: agreement.workCenterId._id as string,
        key: agreement.workCenterId._id,
      },]);
      if(agreement.companyId){
        setOptionsCompany([{
          label: agreement.companyId.name,
          value: agreement.companyId._id as string,
          key: agreement.companyId._id,
        },]);
      }
      
      if (agreement.companyId !== undefined && companies !== undefined) {
          const company = companies.find(
              (company) => company === agreement.companyId
          );
          if (company !== undefined) setCompanyValue(company?._id);
      }

      if (agreement.legalRepresentativeId && agreement.legalRepresentativeId.name) {
        setOptionsCompanyLegalReps([{
            label: `${agreement.legalRepresentativeId.name}`,
            value: agreement.legalRepresentativeId._id as string,
            key: agreement.legalRepresentativeId._id,
          }]);
      } else if(agreement.companyId){
        setCompanyLegalReps(representatives.filter((r) => (r.companyId === agreement.companyId._id) && r.type === "Representative"))
      }
      if (agreement.representativeId && agreement.representativeId.name) {
        setOptionsCompanyContacts([{
            label: `${agreement.representativeId.name}`,
            value: agreement.representativeId._id as string,
            key: agreement.representativeId._id,
          },]);
      }
      handleCompanyChange(agreement?.companyId?._id as string ?? '');
      agreementFormSetter(agreement, form)
      handleTimeChange(form,setTotalHours);
      setFieldsForProgress(agreement)
    }
  }, [agreement]);

  useEffect(() => {
    showLaptopFields()
  }, [user])

  useEffect(() => {
    CheckProgress();
  }, [agreement, fieldsForProgress, isAdultStudent]);

  //progress bar
  const CheckProgress = () => {
    let newProgress = percent;
    let numerator = Object.values(fieldsForProgress).filter(Boolean).length
    if (isAdultStudent && !agreement?.tutorAuthLetterFileId) {
      numerator++;
    }
    newProgress = Math.round(
      (numerator / Object.keys(fieldsForProgress).length) * 100
    );
    setPercent(newProgress);
  };

  const handleFormChange = (
      field:   string, value:   string | Date | undefined | null | number | Representative[], 
      field2?: string, value2?: string | Date | undefined | null | number
    ) => {
      if(field2 !== undefined && value2 !== undefined) {
        setFieldsForProgress({ ...fieldsForProgress, [field]: value, [field2]: value2 });
      } else {
        setFieldsForProgress({ ...fieldsForProgress, [field]: value, });
      }
  };

  const handleTimeFieldsChange = (field: string, value: string | Date | undefined) => {
    handleTimeChange(form,setTotalHours);
    handleFormChange(field, value)
  }

  const showLaptopFields = () => {
    let show = false
    if(!user){
      setVisibleLaptopFields(show);
      return false;
    }
    if (user.role && user.role === UserRoles.AdminRole) {
      show= true
    }
    if(user.approvementRole && user.approvementRole?.sequence > 1) {
      show= true
    }
    setVisibleLaptopFields(show);
  }

  // files

  const onShowDocument = (id: string) => {
    setPdfViewerId(id);
    setShowDocument(true);
  };

  const onUpload = (field: string, id: string | null) => {
    form.setFieldValue(`${field}`, id);
    handleFormChange(field ?? "", id);
    setfilesChanges({ ...filesChanges, [field]: id });
  };
  const onUploadNoProgress = (field: string, id: string | null) => {
    form.setFieldValue(`${field}`, id);
    setfilesChanges({ ...filesChanges, [field]: id });
  };
  // Select Functions

  const handleSubmit = (values: Agreement) => {
    const educativeLevel = form.getFieldValue('educativeLevel')
    if (!educativeLevel) {
      notificationController.error({
        message: 'El plantel no tiene nivel educativo asignado',
      });
      return;
    }
    if (
      !values.startDate ||
      !values.endDate ||
      !validAgreementDates(
        (values.startDate as Date).toISOString(),
        (values.endDate as Date).toISOString()
      )
    ) {
      setLoading(false);
      notificationController.error({
        message: 'Fechas no validas',
        description:
        'Asegúrate de que la fecha de finalización no sea anterior a la fecha de inicio'
      });
      return;
    }
    let changedValues = form.getFieldsValue(true, meta => meta.touched);
    changedValues = {
      ...changedValues,
      ...filesChanges,
    }
    
    const sendPayload = {
      changedValues,
      agreement: { ...values, ...{ minimumRequiredFieldsPercent: percent }}
    }
    dispatch(doPatch(sendPayload))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Expediente editado correctamente",
        });
        setLoading(false);
        navigate(STUDENTS_PATH);
      })
      .catch((error) => {
        setLoading(false);
        const currentStatus = (error as ApiError).statusCode;
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.agreement[currentStatus || 500] as string,
        });
      });
  };

  const ifAdultChangeFiles = useCallback((selectedStudent: Student) => {
    let ineLabel = "Identificación del padre o tutor vigente";
    let display = "";
    let isAdultStudent = false;
    if (IsAdultStudent(selectedStudent)) {
      ineLabel = "Identificación del estudiante mayor de edad";
      isAdultStudent = true;
    }
    if (isAdultStudent && !agreement?.tutorAuthLetterFileId) {
      display = "none";
    }
    setINELabel(ineLabel);
    setLetterDisplay(display);
    setIsAdultStudent(isAdultStudent);
  }, [form]);

  const checkStudentWorkcMatch = () => {
    const studentId = form.getFieldValue("studentId");
    const workc = form.getFieldValue("workCenterId");
    if(studentId === agreement?.studentId._id && agreement!.studentId.workcenter._id === workc) {
      return Promise.resolve();
    }
    if(!selectedStudent || selectedStudent.workcenter._id !== workc){
      return Promise.reject(new Error("El estudiante y el plantel no coinciden"));
    }
    // return true;
    return Promise.resolve();
  };

  // Company

  useEffect(() => {
    const optionsRaw: OptionsSelect[] = companies.map((company) => ({
      label: company.name!,
      value: company._id!,
      key: company._id!,
    }));
    setOptionsCompany(optionsRaw);
  }, [companies]);

  useEffect(() => {
    const optionsRaw: OptionsSelect[] = companyContacts.map((rep) => ({
      label: `${rep.name}`,
      value: rep._id!,
      key: rep._id!,
    }));
    const optionsRawReps: OptionsSelect[] = companyLegalReps.map((rep) => ({
      label: `${rep.name}`,
      value: rep._id!,
      key: rep._id!,
    }));
    const selectedComp = form.getFieldValue('companyId');
    if((agreement?.companyId?._id ?? '') !== selectedComp) {
      handleFormChange('representativeId', '', 'legalRepresentativeId', '')
    }
    setOptionsCompanyContacts(optionsRaw);
    setOptionsCompanyLegalReps(optionsRawReps);
  }, [companyContacts, companyLegalReps]);

  useEffect(() => {
      if (optionsCompanyContacts.length > 0) {
        const contactValue =(optionsCompanyContacts[0].value)
   

          handleFormChange('representativeId', contactValue);
          form.setFieldValue(
              'representativeId',
              contactValue
          );
      }
      if (optionsCompanyLegalReps.length > 0) {
        const legalValue =(optionsCompanyLegalReps[0].value)
          handleFormChange(
              'legalRepresentativeId',
              legalValue
          );
          form.setFieldValue(
              'legalRepresentativeId',
              legalValue
          );
      }
  }, [companyValue, optionsCompanyContacts, optionsCompanyLegalReps]);

  const handleCompanyChange = (value: string) => {
    if((agreement?.companyId?._id ?? '') !== value) {
      form.setFieldValue('representativeId', null)
      form.setFieldValue('legalRepresentativeId', null)
    }
    handleFormChange('companyId', value)
    setCompanyContacts(representatives.filter((r) => (r.companyId === value) && r.type === "Contact"));
    setCompanyLegalReps(representatives.filter((r) => (r.companyId === value) && r.type === "Representative"))
  };

  const showAllCompanyOptions = () => {
    if(optionsCompany.length <= 1) {
      const newOptions = companies.map((company) => ({
      label: company.name!,
      value: company._id!,
      key: company._id!,
    }));
    setOptionsCompany(newOptions);
    }
  }

  const filterCompany = (companyName: string) => {
    const escapedCompanyName = escapeRegExp(companyName);
    const inputString= new RegExp(`${escapedCompanyName}`, 'gi');
    const newOptions = companies.filter(value => inputString.test(value.name)).map((company) => ({
      label: company.name!,
      value: company._id!,
      key: company._id!,
    }));
    setOptionsCompany(newOptions);
  }

  return (
    <>
      {(agreement && agreementStatus === "fullfilled") ? (
        <S.Wrapper>
          <S.SmallCard
            id="agreement-edit"
            title={
              <>
                <CloseButton onClick={()=>{navigate(STUDENTS_PATH)}}></CloseButton>
                <span>&nbsp; {`Editar Expediente`}</span>
              </>
            }
            padding="3rem"
          >
          <Tooltip title={percent === 100 ? "Campos completos" : "Campos incompletos"}>
            <Progress percent={percent} />
          </Tooltip>
            <BaseForm
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              requiredMark="optional"
              initialValues={initialValues}
            >
              <BaseForm.Item name="id" hidden />
              {/* Plantel */}
              <Row>
                <Col style={{ marginRight: 25 }}>
                  <BaseForm.Item label={`Subsistema / IES`}>
                    <Input
                      style={{ width: 200 }}
                      value={valueSubSystem}
                      placeholder="Sub-Sistema / IES"
                      disabled
                    />
                  </BaseForm.Item>
                </Col>
                <Col flex={1} style={{ marginRight: 20 }}>
                  <BaseForm.Item
                    name="workCenterId"
                    label={`Plantel`}
                    dependencies={['studentId']}
                    rules={[
                      { required: true, message: "Este campo es obligatorio" },
                      { validator: checkStudentWorkcMatch },
                    ]}
                  >
                    <Select
                      style={{ width: '100%' }}
                      options={optionWorkCenter}
                      disabled
                    />
                  </BaseForm.Item>
                </Col>
                <Col style={{ marginRight: 20 }}>
                  <BaseForm.Item
                    required
                    name="educativeLevel"
                    label={`Nivel educativo`}
                    rules={[
                      {
                        required: true,
                        message: 'El plantel no tiene un nivel educativo asignado',
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '100%' }}
                      disabled
                    />
                  </BaseForm.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                <BaseForm.Item
                  name="principal"
                  label="Director(a) del Plantel Firmante"
                  rules={[{ required: true, message: "Este campo es obligatorio" }]}
                >
                  <Select
                    placeholder="Director(a) del plantel"
                    options={optionsEmployee}
                    disabled
                  />
                </BaseForm.Item>
                </Col>
                <Col flex={1} style={{ marginLeft: 20 }}>
                <BaseForm.Item
                  name="principalPosition"
                  label="Puesto del director(a) firmante"
                >
                  <Input
                    placeholder="Encargado(a) de la Dirección del Plantel"
                  />
                </BaseForm.Item>
                </Col>
              </Row>
              {/* Student Data  */}
              <BaseForm.Item
                name="studentId"
                label={`Alumno`}
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]}
              >
                <Select
                  options={optionStudents}
                  disabled
                />
              </BaseForm.Item>
              {/* Company Data  */}
              <Row>
                <Col flex={3} style={{ marginRight: 25 }}>
                  <BaseForm.Item label={`Razón social`} required>
                    <Row>
                      <Col style={{ marginRight: 10 }} flex={1}>
                        {/* <Debounce /> */}
                        <BaseForm.Item
                          name="companyId"
                          rules={[
                            {
                              required: true,
                              message: "Este campo es obligatorio",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            value={companyValue}
                            onSearch={filterCompany}
                            onDropdownVisibleChange={showAllCompanyOptions}
                            onChange={(e)=>handleCompanyChange(e)}
                            placeholder="Selecciona un Empresa"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ minWidth: 350 }}
                            options={optionsCompany}
                          />
                        </BaseForm.Item>
                      </Col>
                    </Row>
                  </BaseForm.Item>
                </Col>
                <Col flex={2} style={{ marginRight: 10 }} >
                  <BaseForm.Item
                    name="representativeId"
                    label={`Contacto de la Empresa`}
                    rules={[
                      { required: true, message: "Este campo es obligatorio" },
                    ]}
                  >
                    <Select
                      key="CompanyContactSelect"
                      value={companyContacts}
                      options={optionsCompanyContacts}
                      onChange={(e) => handleFormChange('representativeId', e)}
                    />
                  </BaseForm.Item>
                </Col>
                <Col flex={1}>
                  <BaseForm.Item
                    name="legalRepresentativeId"
                    label={`Representante legal de la Empresa`}
                  >
                    <Select
                      key="CompanyRepSelect"
                      value={companyLegalReps}
                      options={optionsCompanyLegalReps}
                      onChange={(e) => handleFormChange('legalRepresentativeId', e)}
                    />
                  </BaseForm.Item>
                </Col>
              </Row>
              <TimeFields handleTimeFieldsChange={handleTimeFieldsChange} totalHours={totalHours}/>
              {visibleLaptopFields && (
                <LaptopFields
                  onUpload={onUploadNoProgress}
                  onShowDocument={onShowDocument}
                  form={form}
                />
              )}
              <Row>
                <Col>
                  <Divider orientation="left">Convenio de Colaboración</Divider>
                  {fileList(getColabFiles(agreement), onShowDocument, onUpload, form)}
                </Col>
                <Col>
                <Divider orientation="left">Convenio de Aprendizaje</Divider>
                {fileList(
                    getFilesLearningAgreement(agreement, letterDisplay, INETutorEstudiante, selectedWorkCenter),
                    onShowDocument,
                    onUpload,
                    form
                  )}
                  <RotationFields onChange={handleFormChange}/>
                </Col>
              </Row>
              <BaseForm.Item noStyle>
                <S.SubmitButton
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoading}
                >
                  { `Actualizar` }
                </S.SubmitButton>
              </BaseForm.Item>
              <Row>
                <Col sm={24}>
                  <RequiredFieldsCheckList isAdult={isAdultStudent} agreement={agreement} />
                </Col>
              </Row>
            </BaseForm>
          </S.SmallCard>

          <Document
            fileId={pdfViewerId}
            open={showDocument}
            onCancel={() => {
              setShowDocument(false)
              setPdfViewerId("");
            }}
            onOk={() => {
              setShowDocument(false)
              setPdfViewerId("");
            }}
          />
        </S.Wrapper>
      ):
      ((agreementStatus !== "error") ? (
        <>
          <S.Wrapper
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin tip="Cargando" size="large" delay={500}></Spin>
          </S.Wrapper>
          <div>Cargando Expediente</div>
        </>
      ) : (
        <div>Ocurrió un error al intentar obtener el expediente</div>
      )
      )}
    </>
  );
};
