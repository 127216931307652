import { Descriptions, Drawer } from "antd";
import { Subsystem, initialValues } from "./types";
import { OptionsDrawerBar } from "./OptionsDrawer";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import ListCycles from "components/cycles/ListCycles";
import ListCoursesRules from "components/course-rules/ListCourseRules";
import ListCourses from "components/course/ListCourses";

const { writeSubsystems } = PermissionsToAccess;

type SubsystemDetailProps = {
  selectedRow: Subsystem | null;
  onClose: () => void;
  open: boolean;
}

export const SubsystemDetail = ({
  selectedRow,
  open,
  onClose,
}: SubsystemDetailProps) => {
  return (
    <Drawer
      title={`Detalles del subsistema / IES`}
      placement="right"
      open={open}
      destroyOnClose
      onClose={onClose}
      width={`50%`}
      extra={
        CheckAccess(writeSubsystems) && (
          <OptionsDrawerBar rowSelected={selectedRow ?? initialValues} />
        )
      }
    >
      {selectedRow && selectedRow._id &&(
        <>
          <Descriptions bordered size="small">
            <Descriptions.Item key="name" label="Nombre completo" span={3}>
              {selectedRow.name}
            </Descriptions.Item>
            <Descriptions.Item key="nameShort" label="Nombre corto" span={3}>
              {selectedRow.nameShort}
            </Descriptions.Item>
            <Descriptions.Item
              key="principal"
              label="Director(a) general "
              span={3}
            >
              {selectedRow.principal}
            </Descriptions.Item>
          </Descriptions>
          <ListCycles subsystemId={selectedRow._id} />
          <ListCoursesRules
            subsystemId={selectedRow._id}
          />
          <ListCourses subsystemId={selectedRow._id} />
        </>
      )}
    </Drawer>
  );
};
