import { Button, Descriptions, Drawer } from "antd";
import { RepsList } from "./RepsList";
import { Company, initialValues, Representative } from "./types";
import { doList as repsDoList } from "store/slices/representativeSlice";
import { Document } from "components/common/Document/Document";
import { useEffect, useState } from "react";
import { getStatusLights } from "./utils";
import { OptionsDrawerBar } from "./OptionsDrawerComp";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks"
import { CommentsOptions } from "./CommentsOptions";
import { ComentsCompany } from './ComentsCompany';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";


type CompanyDetailProps = {
  selectedRow: Company | null;
  onClose: () => void;
  open: boolean;
}

export const CompanyDetail = ({
  selectedRow,
  open,
  onClose,
}: CompanyDetailProps) => {
  const dispatch = useAppDispatch();
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");
  const [ companyReps, setCompanyReps ] =  useState<Representative[]>([]);
  const [ companyContacts, setCompanyContacts ] =  useState<Representative[]>([]);
  const { representatives, status: repsStateStatus } = useAppSelector((state) => state.representative);

  useEffect(() => {

    if ( repsStateStatus !== `fulfilled` ){
      dispatch(repsDoList())
    } else {
      const currentReps: Representative[] = representatives.filter((r) => (r && r.companyId === selectedRow?._id) && r.type === "Representative");
      setCompanyReps(currentReps);
      const currentContacts: Representative[] = representatives.filter((r) => (r && r.companyId === selectedRow?._id) && r.type === "Contact");
      setCompanyContacts(currentContacts);
    }
  },[dispatch, representatives, repsStateStatus, selectedRow]);

  return (
    <Drawer
      title={`Perfil de la empresa`}
      placement="right"
      open={open}
      destroyOnClose
      onClose={onClose}
      width={`50%`}
      extra={
        <OptionsDrawerBar
          rowSelected={selectedRow ?? initialValues}
          closeDrawer={onClose}
        />
      }
    >
      {selectedRow && (
        <>
          <Descriptions bordered size="small">
            <Descriptions.Item key="name" label="Razón social">
              {selectedRow.name}
            </Descriptions.Item>
            <Descriptions.Item key="taxId" label="RFC">
              {selectedRow.taxId}
            </Descriptions.Item>
            <Descriptions.Item key="status" label="Estado">
              <p style={{ color: getStatusLights(selectedRow.status) }}>
                {selectedRow.status}
              </p>
            </Descriptions.Item>
            <Descriptions.Item
              key="commercialName"
              label="Nombre comercial "
              span={2}
            >
              {selectedRow.commercialName}
            </Descriptions.Item>
            <Descriptions.Item
              key="industry"
              label="Sector productivo "
              span={2}
            >
              {selectedRow.industry}
            </Descriptions.Item>
            <Descriptions.Item
              key="representatives"
              label="Representantes"
              span={3}
            >
              <RepsList
                idCompany={selectedRow}
                reps={companyReps}
                showAddRep={false}
              />
            </Descriptions.Item>
            <Descriptions.Item
              key="Contacts"
              label="Contactos de la empresa"
              span={3}
            >
              <RepsList
                idCompany={selectedRow}
                reps={companyContacts}
                showAddRep={false}
              />
            </Descriptions.Item>
            <Descriptions.Item key="files" label="Archivos">
            { selectedRow.incFileId && selectedRow.incFileId !== "" ? (
            <Button
            type='link'
            onClick={() => {
              setPdfViewerId(selectedRow.incFileId as string);
              setShowDocument(true);
            }}
            icon={<FontAwesomeIcon
              icon={faFilePdf}
              style={{ color: "#ce0e2c", marginRight: '5px' }}
            />}
          >
            Acta Constitutiva
          </Button>
          ) : (
            <>No se ha cargado el Acta Constitutiva</>
          )}
            </Descriptions.Item>
            <Descriptions.Item key="files">
            { selectedRow.fiscalFileId && selectedRow.fiscalFileId !== "" ? (
            <Button
            type='link'
            onClick={() => {
              setPdfViewerId(selectedRow.fiscalFileId as string);
              setShowDocument(true);
            }}
            icon={<FontAwesomeIcon
              icon={faFilePdf}
              style={{ color: "#ce0e2c", marginRight: '5px' }}
            />}
          >
            Constancia de situación fiscal
          </Button>
          ) : (
            <>No se ha cargado la Constancia de situación fiscal</>
          )}
            </Descriptions.Item>
          </Descriptions>
          <Document
            fileId={pdfViewerId}
            open={showDocument}
            onCancel={() => setShowDocument(false)}
            onOk={() => setShowDocument(false)}
          />

          <br />
          <Descriptions
            bordered
            size="small"
            title="Comentarios"
            extra={
              <CommentsOptions
                selectedRow={selectedRow}
                closeModal={onClose}
                visibleRegresar={false}
              />
            }
          ></Descriptions>
          {selectedRow.comments && <ComentsCompany selectedRow={selectedRow} />}
        </>
      )}
    </Drawer>
  );
};
