import React, { useState, useEffect } from "react";
import * as S from "../Student.styles";
import { useNavigate, Link } from "react-router-dom";
import {
  Student,
  newAcademicStatus,
  geneders,
  gradesString,
  initialValues,
  patternCurp,
} from "../types";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Col, DatePicker, Input, Row, Select, Form, Button } from "antd";
import { doRegister } from "store/slices/studentSlice";
import { notificationController } from "controllers/notificationController";
import { doList as WorkcenterSub } from "store/slices/workCenterSlice";
import { doList as SubsystemList } from "store/slices/subsystemSlice";
import { WorkCenter } from "../../workcenters/types";
import { checkDuplicated } from "api/student.api";
import { OptionsSelect } from "types/optionsSelect";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { STUDENTS_PATH } from "components/router/AppRouter";
import { escapeRegExp } from "lodash";
import { StudentChangeWorkCenterModal } from "../StudentList/StudentChangeWorkCenterModal";
import { AWSFileUploader } from 'components/common/FileManager/AWSFileUploader';
import { ShowDocumentBtn } from 'components/common/Document/ShowDocumentBtn';
import { Document } from 'components/common/Document/Document';
import statusMessages from "statusMessages";
import { ApiError } from "types";
import { WarningCard } from "../Student.styles";

export const StudentNew: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const { workcenters, status: workcenterStateStatus } = useAppSelector(
    (state) => state.workcenter
  );
  const { subsystems, status: subsystemStateStatus } = useAppSelector(
    (state) => state.susbsystem
  );
  // Data for select
  const [valueSubSystem, setValueSubSystem] = useState("");
  const [options, setOptionWorkCenter] = useState<OptionsSelect[]>([]);
  const [optionsSubsystem, setOptionsSubsystem] = useState<OptionsSelect[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState<WorkCenter[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [duplicatedStudent, setDuplicatedStudent] = useState<
    Student | undefined
  >();
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState('');
  const [isConsentLetterVisible, setIsConsentLetterVisible] = useState(false);

  const HandleOpenModal = (open: boolean) => setOpenModal(open);

  useEffect(() => {
    if (workcenterStateStatus !== "fulfilled") {
      dispatch(WorkcenterSub());
    }
  }, [dispatch, workcenterStateStatus]);
  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(SubsystemList());
    }
    const optionsRaw: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.nameShort ?? " - "} `,
      value: subs.nameShort!,
      key: subs._id!,
    }));
    setOptionsSubsystem(optionsRaw);
  }, [dispatch, subsystemStateStatus, subsystems]);
  useEffect(() => {
    const optionsRaw: OptionsSelect[] = workcenters.map((workCeter) => ({
      label: workCeter.name!,
      value: workCeter._id!,
      key: workCeter._id!,
    }));
    setOptionWorkCenter(optionsRaw);
  }, [workcenters]);

  useEffect(() => {
    if(form.getFieldValue("consentLetterFileId")) {
      setIsConsentLetterVisible(true);
    }
  }, [form]);

  const filterWorkcenter = (workCenterName: string) => {
    const escapedWorkcName = escapeRegExp(workCenterName);
    const inputString = new RegExp(`${escapedWorkcName}`, "gi");
    const newOptions = workcenters
      .filter((value) => inputString.test(value.name))
      .map((workCenter) => ({
        label: workCenter.name!,
        value: workCenter._id!,
        key: workCenter._id!,
      }));
    setOptionWorkCenter(newOptions);
  };

  const changeSubsystem = (subsystemSelected: string) => {
    const optionsRaw: OptionsSelect[] = workcenters
      .filter((workcenter) => workcenter.subsystemShort === subsystemSelected)
      .map((workCenter) => ({
        label: workCenter.name!,
        value: workCenter._id!,
        key: workCenter._id!,
      }));
    setOptionWorkCenter(optionsRaw);
    setValueSubSystem(subsystemSelected);
  };

  const handleSubmit = (values: Student) => {
    setLoading(true);
    dispatch(doRegister(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Alumno registrado con éxito",
        });
        navigate("/alumnos");
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.student[currentStatus || 500] as string,
        });
        setLoading(false);
      });
  };

  const checkDuplicatedStudent = async (dataToCheck: string) => {
    await checkDuplicated(dataToCheck)
      .then((res) => {
        if (res.student) {
          setDuplicatedStudent(res.student);
          notificationController.info({
            message: "Este alumno ya se encuentra registrado",
          });
        }
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.student[currentStatus || 500] as string,
        });
      });
  };

  return (
    <>
      <S.Wrapper>
        <S.Card
          id="students-new"
          title={
            <>
              <CloseButton
                onClick={() => {
                  navigate(STUDENTS_PATH);
                }}
              ></CloseButton>
              <span>&nbsp; {`Nuevo Alumno`}</span>
            </>
          }
          padding="1.25rem"
        >
          {duplicatedStudent && (
            <WarningCard>
              <Row style={{ marginBottom: 25 }}>
                <Col>
                  <h3>
                    El alumno ya se encuentra registrado, puedes editarlo o
                    cambiarlo de plantel
                  </h3>
                </Col>
              </Row>
              <Row gutter={25}>
                <Col>
                  <Button type="primary" onClick={() => HandleOpenModal(true)}>
                    Cambiar alumno de plantel
                  </Button>
                  <StudentChangeWorkCenterModal
                    openModal={openModal}
                    OpenModalHanlder={HandleOpenModal}
                    curp={duplicatedStudent.curp}
                  />
                </Col>
                <Col>
                  <Link to={`${STUDENTS_PATH}/edit/${duplicatedStudent._id}`}>
                    <Button type="primary">Editar alumno</Button>
                  </Link>
                </Col>
              </Row>
            </WarningCard>
          )}
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            initialValues={initialValues}
            form={form}
          >
            <Row>
              <Col style={{ marginRight: 25 }}>
                <BaseForm.Item
                  required={false}
                  label={`Subsistema / IES`}
                  name="subsystem"
                >
                  <Select
                    style={{ width: 200 }}
                    value={valueSubSystem}
                    onChange={changeSubsystem}
                    placeholder="Sub-Sistema"
                    options={optionsSubsystem}
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
                <BaseForm.Item
                  name="workcenter"
                  label={`Plantel`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    showSearch
                    value={value}
                    onSearch={filterWorkcenter}
                    onChange={(newValue) => {
                      setValue(newValue as WorkCenter[]);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: "100%" }}
                    options={options}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="name"
                  label={`Nombre(s)`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Input placeholder="Ingrese el nombre" />
                </BaseForm.Item>
              </Col>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="paternalLastname"
                  label={`Apellido paterno`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Input placeholder="Ingrese el apellido" />
                </BaseForm.Item>
              </Col>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="maternalLastname"
                  label={`Apellido materno`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Input placeholder="Ingrese el apellido" />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name="email"
                  label={`Correo Electrónico`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                    {
                      type: "email",
                      message:
                        "Por favor, ingresa un correo electrónico válido",
                    },
                  ]}
                >
                  <Input
                    placeholder="Correo Electrónico"
                    onChange={(e) => checkDuplicatedStudent(e.target.value)}
                  />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name="curp"
                  label={`CURP`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                    {
                      validator: (rule, value) => {
                        if (value && patternCurp.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "Ingresa una CURP válida usando mayúsculas y números"
                        );
                      },
                    },
                  ]}
                >
                  <Input
                    placeholder="A-Z"
                    showCount
                    maxLength={18}
                    onChange={(e) => checkDuplicatedStudent(e.target.value)}
                  />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name="phone"
                  label={`Teléfono`}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es obligatorio",
                    },
                  ]}
                >
                  <Input />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
                <BaseForm.Item
                  name="gender"
                  label={`Genero`}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es obligatorio",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 200 }}
                    options={geneders.map((options) => ({
                      label: options,
                      value: options,
                      key: options,
                    }))}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name="career"
                  label={`Carrera`}
                  rules={[
                    {
                      required: true,
                      message: "Este campo es obligatorio",
                    },
                  ]}
                >
                  <Input placeholder="Escribe tu Carrera" max={60} />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name="grade"
                  label={`Periodo escolar`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    options={gradesString.map((grade) => ({
                      label: grade,
                      value: gradesString.indexOf(grade),
                      key: grade,
                    }))}
                  />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }} flex={1}>
                <BaseForm.Item
                  name="birthDate"
                  label={"Fecha de nacimiento"}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <DatePicker />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
                <BaseForm.Item
                  name="academicStatus"
                  label={`Estado Académico`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    style={{ width: 200 }}
                    options={newAcademicStatus.map((status) => ({
                      label: status,
                      value: status,
                      key: status,
                    }))}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="tutor"
                  label={`Nombre(s) y Apellidos del Tutor`}
                >
                  <Input placeholder="Ingrese el nombre" />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
                <BaseForm.Item
                  name="completionDate"
                  label={"Fecha de finalización Edual"}
                >
                  <DatePicker bordered />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <BaseForm.Item
                  name="consentLetterFileId"
                  label="Carta de consentimiento"
                >
                  {isConsentLetterVisible && ( 
                    <>
                      <ShowDocumentBtn
                        onClick={() => {
                          setPdfViewerId(
                            form.getFieldValue(
                              "consentLetterFileId"
                            ) as string
                          );
                          setShowDocument(true);
                        }}
                        dataCy="consent-letter"
                      />
                      <br />
                    </>
                  )}
                  <AWSFileUploader
                    isReplacing={form.getFieldValue(
                      "consentLetterFileId"
                    )}
                    title="Carta de consentimiento"
                    bucket="consent-letter"
                    onUploadCompleted={(id: string | null) => {
                      form.setFieldValue("consentLetterFileId", id);
                      setIsConsentLetterVisible(true);
                    }}
                    dataCy={`consent-letter-file`}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <BaseForm.Item noStyle>
              <S.SubmitButton
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoading}
              >
                {`Registrar`}
              </S.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
          <Document
            fileId={pdfViewerId}
            open={showDocument}
            onCancel={() => {
              setShowDocument(false);
              setPdfViewerId('');
            }}
            onOk={() => setShowDocument(false)}
          />
        </S.Card>
      </S.Wrapper>
    </>
  );
};
