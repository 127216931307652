import { Button, Space } from "antd";
import { WorkCenter } from "./types";
import { Link } from "react-router-dom";

type OptionProps = {
  rowSelected: WorkCenter;
}
export const OptionsDrawerBar = ({ rowSelected }: OptionProps) => {

  return (
    <>
      <Space>
        <Link to={`/planteles/editar/${rowSelected._id}`}>
          <Button type="primary">Editar</Button>
        </Link>
      </Space>
    </>
  );
};
