import { missingFieldMsg } from "constants/global";

export const seedCourseForm = [
    {
        editable: true,
        label: "Nombre",
        name: "name",
        type: "input",
        rules: missingFieldMsg,
    },
    {
        editable: true,
        label: "Identificador del curso semilla",
        name: "code",
        type: "input",
        rules: missingFieldMsg,
    },
];