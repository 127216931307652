import { Avatar, List, Drawer, Col, Row } from "antd";
import { Agreement, initialValues } from "components/Agreements/types";
import { AgreementDetail } from "components/Agreements/AgreementDetail";
import { OptionsDrawerBar } from "components/Agreements/details/OptionsDrawerBar";
import { useState } from "react";
import { colorCompletedHours, colorStatus } from "components/Agreements/Agreements.styles";
import { getHoursMinutesString } from "hooks/dateTreatment";
import { estimateTotalHours } from "components/Agreements/utils/utils";
import { AgreementStatuses } from "constants/enums/modulesStatuses";
import dayjs from "dayjs";

type Props = {
  agreements: Agreement[];
}

export const AgreementsList = ({ agreements }: Props) => {
  const [selectedRowData, setSelectedRowData] = useState<Agreement>();
  const [open, setOpen] = useState(false);

  // actual hours estimate
  const getActualHoursEstimate = (item: Agreement) => {
    if (!item.hoursPerWeek || !item.startDate) {
      return 0;
    }
    const today = new Date();
    today.setHours(0, 0, 0);
    const hoursMin = estimateTotalHours(
      item.startDate,
      today,
      item.hoursPerWeek
    );
    if (hoursMin !== false) {
      // setHoursEstimate(hoursMin);
      return hoursMin;
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onCloseDrawer = () => {
    setSelectedRowData(initialValues);
    setOpen(false);
  };

  const getStatusLights = (status: string) => {
    switch (status) {
      case AgreementStatuses.CompletedStatus:
        return  "#329213" ;
      case AgreementStatuses.DraftStatus:
        return  "#b36d19";
      case AgreementStatuses.ReviewStatus:
        return  "#FF8C00";
      case AgreementStatuses.CorrectionStatus:
        return  "#cc002e"
      default:
        return "#b36d19"
    }
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={agreements}
        renderItem={(item) => (
          <List.Item
            title={item.status}
            key={item._id}
            onClick={() => {
              setSelectedRowData(item);
              showDrawer();
            }}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{
                    backgroundColor: getStatusLights(item.status),
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {item.sequence}
                </Avatar>
              }
              title={
                <div className='name-student-agreements-list'>
                  {item.studentId
                    ? `${item.studentId.name} ${
                        item.studentId.paternalLastname ?? ""
                      } ${item.studentId.maternalLastname ?? ""}`
                    : "Alumno no encontrado"
                  } - Empresa: {
                    item.companyId ? item.companyId.name : "Empresa no encontrada"
                  }
                </div>
                }
              description={
                <div>
                  <p className='status-date'>
                    <span style={colorStatus(item.status)}>{item.status} </span>
                    {(item.status === AgreementStatuses.CompletedStatus && item.inactiveDate) && (
                      <span>{` - Fecha de baja: ${
                        dayjs(item.endDate).format('DD-MM-YYYY')
                      }`}</span>
                    )}
                  </p>
                  {(item.status === AgreementStatuses.CompletedStatus && item.inactiveDate) ? (
                    <p>
                      <i>Horas completadas:{" "}</i>
                      <span
                        style={colorCompletedHours( item.completedHours ?? 0)}
                      >
                        { item.completedHours ? getHoursMinutesString(item.completedHours) : "Sin registro"}
                      </span>
                    </p>
                  ): (
                    item.startDate &&
                    item.hoursPerWeek && (
                      <p>
                        <i>Horas Actuales:{" "}</i>
                         <span
                          style={colorCompletedHours(
                            getActualHoursEstimate(item)!
                          )}
                        >
                          {getActualHoursEstimate(item)
                            ? getHoursMinutesString(getActualHoursEstimate(item)!)
                            : "Sin registro"}
                        </span>
                      </p>
                    )
                  )}
                  <p><i>Correo: </i>{`${
                    item.studentId ? item.studentId.email : "No encontrado"
                  }`}</p>
                  <p><i>Plantel: </i>{`${
                    item.studentId
                      ? item.studentId.workcenter?.nameShort
                      : "No encontrado"
                  }`}</p>
                  <p><i>Representante: </i>{`${
                    item.representativeId
                      ? item.representativeId.name
                      : "No encontrado"
                  }`}</p>
                </div>
              }
            />
          </List.Item>
        )}
      ></List>

      <Drawer
        title={
          <Row gutter={10} align='middle' justify={'space-between'}>
            <Col span={6}>
              <h4>Detalles del expediente dual</h4>
            </Col>
            <Col span={18}>
              <OptionsDrawerBar
                rowSelected={selectedRowData ?? initialValues}
                closeDrawer={setOpen}
              />
            </Col>
          </Row>
        }
        placement='right'
        width='50%'
        onClose={onCloseDrawer}
        destroyOnClose
        open={open}
      >
        <AgreementDetail
          selectedRow={selectedRowData ?? initialValues}
          closeModalDetails={false}
        />
      </Drawer>
    </>
  );
};
