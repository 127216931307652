import React from "react";
import Chart from "react-apexcharts";

export type seriesType = {
  name: string;
  data: number[];
}

interface ColumnChartProps {
  series: seriesType[];
  categories?: string[];
}

export const ColumnChart: React.FC<ColumnChartProps> = ({
  series,
  categories,
}) => {
  return (
    <Chart
      type="bar"
      series={series}
      height={400}
      options={{
        chart: {
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
                height: 150,
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          type: "category",
          categories: categories,
          labels: {
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: false,
          trim: true,
          minHeight: undefined,
          maxHeight: 130,
          }
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      }}
    />
  );
};
