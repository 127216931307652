
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Space } from "antd";
import { useState } from "react";
import { CommentsModal } from './CommentsModal';
import { Company } from "./types";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

type OptionComentsProps = {
  closeModal?: (arg0: boolean)=>void;
  selectedRow: Company;
  visibleRegresar?: boolean;
}

export const CommentsOptions = ({
  closeModal,
  selectedRow,
  visibleRegresar = true,
}: OptionComentsProps) => {
  const [active, setActive] = useState(false);

  const toggle = () => {
    setActive(!active)
  }

  return (
    <>
      <Space>
        <Button
          key='back'
          type='primary'
          disabled={!visibleRegresar}
          style={{ display: visibleRegresar ? "initial" : "none" }}
          onClick={() => {
            if(closeModal){
              closeModal(false)
            }
          }}
        >
          Regresar
        </Button>
        <Button
          type='primary'
          style={{ backgroundColor: "#3fb618" }}
          icon={
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
          }
          onClick={() => {
            toggle();
          }}
        >
          Agregar
        </Button>
        <CommentsModal
        active={active}
        toggle={toggle}
        selectedRow={selectedRow}
        statusCompany={selectedRow.status}
      ></CommentsModal>
      </Space>
    </>
  );
};
