import React from "react";
import { CSVLink } from "react-csv";
import { LoginReportType } from "../types";

type CsvLoginReportProps = {
  csvReportLinkRef: React.RefObject<
    CSVLink &
      HTMLAnchorElement & {
        link: HTMLAnchorElement;
      }
  >;
  data: LoginReportType[];
}
export const CsvLoginReport = ({
  csvReportLinkRef,
  data,
}: CsvLoginReportProps) => {
  const headers = [
    { label: "Nombre", key: "name" },
    { label: "Correo electrónico", key: "email" },
    { label: "Subsistema", key: "subsystem" },
    { label: "Plantel", key: "WorkcenterName" },
    { label: "Ha ingresado a la plataforma", key: "hasPassword" },
    { label: "Último inicio de sesión", key: "lastLogin" },
    { label: "Fecha de registro", key: "created_at" },
  ];
  return (
    <CSVLink
      data={data}
      headers={headers}
      className="exportButton"
      filename="reporte_inicio_sesion.csv"
      ref={csvReportLinkRef}
    ></CSVLink>
  );
};
