import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import { notificationController } from 'controllers/notificationController';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doSetNewPassword } from 'store/slices/authSlice';
import * as S from './NewPasswordForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { RuleObject } from 'antd/es/form';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}

const initStates = {
  password: '',
  confirmPassword: '',
};

export const NewPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const isStrongPassowrd = (pass: string) => {
    const expRegPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
    if (pass.match(expRegPass)) {
      return true;
    }
    return false;
  };

  const validatePassword = (_: RuleObject, value: string, callback: (message?:string) => void) => {
    if (value && !isStrongPassowrd(value)) {
      callback(
        "Su contraseña debe ser de al menos 8 caracteres, contener al menos una letra minúscula, una letra mayúscula, un número y un carácter especial"
      );
    } else {
      callback();
    }
  };

  const handleSubmit = (values: NewPasswordFormData) => {
    setLoading(true);
    dispatch(doSetNewPassword({ newPassword: values.password }))
      .unwrap()
      .then(() => {
        navigate('/auth/login');
        notificationController.success({
          message: `La contraseña ha sido actualizada`,
          description: `Ingresa con tu nueva contraseña`,
        });
      })
      .catch((err:Error) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initStates}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {`Regresar`}
        </Auth.BackWrapper>
        <Auth.FormTitle>{`Recuperar contraseña`}</Auth.FormTitle>
        <S.Description>{`Ingresa una nueva contraseña`}</S.Description>
        <Auth.FormItem
          name="password"
          label={`Contraseña`}
          rules={[{ required: true, message: `Nueva contraseña` },  { validator: validatePassword }]}
          hasFeedback
        >
          <Auth.FormInputPassword placeholder={`Contraseña`} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label={`Confirmar contraseña`}
          dependencies={['password']}
          rules={[
            { required: true, message: `Campo requerido` },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`Ocurrió un error`));
              },
            }),
          ]}
          hasFeedback
        >
          <Auth.FormInputPassword placeholder={`Confirmar contraseña`} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {`Resetear contraseña`}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
