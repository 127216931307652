import { Card, Col, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { doList } from 'store/slices/agreementSlice';
import { doGroupByRegion } from 'store/slices/municipalitySlice';
import { doListAgreementsByMunicipality } from 'store/slices/statisticsSlice';
import { AgreementStatuses } from 'constants/enums/modulesStatuses';

export const RegionsStatistic = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<{ region: string; students: number }[]>();
  const [totalRows, setTotalRows] = useState(0);

  const {
    agreementsByMunicipality: agreementsByMun,
    agreementsByMunicipalityStatus: agreementsByMunStatus,
  } = useAppSelector((state) => state.statistics);
  const {
    municipalitiesByRegion: municipalities,
    municipalitiesByRegionStatus: municipalityStatus,
  } = useAppSelector((state) => state.municipality);
  const { agreements, status } =
    useAppSelector((state) => state.agreement);

  useEffect(() => {
    if (
      agreements &&
      agreements.length > 0 &&
      municipalities &&
      municipalities.length > 0
    ) {
      setLoading(true);

      const filteredAgreements = agreements.filter(
        (agr) => agr.status !== AgreementStatuses.CorrectionStatus
      );
      let totalCount = 0;
      const filteredData = municipalities.map((munGroup) => {
        const { municipalities, region } = munGroup;
        let count = 0;
        municipalities.forEach((mun) => {
          const munName = mun.name;
          filteredAgreements.forEach((agr) => {
            if (agr.municipality === munName) {
              totalCount++;
              count++;
            }
          });
        });
        return { region, students: count };
      });
      const data = [
        ...filteredData.sort((a, b) => b.students - a.students),
        {
          region: 'Estudiantes sin región asignada',
          students: filteredAgreements.length - totalCount,
        },
      ];
      setTotalRows(data.length);
      setData(data);
      setLoading(false);
    }
  }, [agreements, municipalities]);

  useEffect(() => {
    if (status !== 'fulfilled') {
      dispatch(doList());
    }
  }, [dispatch, agreements, status]);

  useEffect(() => {
    if (agreementsByMunStatus !== 'fulfilled') {
      dispatch(doListAgreementsByMunicipality());
    }
  }, [dispatch, agreementsByMun, agreementsByMunStatus]);

  useEffect(() => {
    if (municipalityStatus !== 'fulfilled') {
      dispatch(doGroupByRegion());
    }
  }, [dispatch, municipalities, municipalityStatus]);

  const bgColors = [
    'linear-gradient(90deg, rgba(0,151,255,1) 0%, rgba(116,198,255,1) 40%, rgba(255,255,255,1) 100%)',
    'linear-gradient(90deg, rgba(104,204,181,1) 0%, rgba(175,228,216,1) 40%, rgba(255,255,255,1) 100%)',
    'linear-gradient(90deg, rgba(131,131,131,1) 0%, rgba(201,201,201,1) 40%, rgba(255,255,255,1) 100%)',
  ];

  return (
    <Card
      style={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        maxHeight: '45vh',
      }}
      title='Estudiantes por región'
      bordered={false}
      size='small'
      loading={loading}
    >
      {data?.map((item, index) => {
        const bgColorsLength = bgColors.length;
        const colorIndex =
          Math.ceil((bgColorsLength * (index + 1)) / totalRows) - 1;
        const colspan = totalRows - index > 0 ? totalRows - index : 0;
        return (
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col span={colspan}></Col>
            <Col
              flex={1}
              style={{ borderBottom: '1px solid #CAC2C1', padding: '10px' }}
            >
              <Row
                style={{
                  background: bgColors[colorIndex],
                  padding: '5px 10px',
                }}
              >
                <Col>
                  <h5>Región</h5>
                  <h3>{item.region}</h3>
                </Col>
              </Row>
              <Row style={{ padding: '0px 10px' }}>
                <h3>Estudiantes: {item.students}</h3>
              </Row>
            </Col>
          </Row>
        );
      })}
    </Card>
  );
};
