import { httpApi } from "api/http.api";
import { Cycle, EditCycle } from "types/cycle";

const controller = `cycle`;

export const createCycle = (values:Cycle): Promise<JSON> =>
  httpApi
    .post<JSON>(`${controller}/`, { ...values })
    .then(({ data }) => data);

export const updateCycle = (values:EditCycle): Promise<JSON> =>
httpApi
  .put<JSON>(`${controller}/${values.cycleId}`, {...values})
  .then(({ data }) => data);

export const listCycle = (subsystemId:string): Promise<Cycle[]> =>
  httpApi.get<Cycle[]>(`${controller}/${subsystemId}`).then(({ data }) => data);

export const deleteCycle = (cycleId: string): Promise<JSON> =>
  httpApi
    .delete(`${controller}/${cycleId}`)
    .then(({ data }) => data);

