
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";

type InfoCirlceProps = {
  info: string;
};
export const InfoCircle = ({ info }: InfoCirlceProps) => {
  return (
    <Tooltip title={info}>
      <FontAwesomeIcon
        icon={faInfoCircle}
        style={{
          color: "rgba(0,0,0,.45)",
          marginRight: 5,
          marginLeft: 10,
        }}
      />
    </Tooltip>
  );
};
