import { Row, Col, Button, FormInstance } from "antd";
import { ColumnsType } from "antd/es/table";
import { deleteCycle } from "api/cycle.api";
import { notificationController } from "controllers/notificationController";
import dayjs from "dayjs";
import statusMessages from "statusMessages";
import { ApiError } from "types";
import { Cycle } from "types/cycle";

interface CycleColumnsProps {
    setCurrentCycle: (value:string) => void;
    setEditOpen: (value:boolean) => void;
    onGetCycles: () => void;
    form: FormInstance;
}
export const cycleColumns = ({setCurrentCycle, setEditOpen, onGetCycles, form}: CycleColumnsProps) => {
    const handleDeleteCycle = async (cycleId: string) => {
        await deleteCycle(cycleId).then(async () => {
            await onGetCycles();
            notificationController.success({
                message: 'El ciclo se elimino con éxito!'
            });
        }).catch(error => {
            const currentStatus = (error as ApiError).statusCode;
            notificationController.error({
                message: statusMessages.cycle[currentStatus || 500] as string
            });
        });
    }
    
    const CycleHeaders: ColumnsType<Cycle> = [
        {
            title: 'Clave',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Fecha de inicio',
            dataIndex: 'initialDate',
            key: 'initialDate',
            render: (i: string) => dayjs(i).format('DD/MM/YYYY'),
        },
        {
            title: 'Fecha final',
            dataIndex: 'finalDate',
            key: 'finalDate',
            render: (i: string) => dayjs(i).format('DD/MM/YYYY'),
        },
        {
            title: 'Opciones',
            dataIndex: 'options',
            key: 'options',
            render: (_, values) => (
                <Row gutter={[24, 12]}>
                    <Col xs={12}>
                        <Button
                            danger
                            type="primary"
                            onClick={() => handleDeleteCycle(values._id || '')}
                        >
                            Eliminar
                        </Button>
                    </Col>
                    <Col xs={12}>
                        <Button
                            onClick={() => {
                                const newFinalDate = dayjs(values.finalDate);
                                const newInitialDate = dayjs(values.initialDate);
                                setCurrentCycle(values._id || '')
                                setEditOpen(true)
                                form.setFieldsValue({
                                    code:values.code,
                                    dates: [newInitialDate, newFinalDate],
                                })
                            }}
                        >
                            Editar
                        </Button>
                    </Col>
                </Row>
            )
        }
    ];
    return CycleHeaders;
}