import { Row, Col, Button, FormInstance } from "antd";
import { ColumnsType } from "antd/es/table";
import { deleteCourseRules } from "api/course-rules.api";
import { notificationController } from "controllers/notificationController";
import statusMessages from "statusMessages";
import { ApiError } from "types";
import { CourseRules } from "types/course-rules";

interface CourseRulesColumnsProps {
  setCurrentCourseRules: (value: string) => void;
  setEditOpen: (value: boolean) => void;
  onGetCoursesRules: () => void;
  form: FormInstance;
}
export const CourseRulesColumns = ({
  setCurrentCourseRules,
  setEditOpen,
  onGetCoursesRules,
  form,
}: CourseRulesColumnsProps) => {
  const handleDeleteCourseRules = async (id: string) => {
    await deleteCourseRules(id)
      .then(async () => {
        await onGetCoursesRules();
        notificationController.success({
          message: "Las reglas del curso se eliminaron con éxito",
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController.error({
          message: statusMessages.courseRules[currentStatus || 500] as string,
        });
      });
  };

  const CourseRulesHeaders: ColumnsType<CourseRules> = [
    {
      title: "Grado",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "ID del curso semilla",
      dataIndex: "seedCourseId",
      key: "seedCourseId",
    },
    {
      title: "Nombre del curso semilla",
      dataIndex: "seedCourseName",
      key: "seedCourseName",
    },
    {
      title: "Opciones",
      dataIndex: "options",
      key: "options",
      render: (_, values) => (
        <Row gutter={[24, 12]}>
          <Col xs={12}>
            <Button
              danger
              type="primary"
              onClick={() => handleDeleteCourseRules(values._id || "")}
            >
              Eliminar
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              onClick={() => {
                setCurrentCourseRules(values._id || "");
                setEditOpen(true);
                form.setFieldsValue(values);
              }}
            >
              Editar
            </Button>
          </Col>
        </Row>
      ),
    },
  ];
  return CourseRulesHeaders;
};
