import React from "react";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { StudentList } from "components/students/StudentList/StudentList";

type StudentsPageProps = {
  filter?: string
}

const StudentsPage: React.FC<StudentsPageProps> = ({filter}:StudentsPageProps) => {
  return (
    <>
      <PageTitle>{`Lista de Alumnos`}</PageTitle>
      <StudentList filter={filter ?? ""}/>
    </>
  );
};

export default StudentsPage;
