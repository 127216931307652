export default {
  404: "No se pudo encontrar ningún expediente.",
  422: "Los datos del expediente no tienen el formato correcto.",
  427: "No pudo crearse el expediente porque ya existe otro expediente activo para este alumno.",
  433: "Rol de aprobación inválido.",
  437: "Se ha actualizado el expediente y se ha creado el usuario de Canvas, pero no se pudo registrar al usuario en los cursos. No es necesario que vuelvas a intentarlo, revisaremos el problema.",
  439: "El id del expediente recibido no es válido.",
  446: "Error al obtener las horas completadas.",
  447: "Se ha actualizado el expediente pero no se pudo registrar al usuario en Canvas. No es necesario que vuelvas a intentarlo, revisaremos el problema.",
};
