/*eslint @typescript-eslint/no-explicit-any: "off"*/

import Search from "antd/es/input/Search";
import { ColumnProps } from "antd/es/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";


export const filterProps: ColumnProps<any> = {
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => {
    return (
      <>
        <Search
          prefix={
            <FontAwesomeIcon
              style={{ marginRight: 15 }}
              icon={faXmark}
              onClick={() => {
                clearFilters!();
                close();
              }}
            />
          }
          autoFocus
          placeholder='Busqueda'
          value={selectedKeys[0]}
          enterButton
          onSearch={() => {
            confirm();
          }}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
          onPressEnter={() => {
            confirm();
          }}
          onBlur={() => {
            confirm();
          }}
        />
      </>
    );
  },
};
