import { NumericLiteral } from 'typescript';
import { ReactNode } from 'react';
import { CertificateFiles } from 'components/students/types';

export type WithChildrenProps<T = undefined> = T extends undefined
  ? {
      children?: ReactNode;
    }
  : T & {
      children?: ReactNode;
    };

export type Dimension = number | string;

export type ChartData = number[];

export type xData = number[] | string[];

export type LanguageType = 'de' | 'en';

export type ThemeType = 'light' | 'dark';

export interface ChartSeries {
  seriesName: string;
  value: number;
  data: {
    day: number;
    value: NumericLiteral;
  };
  name: string;
}

export type ChartSeriesData = ChartSeries[];

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type { default as User } from './User';

export type FileChangesType = {
  certificateFiles?: CertificateFiles[],
  incFileId?: string,
  fiscalFileId?: string,
}

export class ApiError extends Error {
  constructor(
    public statusCode: number,
    public message: string
  ) {
    super();
  }
}

export type Json = {
  [key: string | number]: number | string | boolean | null;
};

export type SearchProps = { searchParam: string, searchValue: string }