import { AgreementNewForm } from "components/Agreements/AgreementNewForm";
import { PageTitle } from "components/common/PageTitle/PageTitle";
import { useParams } from "react-router-dom";
import React from "react";

const AgreementNewPage: React.FC = () => {
  const params = useParams();
  return (
    <>
      <PageTitle>{`Nuevo Expediente`}</PageTitle>
      <AgreementNewForm studentId={JSON.parse(JSON.stringify(params))} />
    </>
  );
};

export default AgreementNewPage;
