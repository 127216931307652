import { ValuesForm } from "components/Form/ModalForm";
import { Dayjs } from "dayjs";
import { missingFieldMsg } from "constants/global";

export interface CycleValues {
  code: string;
  dates: Dayjs[];
}

export const cycleForm: ValuesForm[] = [
  {
    editable: true,
    label: "Clave",
    name: "code",
    type: "input",
    rules: missingFieldMsg,
  },
  {
    editable: true,
    label: "Fecha inicial y final",
    name: "dates",
    type: "rangePicker",
    rules: missingFieldMsg,
  },
];
