import { Table, Collapse } from "antd";
import { listCourses } from "api/course.api";
import { notificationController } from "controllers/notificationController";
import { useEffect, useState, useCallback } from "react";
import statusMessages from "statusMessages";
import { ApiError } from "types";
import { Course } from "types/course";
import * as S from "../cycles/cycles.styles";
import { CourseColumns } from "./CourseColumns";

interface ListCoursesProps {
  subsystemId: string;
}

const ListCourses = ({ subsystemId }: ListCoursesProps) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [status, setStatus] = useState<"empty" | "error" | "fulfilled">(
    "empty"
  );

  const onGetCourses = useCallback(async () => {
    await listCourses(subsystemId)
      .then((response) => {
        setCourses(response);
        setStatus("fulfilled");
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        setStatus("error");
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.course[currentStatus || 500] as string,
        });
      });
  }, [subsystemId, setCourses, setStatus, notificationController]);

  useEffect(() => {
    if (courses.length === 0 && status !== "error") {
      onGetCourses();
    }
  }, [courses, status, onGetCourses]);

  const coursesBySchool = courses.reduce((acc, course) => {
    const { schoolName } = course;
    if (!acc[schoolName]) {
      acc[schoolName] = [];
    }
    acc[schoolName].push(course);
    return acc;
  }, {} as Record<string, Course[]>);

  return (
    <>
      <S.TableCard id="course-list" title={`Cursos Activos`} padding=".5rem">
        <Collapse accordion>
          {Object.entries(coursesBySchool).map(
            ([schoolName, schoolCourses]) => (
              <Collapse.Panel header={schoolName} key={schoolName}>
                <Table
                  columns={CourseColumns()}
                  dataSource={schoolCourses}
                  size="small"
                  scroll={{ x: "max-content" }}
                  pagination={false}
                  bordered
                  rowKey={(record) => record.canvasCourseId}
                />
              </Collapse.Panel>
            )
          )}
        </Collapse>
      </S.TableCard>
    </>
  );
};

export default ListCourses;
