import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  FormItemProps,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";

export interface ValuesForm extends FormItemProps {
  editable: boolean;
  name: string;
  type: string;
  options?:{
    value:string | number;
    label:string;
  }[];
}

interface ModalFormProps<T> {
  values: ValuesForm[];
  open: boolean;
  setOpen: (value: boolean) => void;
  modalTitle: string;
  onFinish: (values: T) => void;
  form: FormInstance;
  loading: boolean;
}

const ModalForm = <T,>({
  values,
  open,
  setOpen,
  modalTitle,
  onFinish,
  form,
  loading,
}: ModalFormProps<T>) => {
  return (
    <Modal
      title={modalTitle}
      open={open}
      onClose={() => setOpen(false)}
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      footer={false}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        {values.map((value) => (
          <Form.Item
            label={`${value.label}:`}
            name={value.name}
            rules={value.rules}
          >
            {value.type === "rangePicker" && <DatePicker.RangePicker />}

            {value.type === "input" && <Input />}

            {value.type === "inputIntNumber" && (
              <InputNumber min={1} step={1} precision={0} />
            )}

            {value.type === 'select' && value.options && (
              <Select>
                {value.options.map(option => (
                  <Select.Option value={option.value}>{option.label.toUpperCase().replace('(SEMILLA)', '')}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        ))}
        <Form.Item>
          <Button htmlType="submit" type="primary" loading={loading}>
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalForm;
