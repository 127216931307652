import { Table, Drawer, Spin, Button, Row, Col, Tooltip } from 'antd';
import type { TourProps } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import * as S from '../Student.styles';
import { doList, doRemove } from 'store/slices/studentSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  initialValues,
  Student,
  StudentCsv,
  StudentWithAgreement
} from '../types';
import { WorkCenter } from '../../workcenters/types';
import { ColumnsType } from 'antd/es/table';
import { filterProps } from 'components/common/FilterProps';
import { OptionsDrawerBar } from '../StudentDetail/OptionsDrawerBar';
import { StudentDetail } from '../StudentDetail/StudentDetail';
import { InfoTour } from 'components/common/InfoTour/InfoTour';
import { InfoButton } from 'components/common/buttons/Button/InfoButton';
import { CheckAccess } from 'checkAccess/CheckAccess';
import { PermissionsToAccess } from 'checkAccess/ConstPermissions';
import { useTourCheck } from 'hooks/useTourCheck';
import { STUDENTS_PATH } from 'components/router/AppRouter';
import { useNavigate, Link } from 'react-router-dom';
import { StudentChangeWorkCenterModal } from './StudentChangeWorkCenterModal';
import { DownloadTableButton } from 'components/common/buttons/Button/DownloadTableButton';
import { notificationController } from "controllers/notificationController";
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { colorStatus } from 'components/Agreements/Agreements.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StudentStatuses } from 'constants/enums/modulesStatuses';
import SkeletonButton from 'antd/es/skeleton/Button';
import { ExportAgreements } from 'components/Agreements/list/ExportAgreements';
import { AGREEMENTS_PATH } from "components/router/AppRouter";
import statusMessages from "statusMessages";
import { ApiError } from "types";

const { writeStudents, exportAgreements } = PermissionsToAccess;

type StudentListProps = {
  filter?: string;
};

export const StudentList: React.FC<StudentListProps> = ({
  filter,
}: StudentListProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { students, status: studentStateStatus } = useAppSelector(
    (state) => state.student
  );
  const { status: agreementStateStatus } = useAppSelector((state) => state.agreement);

  const [studentData, setStudentData] = useState<Student[]>();
  const [studentDataCsv, setStudentDataCsv] = useState<StudentCsv[]>();
  const [selectedRowData, setSelectedRowData] = useState<Student>();
  const [spin, setSpin] = useState<boolean>(true);
  const [updatingTable, setUpdatingTable] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [label, setLabel] = useState("");
  const [isExportBtnLoading, setIsExportBtnLoading] = useState(false);
  const HandleOpenModal = (open: boolean) => setOpenModal(open);

  useEffect(() => {
    if (
      (agreementStateStatus === 'fulfilled')
    ) {
      setIsExportBtnLoading(false);
    } else {
      setIsExportBtnLoading(true);
    }
  }, [agreementStateStatus]);

  useEffect(() => {
    if (studentStateStatus === 'fulfilled') {
      setUpdatingTable(true);
      dispatch(doList()).then(() => {
        setUpdatingTable(false);
      }).catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.student[currentStatus || 500] as string,
        });
      });
    }
  }, [dispatch]);

  useEffect(() => {
    let filteredStudents: Student[] = [];

    switch (filter) {
      case "declinedStudents":
        filteredStudents = students.filter(
          (s) => s.academicStatus === StudentStatuses.DeclinedStatus
        );
        setLabel("en Baja de Dual")
        break;
      case "waitingStudents":
        filteredStudents = students.filter(
          (s) => s.academicStatus === StudentStatuses.WaitingStatus
        );
        setLabel("en Espera")
        break;
      case "graduatedStudents":
        filteredStudents = students.filter(
          (s) => s.academicStatus === StudentStatuses.GraduatedStatus
        );
        setLabel("Graduados")
        break;
      case "activeStudents":
        filteredStudents = students.filter(
          (s) => s.academicStatus === StudentStatuses.ActiveStatus
        );
        setLabel("Activos")
        break;
      default:
        filteredStudents = students;
        break;
    }
    setStudentData(filteredStudents);
  }, [students, filter])

  const handleDeleteStudent = (id: string) => {
    dispatch(doRemove(id))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: 'Alumno eliminado con éxito',
        });
        setSelectedRowData(initialValues);
        onCloseDrawer();
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.student[currentStatus || 500] as string,
        });
      });
  }

  // tour
  const { setOpenTour, openTour, isFirstTime } = useTourCheck('student');
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const steps: TourProps['steps'] = [
    {
      title: 'Sección de alumnos',
      description:
        'En esta sección podrá consultar la información de las alumnas y alumnos que participan en Educación Dual.',
      target: () => ref1.current,
    },
    {
      title: 'Nuevo alumno',
      description:
        'Para agregar un nuevo estudiante deberá presionar el botón “Nuevo alumno“ y agregar los datos correspondientes.',
      placement: 'left',
      target: () => ref2.current,
    },
    {
      title: 'Tabla, filtros y búsqueda',
      description:
        'Para realizar la búsqueda de estudiantes ya registrados podrá hacer uso de los filtros que aparecen en el encabezado de la tabla',
      placement: 'bottom',
    },
  ];

  // Carga la lista de estudiantes
  useEffect(() => {
    if (studentStateStatus !== 'fulfilled') {
      setSpin(true);
      dispatch(doList());
    } else {
      setSpin(false);
    }
  }, [studentStateStatus, students]);

  useEffect(() => {
    if (studentData && studentData.length > 0) {
      const csvData: StudentCsv[] = studentData.map((student) => {
        const formattedHours =
          student.completedHours
            ?.map((hoursObj) => {
              return `${hoursObj.educativeLevel}: ${hoursObj.completedHours}`;
            })
            .join(', ') ?? '';
        return {
          name: student.name ?? '',
          paternalLastname: student.paternalLastname ?? '',
          maternalLastname: student.maternalLastname ?? '',
          curp: student.curp ?? '',
          subsystem: student.workcenter?.subsystemShort ?? 'Sin subsistema',
          workcenter: student.workcenter?.nameShort ?? 'Sin plantel',
          academicStatus: student.academicStatus,
          completedHours: formattedHours,
          grade: student.grade,
        };
      });
      if (csvData) {
        setStudentDataCsv(csvData);
      }
    }
  }, [studentData]);

  // Drawer
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onCloseDrawer = () => {
    setSelectedRowData(initialValues);
    setOpen(false);
  };

  const columns: ColumnsType<StudentWithAgreement> = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => `${record.name} ${record.paternalLastname ?? ''} ${record.maternalLastname ?? ''}`,
      onFilter: (value, record) => {
        const fullName = `${record.name} 
          ${record.paternalLastname ?? ''} 
          ${record.maternalLastname ?? ''}`;
        return fullName.toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a: StudentWithAgreement, b: StudentWithAgreement) => {
        const fullNameA = `${a.name} ${a.paternalLastname ?? ''} ${a.maternalLastname ?? ''}`;
        const fullNameB = `${b.name} ${b.paternalLastname ?? ''} ${b.maternalLastname ?? ''}`;
        return fullNameA.localeCompare(fullNameB);
      },
      ...filterProps,
    },
    {
      title: 'CURP',
      dataIndex: 'curp',
      key: 'curp',
      ...filterProps,
      onFilter: (value, record) => {
        return record.curp
          .toLowerCase()
          .includes(value.toString().toLowerCase())
      },
      sorter: (a: Student, b: Student) =>
        a.curp && b.curp
          ? a.curp.localeCompare(b.curp)
          : a.curp
          ? a.curp.localeCompare('')
          : b.curp
          ? b.curp.localeCompare('')
          : ''.localeCompare(''),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100,
      render: (_: string, record: StudentWithAgreement) => (
        <span onMouseEnter={(e) => e.preventDefault()}>
          <Tooltip
            title={
              record.approvalStep
                ? `${record.agreementStatus} - ${record.approvalStep.name}`
                : `${
                    record.agreementStatus
                    ?? "Sin expediente"
                  }`
            }
          >
            <FontAwesomeIcon icon={faCircle} style={colorStatus(record.agreementStatus ?? "")} />
          </Tooltip>
        </span>
      ),
      ...filterProps,
      onFilter: (value, record) => {
        const status = record.agreementStatus ?? ''; 
        return status
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: StudentWithAgreement, b: StudentWithAgreement) => {
        const statusA = a.agreementStatus || "Sin expediente";
        const statusB = b.agreementStatus || "Sin expediente";
        return statusA.localeCompare(statusB);
      }
    },
    {
      title: 'Plantel',
      dataIndex: 'workcenter',
      key: 'workcenter',
      render: (data: WorkCenter) => {
        return data ? data.nameShort : '';
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.workcenter?.nameShort
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: StudentWithAgreement, b: StudentWithAgreement) =>
        a.workcenter?.nameShort.localeCompare(b.workcenter?.nameShort),
    },
    {
      title: 'Subsistema / IES',
      dataIndex: 'workcenter',
      key: 'subsystem',
      render: (data: WorkCenter) => {
        return data ? data.subsystemShort : '';
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.workcenter?.subsystemShort
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: StudentWithAgreement, b: StudentWithAgreement) =>
        a.workcenter?.subsystemShort.localeCompare(
          b.workcenter?.subsystemShort
        ),
    },
    {
      title: 'Empresa',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (companyName) => {
        return companyName ?? 'Sin empresa';
      },
      ...filterProps,
      onFilter: (value, record) => {
        return (record.companyName ?? "Sin empresa")
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: StudentWithAgreement, b: StudentWithAgreement) =>
        (a.companyName ?? "Sin empresa").localeCompare(b.companyName ?? "Sin empresa"),
    },
    {
      title: 'Folio',
      dataIndex: 'sequence',
      key: 'sequence',
      align: 'center',
      render: (_value: string, record: StudentWithAgreement) => {
        return record.agreementSequence ? (
          record.agreementSequence
        ) : (
          <Link to={`${AGREEMENTS_PATH}/nuevo/${record._id}`}>
            <Button type="primary">Nuevo Expediente</Button>
          </Link>
        );
      },
      defaultSortOrder: 'ascend',
      sorter: (a: StudentWithAgreement, b: StudentWithAgreement) => 
      {
        const seqA = Number(a.agreementSequence) || 0;
        const seqB = Number(b.agreementSequence) || 0;
        return seqA - seqB;
      }
    },
    {
      title: 'Consentimiento',
      dataIndex: 'consentLetterFileId',
      key: 'consentLetterFileId',
      align: 'center',
      width: 130,
      render: (_value: string, record: StudentWithAgreement) => {
        return record.consentLetterFileId ? (
          "SÍ"
        ) : (
          "NO"
        );
      },
      defaultSortOrder: 'ascend',
      sorter: (a: StudentWithAgreement, b: StudentWithAgreement) =>
        (a.consentLetterFileId ? "SÍ" : "NO").localeCompare(b.consentLetterFileId ? "SÍ" : "NO"),
    },
  ];
 
  return (
    <>
      <S.Wrapper>
        <S.Card
          id='students-list'
          title={
            <>
              <span ref={ref1}>
                Base de Datos de Alumnos {label}
                <InfoButton onClick={() => setOpenTour(true)} />
              </span>
            </>
          }
          extra={
            CheckAccess(writeStudents) && (
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                }}
              >
                <Col>
                  {updatingTable ? (
                    <div>
                      <Spin />
                      <strong> Actualizando tabla</strong>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col>
                  <Button type='primary' onClick={() => HandleOpenModal(true)}>
                    Cambiar alumno de plantel
                  </Button>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    ref={ref2}
                    onClick={() => {
                      navigate(`${STUDENTS_PATH}/nuevo`);
                    }}
                  >
                    Nuevo alumno
                  </Button>
                </Col>
                <Col>
                  <DownloadTableButton
                    columns={columns}
                    data={studentDataCsv}
                    size='middle'
                    type='primary'
                    label='Descargar alumnos'
                  />
                </Col>
                {CheckAccess(exportAgreements) && (
                  !isExportBtnLoading ? (
                    <Col>
                      <ExportAgreements />
                    </Col>
                  ) : (
                    <Col>
                      <SkeletonButton block={true} />
                    </Col>
              )
            )}
              </Row>
            )
          }
          padding='1.25rem'
        >
          <Drawer
            title='Detalle del Alumno Dual'
            placement='right'
            width='50%'
            onClose={onCloseDrawer}
            destroyOnClose
            open={open}
            extra={
              CheckAccess(writeStudents) && (
                <OptionsDrawerBar
                  rowSelected={selectedRowData ?? initialValues}
                  handleDeleteStudent={handleDeleteStudent}
                />
              )
            }
          >
            <StudentDetail selectedRow={selectedRowData ?? initialValues} />
          </Drawer>
          <StudentChangeWorkCenterModal
            openModal={openModal}
            OpenModalHanlder={HandleOpenModal}
          />
          <Spin spinning={spin} delay={400} tip='Cargando...' size='large'>
            <Table
              dataSource={studentData}
              columns={
                CheckAccess(writeStudents)
                  ? columns
                  : columns.filter((col) => col.title !== 'Acción')
              }
              onRow={(record, ) => {
                return {
                  onClick: () => {
                    setSelectedRowData(record);
                    showDrawer();
                  },
                };
              }}
              tableLayout='fixed'
              size='middle'
              rowKey='_id'
            />
          </Spin>
        </S.Card>
        <InfoTour
          onClose={() => setOpenTour(false)}
          open={openTour}
          steps={steps}
          block='nearest'
          module='student'
          isFirstTime={isFirstTime}
        />
      </S.Wrapper>
    </>
  );
};
