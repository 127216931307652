import { httpApi } from "api/http.api";
import { Subsystem } from "components/subsystems/types";

const controller = `subsystem`;

export const register = (SubsystemPayload: Subsystem): Promise<Subsystem> =>
  httpApi
    .post<Subsystem>(`${controller}`, { ...SubsystemPayload })
    .then(({ data }) => data);

export const list = (): Promise<Subsystem[]> =>
  httpApi.get<Subsystem[]>(`${controller}`).then(({ data }) => data);

export const remove = (SubsystemPayload: Subsystem): Promise<Subsystem> =>
  httpApi
    .delete(`${controller}`, { data: { id: SubsystemPayload._id } })
    .then(({ data }) => data);

  export const update = (SubsystemPayload: Subsystem): Promise<Subsystem> =>
  httpApi
    .put<Subsystem>(`${controller}/`, { ...SubsystemPayload })
    .then(({ data }) => data);