import { Button, Table } from "antd";
import { createCycle, listCycle, updateCycle } from "api/cycle.api";
import { notificationController } from "controllers/notificationController";
import { useEffect, useState, useCallback } from "react";
import statusMessages from "statusMessages";
import { ApiError } from "types";
import { Cycle } from "types/cycle";
import * as S from "./cycles.styles";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import ModalForm from "components/Form/ModalForm";
import { cycleForm, CycleValues } from "./cycleForm";
import { cycleColumns } from "./cycleColumns";
import { useForm } from "antd/es/form/Form";

interface ListCyclesProps {
  subsystemId: string;
}

const ListCycles = ({ subsystemId }: ListCyclesProps) => {
  const { writeSubsystems } = PermissionsToAccess;
  const [cycles, setCycles] = useState<Cycle[]>([]);
  const [status, setStatus] = useState<"empty" | "error" | "fulfilled">(
    "empty"
  );
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentCycle, setCurrentCycle] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const onGetCycles = useCallback(async () => {
    await listCycle(subsystemId)
      .then((response) => {
        setCycles(response);
        setStatus("fulfilled");
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        setStatus("error");
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.cycle[currentStatus || 500] as string,
        });
      });
  }, [subsystemId, setCycles, setStatus, notificationController]);

  useEffect(() => {
    if (cycles.length === 0 && status !== "error") {
      onGetCycles();
    }
  }, [cycles, status, onGetCycles]);

  const handleEditCycle = async (values: CycleValues) => {
    setLoading(true);
    if (!currentCycle) {
      notificationController.error({
        message: "No se encontró el id del ciclo",
      });
      return;
    }
    await updateCycle({
      code: values.code,
      finalDate: values.dates[1].toDate(),
      initialDate: values.dates[0].toDate(),
      cycleId: currentCycle || "",
    })
      .then(async () => {
        setEditOpen(false);
        await onGetCycles();
        notificationController.success({
          message: "El ciclo se edito con éxito!",
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController.error({
          message: statusMessages.cycle[currentStatus || 500] as string,
        });
      })
      .finally(() => setLoading(false));
  };

  const handleCreateCycle = async (values: CycleValues) => {
    setLoading(true);
    await createCycle({
      code: values.code,
      finalDate: values.dates[1].toDate(),
      initialDate: values.dates[0].toDate(),
      subsystem: subsystemId,
    })
      .then(async () => {
        await onGetCycles();
        setCreateOpen(false);
        notificationController.success({
          message: "El ciclo se creo con éxito!",
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController.error({
          message: statusMessages.cycle[currentStatus || 500] as string,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <S.TableCard
        id="cycle-list"
        title={`Ciclos`}
        padding=".5rem"
        extra={
          CheckAccess(writeSubsystems) && [
            <Button
              type="primary"
              onClick={() => {
                setCreateOpen(true);
                form.resetFields();
              }}
            >
              Nuevo Ciclo
            </Button>,
          ]
        }
      >
        <Table
          columns={cycleColumns({
            setCurrentCycle,
            setEditOpen,
            onGetCycles,
            form,
          })}
          dataSource={cycles}
          size="small"
          scroll={{ x: "max-content" }}
          pagination={false}
          bordered
        />
      </S.TableCard>
      <ModalForm<CycleValues>
        loading={loading}
        modalTitle="Nuevo Ciclo"
        open={createOpen}
        setOpen={setCreateOpen}
        values={cycleForm}
        onFinish={handleCreateCycle}
        form={form}
      />
      <ModalForm<CycleValues>
        loading={loading}
        modalTitle="Editar Ciclo"
        open={editOpen}
        setOpen={setEditOpen}
        values={cycleForm.filter((value) => value.editable)}
        onFinish={handleEditCycle}
        form={form}
      />
    </>
  );
};

export default ListCycles;