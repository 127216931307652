import { httpApi } from "api/http.api";
import { CanvasCourse, Course, SeedCourse } from "types/course";

const controller = "course";

export const listCourses = (subsystemId: string): Promise<Course[]> =>
  httpApi
    .get<Course[]>(`${controller}/${subsystemId}`)
    .then(({ data }) => data);

export const listSeedCourses = (): Promise<CanvasCourse[]> =>
  httpApi
    .get<CanvasCourse[]>(`${controller}/seedCourses`)
    .then(({ data }) => data);

export const createSeedCourse = (data:SeedCourse): Promise<JSON> =>
  httpApi
    .post<JSON>(`${controller}/seed`, {...data})
    .then(({ data }) => data);
