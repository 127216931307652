import { FormInstance, List } from 'antd';
import { ShowDocumentBtn } from 'components/common/Document/ShowDocumentBtn';
import { AWSFileUploader } from 'components/common/FileManager/AWSFileUploader';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import { Agreement } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WorkCenter } from 'components/workcenters/types';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

export type FileListItem = {
  id?: string;
  title: string;
  bucket?: string;
  field?: string;
  style?: string;
  inheritedFileId?: string;
};

export const fileList = (
  data: FileListItem[],
  onShowDocument: (id: string) => void,
  onUpload: (field: string, id: string | null) => void,
  form: FormInstance
) => {
  return (
    <List
      bordered
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 3,
        xxl: 3,
      }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item style={{ display: item.style ?? '' }}>
          {item.id !== undefined && item.id !== null ? (
            <ShowDocumentBtn
              label={item.title}
              onClick={() => {
                onShowDocument(item.id!);
              }}
            />
          ) : (
            `${item.title}`
          )}
          {item.field &&
            form.getFieldValue(item.field) &&
            form.getFieldValue(item.field) !== item.id && (
              <>
                {' '}
                <FontAwesomeIcon
                  icon={faCircleArrowRight}
                  style={{ color: '#ce0e2c', marginRight: '5px' }}
                />
                <ShowDocumentBtn
                  label="ver nuevo documento"
                  onClick={() => {
                    onShowDocument(form.getFieldValue(item.field!));
                  }}
                  dataCy={item.field}
                />
              </>
            )}
          {item.bucket && item.field && (
            <BaseForm.Item name={item.field}>
              <AWSFileUploader
                isReplacing={form.getFieldValue(item.field!)}
                title={item.title}
                bucket={item.bucket}
                onUploadCompleted={(id) => {
                  onUpload(item.field!, id);
                  item.id = id ? id : undefined;
                }}
                inheritedFileId={item.inheritedFileId}
                dataCy={`upload-${item.field}`}
              />
            </BaseForm.Item>
          )}
        </List.Item>
      )}
    />
  );
};

export const getColabFiles = (agreement: Agreement) => {
  const filesColabAgreement = [
    {
      title: 'Convenio',
      id: agreement?.colaborationAgreementFileId,
      bucket: 'colaboration-agreement',
      field: 'colaborationAgreementFileId',
    },
    {
      title: 'Acta constitutiva de la empresa',
      bucket: 'company-inc',
      id: agreement?.companyId?.incFileId,
    },
    {
      title: 'Poder del representante legal',
      bucket: 'representative-power',
      id: agreement?.legalRepresentativeId?.powerFileId,
    },
    {
      title: 'Idetificación del representante legal',
      bucket: 'representative-id',
      id: agreement?.legalRepresentativeId?.idFileId,
    },
    {
      title: 'Constancia de situación fiscal',
      bucket: 'company-fiscal',
      id: agreement?.companyId?.fiscalFileId,
    },
  ];
  return filesColabAgreement;
};

export const getFilesLearningAgreement = (
  agreement: Agreement,
  letterDisplay: string,
  INETutorEstudiante: string,
  workcenter: WorkCenter | undefined = undefined
) => {
  const filesLearningAgreement = [
    {
      title: 'Convenio',
      id: agreement?.learningAgreementFileId,
      bucket: 'learning-agreement',
      field: 'learningAgreementFileId',
    },
    {
      title: 'Carta de autorización del padre o tutor',
      id: agreement?.tutorAuthLetterFileId,
      bucket: 'auth-letter',
      field: 'tutorAuthLetterFileId',
      style: letterDisplay,
    },
    {
      title: INETutorEstudiante,
      id: agreement?.tutorIdFileId,
      bucket: 'tutor-id',
      field: 'tutorIdFileId',
    },
    {
      title: 'Seguro facultativo',
      id: agreement?.insuranceFileId,
      bucket: 'insurance',
      field: 'insuranceFileId',
    },
    {
      title: 'Plan de Rotación',
      id: agreement?.rotationPlanFileId,
      bucket: 'rotation-plan',
      field: 'rotationPlanFileId',
    },
    {
      title: 'Puestos de Aprendizaje',
      id: agreement?.learningPositionsFieldId,
      bucket: 'learning-positions',
      field: 'learningPositionsFieldId',
    },
    {
      title: 'Matriz de correspondencia',
      id: agreement?.matrixFieldId,
      bucket: 'matrix',
      field: 'matrixFieldId',
    },
    {
      title: 'Nombramiento del director',
      id: agreement?.principalFileId,
      bucket: 'principal',
      field: 'principalFileId',
      inheritedFileId: workcenter?.principalFileId,
    },
  ];

  return filesLearningAgreement;
};

export const getFilesNewLearningAgreement = (
  letterDisplay: string,
  INETutorEstudiante: string,
  workcenter: WorkCenter | undefined = undefined
) => {
  const filesLearningAgreement: FileListItem[] = [
    {
      title: 'Convenio',
      bucket: 'learning-agreement',
      field: 'learningAgreementFileId',
    },
    {
      title: 'Carta de autorización del padre o tutor',
      bucket: 'auth-letter',
      field: 'tutorAuthLetterFileId',
      style: letterDisplay,
    },
    {
      title: INETutorEstudiante,
      bucket: 'tutor-id',
      field: 'tutorIdFileId',
    },
    {
      title: 'Seguro facultativo',
      bucket: 'insurance',
      field: 'insuranceFileId',
    },
    {
      title: 'Plan de Rotación',
      bucket: 'rotation-plan',
      field: 'rotationPlanFileId',
    },
    {
      title: 'Puestos de Aprendizaje',
      bucket: 'learning-positions',
      field: 'learningPositionsFieldId',
    },
    {
      title: 'Matriz de correspondencia',
      bucket: 'matrix',
      field: 'matrixFieldId',
    },
    {
      title: 'Nombramiento del director',
      bucket: 'principal',
      field: 'principalFileId',
      inheritedFileId: workcenter?.principalFileId,
    },
  ];
  return filesLearningAgreement;
};

export const getFilesWorkCenter = (
  workcenter: WorkCenter | undefined = undefined
) => [
  {
    id: workcenter?.principalFileId,
    title: 'Nombramiento del director',
    bucket: 'principal',
    field: 'principalFileId',
  },
];
