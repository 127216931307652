import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { list, register, remove, listfilter, search, update } from "api/employee.api";
import { SearchParams } from "components/companies/types";
import { Employee } from "components/employees/types";

export interface EmployeeSlice {
  employees: Employee[];
  status: 'empty' | 'fulfilled' | 'error'
}

const initialState: EmployeeSlice = {
  employees: [],
  status: 'empty',
};

export const doRegister = createAsyncThunk(
  "employee",
  async (EmployeePayload: Employee) =>
    register(EmployeePayload).then((res) => res)
);

export const doList = createAsyncThunk("employees/list", async () =>
  list().then((res) => res)
);

export const doUpdate = createAsyncThunk(
  "employees/update",
  async (EmployeePayload: Employee) => update(EmployeePayload).then((res) => res)
);

export const doListFiltered = createAsyncThunk(
  "employees/listFilter",
  async (rows: number) => listfilter(rows).then((res) => res)
);

export const doRemove = async (EmployeePayload: Employee) =>
  remove(EmployeePayload).then((res) => res);

export const doSearch = createAsyncThunk(
  "employees/search",
  async (data: SearchParams) => search(data).then((res) => res)
);

export const doClear = createAction('employees/clear', () => {
  return {
    payload: "clear",
  };
});

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doRegister.fulfilled, (state, action) => {
      state.employees.push(action.payload.user);
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.employees = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(doListFiltered.fulfilled, (state, action) => {
      state.employees = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(doUpdate.fulfilled, (state, action) => {
      const index = state.employees.findIndex(r => r._id === action.payload._id);
      state.employees[index] = action.payload;
    });
    builder.addCase(doClear, (state) => {
      state.employees = [];
      state.status = "empty";
    });
    // builder.addCase(doSearch.fulfilled, (state, action) => {
    //   console.log(action)
    //   state.employees = action.payload;
    // });
  },
});

export default employeeSlice.reducer;
