import { Avatar, List } from "antd";

import { Company } from "../companies/types";

type Props = {
  companies: Company[];
}

export const CompaniesList = ({ companies }: Props) => {
  const getStatusLights = (status: string) => {
    return status === "Rechazada"
      ? "#cf1322"
      : status === "Sin Verificar"
      ? "#faad14"
      : "#3f8600";
  };
  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={companies}
        pagination={{
          pageSize: 3,
          total: companies.length,
          pageSizeOptions: ["1", "2", "3"],
        }}
        renderItem={(item) => (
          <List.Item key={item._id}>
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{
                    backgroundColor: getStatusLights(item.status),
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {item.name.charAt(0).toUpperCase() ?? ""}
                </Avatar>
              }
              title={`${item.name ?? "no encontrado"} - : ${
                item.industry ?? "sector productivo no encontrado"
              }`}
              description={
                <div>
                  <p>{`Nombre comercial: ${item.commercialName ?? "No encontrado"}`}</p>
                  <p>{`Estado: ${item.status}`}</p>
                </div>
              }
            />
          </List.Item>
        )}
      ></List>
    </>
  );
};
