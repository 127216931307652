import { StatusMessages } from "types/statusMessage";
import base from "./base";
import cycle from "./cycle";
import course from "./course";
import courseRules from "./course-rules";
import student from "./student";
import agreement from "./agreement";
import file from "./file";

const statusMessages: StatusMessages = {
  base,
  cycle: { ...base, ...cycle },
  courseRules: { ...base, ...courseRules },
  course: { ...base, ...course },
  student: { ...base, ...student },
  agreement: { ...base, ...agreement },
  file: { ...base, ...file },
};

export default statusMessages;
