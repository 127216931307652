import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { store } from 'store/store';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: `${import.meta.env.VITE_APP_SENTRY_DSN}`,
  integrations: [Sentry.reactRouterV6BrowserTracingIntegration({
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
  }),Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.25,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.NODE_ENV === 'production',
  release: `${import.meta.env.VITE_APP_REPOSITORY}@${import.meta.env.VITE_APP_IMAGE_TAG}`,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: 
//   reportWebVitals((metric) => {
//     console.log(metric.name); El nombre de la métrica
//     console.log(metric.value); El valor de la métrica
//     console.log(metric.id); ID único de la métrica
//   });
// )
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
