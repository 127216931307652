import { Card, Spin, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doListStudentsByEducativeLevel } from 'store/slices/statisticsSlice';
import { useEffect, useState } from 'react';
import { DownloadTableButton } from 'components/common/buttons/Button/DownloadTableButton';
import { ColumnsType } from 'antd/es/table';
import { StudentsByEducativeLevel } from 'api/statistic.api';

export const StudentsStatisticsTable = () => {
  const [spin, setSpin] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const {
    studentsByEducativeLevel,
    studentsByEducativeLevelStatus: studentStatus,
  } = useAppSelector((state) => state.statistics);

  useEffect(() => {
    if (studentStatus !== 'fulfilled') {
      dispatch(doListStudentsByEducativeLevel());
    } else {
      setSpin(false)
    }
  }, [dispatch, studentStatus]);

  const studentStatisticsColumns: ColumnsType<StudentsByEducativeLevel> = [
    {
      title: 'Nivel Educativo',
      dataIndex: 'educativeLevel',
      key: 'educativeLevel',
    },
    {
      title: 'Activos',
      dataIndex: 'activeCount',
      key: 'activeCount',
      align: 'right',
    },
    {
      title: 'En espera',
      dataIndex: 'waitingCount',
      key: 'waitingCount',
      align: 'right',
    },
    {
      title: 'Graduados',
      dataIndex: 'graduatedCount',
      key: 'graduatedCount',
      align: 'right',
    },
    {
      title: 'Baja',
      dataIndex: 'declinedCount',
      key: 'declinedCount',
      align: 'right',
    },
    {
      title: 'Total',
      dataIndex: 'totalCount',
      key: 'totalCount',
      align: 'right',
    },
  ];

  return (
    <Card
      style={{ width: '100%', height: '100%' }}
      title='Estudiantes de educación dual'
      bordered={false}
      size='small'
      extra={
        <DownloadTableButton
          columns={studentStatisticsColumns}
          data={studentsByEducativeLevel}
        />
      }
    >
      <Spin spinning={spin} delay={400} tip='Cargando...' size='large'>
        <Table
          style={{ width: '100%', height: '100%' }}
          scroll={{ x: true }}
          columns={studentStatisticsColumns}
          dataSource={studentsByEducativeLevel}
          pagination={false}
        />
      </Spin>
    </Card>
  );
};
