/*eslint valid-typeof: off*/
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import {
  Select,
  DatePicker,
  Row,
  Col,
  Button,
  Form,
  Input,
  Tooltip,
  Progress,
  Divider,
} from 'antd';
import { doRegister } from 'store/slices/agreementSlice';
import { doGetOne } from 'store/slices/studentSlice';
import { doList as doCompanyList } from 'store/slices/companySlice';
import { doList as doSubsystemList } from 'store/slices/subsystemSlice';
import { notificationController } from 'controllers/notificationController';
import * as S from './Agreements.styles';
import { STUDENTS_PATH } from 'components/router/AppRouter';
import { Company, Representative } from 'components/companies/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CompanyNew } from '../companies/CompanyNew';
import { OptionsSelect } from 'types/optionsSelect';
import { AWSFileUploader } from 'components/common/FileManager/AWSFileUploader';
import { doList as WorkcenterSub } from 'store/slices/workCenterSlice';
import { doList as repsDoList } from 'store/slices/representativeSlice';
import { CloseButton } from 'components/common/buttons/Button/CloseButton';
import {
  IsAdultStudent,
  handleTimeChange,
  validAgreementDates,
} from './utils/utils';
import { Document } from 'components/common/Document/Document';
import { ShowDocumentBtn } from 'components/common/Document/ShowDocumentBtn';
import { escapeRegExp } from 'lodash';
import { fileList, getFilesNewLearningAgreement } from './utils/fileList';
import { RotationFields } from './utils/RotationFields';
import { TimeFields } from './utils/timeFields';
import { Agreement } from './types';
import statusMessages from "statusMessages";
import { ApiError } from "types";
import { WorkCenter } from 'components/workcenters/types';
import { UserRoles } from 'constants/roles';
import { Student } from 'components/students/types';

type NewAgreementProps = { studentId: { id: string } };

export const AgreementNewForm = ({ studentId }: NewAgreementProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const laptopAssignmentHook = Form.useWatch('laptopAssignmentFileId', form);
  const [isLoading, setLoading] = useState(false);
  // Inital Data
  const { companies, status: companyStateStatus } = useAppSelector(
    (state) => state.company
  );
  const { representatives, status: repsStateStatus } = useAppSelector(
    (state) => state.representative
  );
  const { workcenters, status: workcenterStateStatus } = useAppSelector(
    (state) => state.workcenter
  );
  const { subsystems, status: subsystemStateStatus } = useAppSelector(
    (state) => state.susbsystem
  );
  const { user } = useAppSelector((state) => state.user);
  const { student } = useAppSelector((state) => state.student);
  // WorkCenter
  const [selectedWorkCenter, setSelectedWorkCenter] = useState<WorkCenter>();
  const [optionWorkCenter, setOptionWorkCenter] = useState<OptionsSelect[]>([]);
  const [valueSubSystem, setValueSubSystem] = useState('');
  const [optionsEmployee, setOptionsEmployee] = useState<OptionsSelect[]>([]);
  // WorkCenter
  const [optionStudents, setOptionStudents] = useState<OptionsSelect[]>([]);
  // Company
  // Select for Compnay
  const [companyContacts, setCompanyContacts] = useState<Representative[]>([]);
  const [companyValue, setCompanyValue] = useState<string>();
  const [optionsCompany, setOptionsCompany] = useState<OptionsSelect[]>([]);
  const [optionsCompanyContact, setOptionsrCompanyContact] = useState<
    OptionsSelect[]
  >([]);
  const [companyLegalReps, setCompanyLegalReps] = useState<Representative[]>(
    []
  );
  const [optionsCompanyLegalReps, setOptionsCompanyLegalReps] = useState<
    OptionsSelect[]
  >([]);
  // label and display if adult or not
  const [INETutorEstudiante, setINELabel] = useState('');
  const [letterDisplay, setLetterDisplay] = useState('');
  // Drawer Comapny
  const [openDrawer, setOpenDrawer] = useState(false);
  const [newCompany, setnewCompany] = useState<Company>();
  //Documents
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState('');
  // total hours
  const [totalHours, setTotalHours] = useState<string>('00:00');
  // show laptop fields
  const [visibleLaptopFields, setVisibleLaptopFields] = useState(false);
  //progress bar
  const [percent, setPercent] = useState<number>(0);
  const [isAdultStudent, setIsAdultStudent] = useState(false);
  const [fieldsForProgress, setFieldsForProgress] = useState({
    sequence: '',
    studentId: '',
    workCenterId: '',
    companyId: '',
    representativeId: '',
    legalRepresentativeId: '',
    startDate: '',
    endDate: '',
    learningAgreementFileId: '',
    colaborationAgreementFileId: '',
    tutorIdFileId: '',
    tutorAuthLetterFileId: '',
    insuranceFileId: '',
    rotationPlanFileId: '',
    learningPositionsFieldId: '',
    matrixFieldId: '',
    principalFileId: '',
    principal: '',
    principalPosition: '',
    rotationTimes: '',
    rotationPositions: '',
    hoursPerWeek: '',
  });

  useEffect(() => {
    dispatch(doGetOne({ searchParam: 'id', searchValue: studentId.id }))
    .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.student[currentStatus || 500] as string,
        });
    });
  }, [dispatch]);

  useEffect(() => {
    if (workcenterStateStatus !== 'fulfilled') {
      dispatch(WorkcenterSub());
    }
  }, [dispatch, workcenterStateStatus]);

  useEffect(() => {
    if (subsystemStateStatus !== 'fulfilled') {
      dispatch(doSubsystemList());
    }
  }, [dispatch, subsystemStateStatus]);

  useEffect(() => {
    setINELabel('INE del padre o tutor vigente');
    setLetterDisplay('');
  }, []);
  useEffect(() => {
    showLaptopFields();
  }, [user]);

  useEffect(() => {
    if (companyStateStatus !== 'fulfilled') {
      dispatch(doCompanyList());
    }
    if (repsStateStatus !== `fulfilled`) {
      dispatch(repsDoList());
    }
  }, [companyStateStatus, dispatch, repsStateStatus]);

  useEffect(() => {
    if (newCompany) {
      handleCompanyChange(newCompany._id!);
    }
  }, [newCompany]);

  useEffect(() => {
    CheckProgress();
  }, [fieldsForProgress, isAdultStudent]);

  const ifAdultChangeFiles = useCallback((selectedStudent: Student) => {
    let ineLabel = 'INE del padre o tutor vigente';
    let display = '';
    let isAdultStudent = false;
    const authLetterVal = form.getFieldValue('tutorAuthLetterFileId');

    if (IsAdultStudent(selectedStudent)) {
      ineLabel = 'INE del estudiante mayor de edad';
      isAdultStudent = true;
    } else if (selectedStudent.consentLetterFileId) {
      form.setFieldValue("tutorAuthLetterFileId", selectedStudent.consentLetterFileId);
    }
    if (isAdultStudent && !authLetterVal) {
      display = 'none';
    }
    setINELabel(ineLabel);
    setLetterDisplay(display);
    setIsAdultStudent(isAdultStudent);
  }, [form]);

  useEffect(() => {
    if (student?._id && workcenters) {
      ifAdultChangeFiles(student);
      const foundWorkCenter = workcenters.find((wc) => wc._id === student.workcenter._id);
      setOptionWorkCenter([{
        label: foundWorkCenter?.name ?? "",
        value: foundWorkCenter?._id as string,
        key: foundWorkCenter?._id,
      },]);
      setSelectedWorkCenter(foundWorkCenter);
      const foundSubsystem = subsystems.find(
        (subsystem) => subsystem._id === foundWorkCenter?.subsystemId
      );
      setValueSubSystem(foundSubsystem?.nameShort ?? "");
      setOptionsEmployee([{
        label: foundWorkCenter?.principal?.name ?? "",
        value: foundWorkCenter?.principal?._id as string,
        key: foundWorkCenter?.principal?._id,
      },]);
      setOptionStudents([{
        label: `${student.name}${student.paternalLastname ? " " + student.paternalLastname : ""}${
        student.maternalLastname ? " " + student.maternalLastname : ""}`,
        value: student._id as string,
        key: student._id,
      },]);
      form.setFieldValue("educativeLevel", foundSubsystem?.educativeLevel);
      form.setFieldValue("workCenterId", student.workcenter._id);
      form.setFieldValue("principal", foundWorkCenter?.principal?._id);
      form.setFieldValue("studentId", student._id);
      setFieldsForProgress((prevState) => ({
        ...prevState,
        workCenterId: student.workcenter._id ?? "",
        principal: foundWorkCenter?.principal?._id ?? "",
        studentId: student._id ?? "",
      }));
    }
  }, [student, workcenters]);

  //progress bar
  const CheckProgress = () => {
    const authLetterVal = form.getFieldValue('tutorAuthLetterFileId');
    let newProgress = percent;
    let numerator = Object.values(fieldsForProgress).filter(Boolean).length;
    if (isAdultStudent && !authLetterVal) {
      numerator++;
    }
    newProgress = Math.round(
      (numerator / Object.keys(fieldsForProgress).length) * 100
    );
    setPercent(newProgress);
  };

  const handleTimeFieldsChange = (
    field: string,
    value: string | Date | undefined
  ) => {
    handleTimeChange(form, setTotalHours);
    handleFormChange(field, value);
  };

  const showLaptopFields = () => {
    let show = false;
    if (!user) {
      setVisibleLaptopFields(show);
      return false;
    }
    if (user.role && user.role === UserRoles.AdminRole) {
      show = true;
    }
    if (user.approvementRole && user.approvementRole?.sequence > 1) {
      show = true;
    }
    setVisibleLaptopFields(show);
  };

  // documents
  const onShowDocument = (id: string) => {
    setPdfViewerId(id);
    setShowDocument(true);
  };

  const onUpload = (field: string, id: string | null) => {
    form.setFieldValue(`${field}`, id);
    handleFormChange(field ?? '', id);
  };

  const handleFormChange = (
    field: string,
    value: string | Date | undefined | null | number | Representative[]
  ) => {
    setFieldsForProgress({ ...fieldsForProgress, [field]: value });
  };
  // Select Functions
  const handleSubmit = (values: Agreement) => {
    const educativeLevel = form.getFieldValue('educativeLevel')
    if (!educativeLevel) {
      notificationController.error({
        message: 'El plantel no tiene nivel educativo asignado',
      });
      return;
    }
    if (
      !values.startDate ||
      !values.endDate ||
      !validAgreementDates(
        (values.startDate as Date).toISOString(),
        (values.endDate as Date).toISOString()
      )
    ) {
      setLoading(false);
      notificationController.error({
        message: 'Fechas no validas',
        description:
          'Asegúrate de que la fecha de finalización no sea anterior a la fecha de inicio',
      });
      return;
    }
    dispatch(doRegister(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: 'Expediente registrado correctamente',
        });
        setLoading(false);
        navigate(STUDENTS_PATH);
      })
      .catch((error) => {
        setLoading(false);
        const currentStatus = (error as ApiError).statusCode;
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.agreement[currentStatus || 500] as string,
        });
      });
  };

  // Company

  useEffect(() => {
    const optionsRaw: OptionsSelect[] = companies.map((company) => ({
      label: company.name!,
      value: company._id!,
      key: company._id!,
    }));
    setOptionsCompany(optionsRaw);
  }, [companies]);

  useEffect(() => {
    const optionsRaw: OptionsSelect[] = companyContacts.map((rep) => ({
      label: `${rep.name}`,
      value: rep._id!,
      key: rep._id!,
    }));
    const optionsRawReps: OptionsSelect[] = companyLegalReps.map((rep) => ({
      label: `${rep.name}`,
      value: rep._id!,
      key: rep._id!,
    }));
    setOptionsrCompanyContact(optionsRaw);
    setOptionsCompanyLegalReps(optionsRawReps);
    handleFormChange('representativeId', '');
    handleFormChange('legalRepresentativeId', '');
  }, [companyContacts, representatives, companyLegalReps]);

  useEffect(() => {
    if (optionsCompanyContact.length > 0) {
      handleFormChange('representativeId', optionsCompanyContact[0].value);
      form.setFieldValue('representativeId', optionsCompanyContact[0].value);
    }
    if (optionsCompanyLegalReps.length > 0) {
      handleFormChange(
        'legalRepresentativeId',
        optionsCompanyLegalReps[0].value
      );
      form.setFieldValue(
        'legalRepresentativeId',
        optionsCompanyLegalReps[0].value
      );
    }
  }, [companyValue, optionsCompanyContact, optionsCompanyLegalReps]);

  const handleCompanyChange = (value: string) => {
    handleFormChange('companyId', value);
    if (newCompany) {
      form.setFieldValue('companyId', value);
    }
    form.setFieldValue('representativeId', null);
    form.setFieldValue('legalRepresentativeId', null);
    handleFormChange('legalRepresentativeId', '');
    setCompanyValue(value);
    setCompanyContacts(
      representatives.filter(
        (r) => r.companyId === value && r.type === 'Contact'
      )
    );
    setCompanyLegalReps(
      representatives.filter(
        (r) => r.companyId === value && r.type === 'Representative'
      )
    );
  };

  const filterCompany = (companyName: string) => {
    const escapedCompanyName = escapeRegExp(companyName);
    const inputString = new RegExp(`${escapedCompanyName}`, 'gi');
    const newOptions = companies
      .filter((value) => inputString.test(value.name))
      .map((company) => ({
        label: company.name!,
        value: company._id!,
        key: company._id!,
      }));
    setOptionsCompany(newOptions);
  };

  const onClose = async () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <S.Wrapper>
        <S.SmallCard
          id="agreement-new"
          title={
            <>
              <CloseButton
                onClick={() => {
                  navigate(STUDENTS_PATH);
                }}
              ></CloseButton>
              <span>&nbsp; {`Nuevo Expediente`}</span>
            </>
          }
          padding="3rem"
        >
          <Tooltip
            title={percent === 100 ? 'Campos completos' : 'Campos incompletos'}
          >
            <Progress percent={percent} />
          </Tooltip>
          <BaseForm
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            requiredMark="optional"
          >
            {/* Plantel */}
            <Row></Row>
            <Row>
              <Col style={{ marginRight: 25 }}>
                <BaseForm.Item label={`Subsistema / IES`}>
                  <Input
                    style={{ width: 200 }}
                    value={valueSubSystem}
                    placeholder="Sub-Sistema / IES"
                    disabled
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="workCenterId"
                  label={`Plantel`}
                  rules={[{ required: true, message: 'Selecciona un plantel' }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    options={optionWorkCenter}
                    disabled
                  />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="educativeLevel"
                  required
                  label={`Nivel educativo`}
                  rules={[
                    {
                      required: true,
                      message: 'El plantel no tiene un nivel educativo asignado',
                    },
                  ]}
                >
                  <Input
                    style={{ width: '100%' }}
                    disabled
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1}>
                <BaseForm.Item
                  name="principal"
                  label="Director(a) del Plantel Firmante"
                  rules={[
                    {
                      required: true,
                      message: 'El plantel no tiene un director asignado',
                    },
                  ]}
                >
                  <Select
                    placeholder="Director(a) del plantel"
                    options={optionsEmployee}
                    disabled
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={1} style={{ marginLeft: 20 }}>
                <BaseForm.Item
                  name="principalPosition"
                  label="Puesto del director(a) firmante"
                >
                  <Input
                    placeholder="Encargado(a) de la Dirección del Plantel"
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            {/* Student Data  */}
            <BaseForm.Item
              name="studentId"
              label={`Alumno`}
              rules={[{ required: true, message: 'Selecciona un alumno' }]}
            >
              <Select
                options={optionStudents}
                disabled
              />
            </BaseForm.Item>
            {/* Company Data  */}
            <Row>
              <Col flex={3} style={{ marginRight: 25 }}>
                <BaseForm.Item label={`Razón social`} required>
                  <Row>
                    <Col style={{ marginRight: 10 }} flex={1}>
                      {/* <Debounce /> */}
                      <BaseForm.Item
                        name="companyId"
                        rules={[
                          {
                            required: true,
                            message: 'Selecciona una empresa',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          value={companyValue}
                          onSearch={filterCompany}
                          onChange={handleCompanyChange}
                          placeholder="Selecciona un Empresa"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ minWidth: 350 }}
                          options={optionsCompany}
                        />
                      </BaseForm.Item>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        onClick={() => {
                          setOpenDrawer(true);
                        }}
                        icon={
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ marginRight: 5 }}
                          />
                        }
                      >
                        Nueva
                      </Button>
                    </Col>
                  </Row>
                </BaseForm.Item>
                <CompanyNew
                  open={openDrawer}
                  onClose={onClose}
                  onCompanySaved={setnewCompany}
                />
              </Col>
              <Col flex={2} style={{ marginRight: 10 }}>
                <BaseForm.Item
                  name="representativeId"
                  label={`Contacto de la Empresa`}
                  rules={[
                    {
                      required: true,
                      message: 'Selecciona el contacto de la empresa',
                    },
                  ]}
                >
                  <Select
                    key="CompanyContactSelect"
                    value={companyContacts}
                    options={optionsCompanyContact}
                    onChange={(e) => handleFormChange('representativeId', e)}
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={2}>
                <BaseForm.Item
                  name="legalRepresentativeId"
                  label={`Representante legal de la Empresa`}
                >
                  <Select
                    key="CompanyRepSelect"
                    value={companyLegalReps}
                    options={optionsCompanyLegalReps}
                    onChange={(e) =>
                      handleFormChange('legalRepresentativeId', e)
                    }
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <TimeFields
              handleTimeFieldsChange={handleTimeFieldsChange}
              totalHours={totalHours}
            />
            <BaseForm.Item
              name="colaborationAgreementFileId"
              label="Convenio de Colaboración"
            >
              {form.getFieldValue('colaborationAgreementFileId') && (
                <>
                  <ShowDocumentBtn
                    onClick={() => {
                      setPdfViewerId(
                        form.getFieldValue(
                          'colaborationAgreementFileId'
                        ) as string
                      );
                      setShowDocument(true);
                    }}
                    dataCy="colaboration-agreement"
                  />
                  <br />
                </>
              )}
              <AWSFileUploader
                isReplacing={form.getFieldValue('colaborationAgreementFileId')}
                title="Convenio de Colaboración"
                bucket="colaboration-agreement"
                onUploadCompleted={(id: string | null) => {
                  form.setFieldValue('colaborationAgreementFileId', id);
                  handleFormChange('colaborationAgreementFileId', id);
                }}
                dataCy={`upload-colaboration-agreement-file`}
              />
            </BaseForm.Item>
            {visibleLaptopFields && (
              <Row>
                <Col flex={1}>
                  <BaseForm.Item
                    name="laptopAssignmentFileId"
                    label="Responsiva de entrega de laptop"
                  >
                    {laptopAssignmentHook && (
                      <>
                        <ShowDocumentBtn
                          onClick={() => {
                            setPdfViewerId(laptopAssignmentHook);
                            setShowDocument(true);
                          }}
                          dataCy="laptop-assignment"
                        />
                        <br />
                      </>
                    )}
                    <AWSFileUploader
                      isReplacing={form.getFieldValue('laptopAssignmentFileId')}
                      title="Responsiva de entrega de laptop"
                      bucket="laptop-Assignment"
                      onUploadCompleted={(id: string | null) => {
                        form.setFieldValue('laptopAssignmentFileId', id);
                      }}
                      dataCy={`upload-laptop-assignment`}
                    />
                  </BaseForm.Item>
                </Col>
                <Col flex={1} style={{ marginRight: 50 }}>
                  <BaseForm.Item
                    name="laptopSN"
                    label={'Número de serie de laptop'}
                    rules={[
                      { required: false, message: 'Este campo es obligatorio' },
                    ]}
                  >
                    <Input placeholder="Número de serie" />
                  </BaseForm.Item>
                </Col>
                <Col flex={1}>
                  <BaseForm.Item
                    name="laptopDeliveryDate"
                    label={'Fecha de entrega de laptop'}
                  >
                    <DatePicker />
                  </BaseForm.Item>
                </Col>
              </Row>
            )}
            <Divider orientation="left">Convenio de Aprendizaje</Divider>
            {fileList(
              getFilesNewLearningAgreement(
                letterDisplay,
                INETutorEstudiante,
                selectedWorkCenter
              ),
              onShowDocument,
              onUpload,
              form
            )}
            <br />
            <RotationFields onChange={handleFormChange} />
            <BaseForm.Item noStyle>
              <S.SubmitButton
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoading}
                data-cy="create-agreement-button"
              >
                {`Guardar incompleto`}
              </S.SubmitButton>
            </BaseForm.Item>
            <Document
              fileId={pdfViewerId}
              open={showDocument}
              onCancel={() => {
                setShowDocument(false);
                setPdfViewerId('');
              }}
              onOk={() => setShowDocument(false)}
            />
          </BaseForm>
        </S.SmallCard>
      </S.Wrapper>
    </>
  );
};
