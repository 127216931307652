import { Button, Col, Row, Table } from "antd";
import {
  createCourseRules,
  listCourseRules,
  updateCourseRules,
} from "api/course-rules.api";
import { notificationController } from "controllers/notificationController";
import { useEffect, useState, useCallback } from "react";
import statusMessages from "statusMessages";
import { ApiError } from "types";
import { CourseRules } from "types/course-rules";
import * as S from "../cycles/cycles.styles";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import ModalForm from "components/Form/ModalForm";
import { courseRulesForm } from "./courseRulesForm"; 
import { CourseRulesColumns } from "./CourseRulesColumns";
import { useForm } from "antd/es/form/Form";
import { seedCourseForm } from "./seedCourseForm";
import { SeedCourse } from "types/course";
import { createSeedCourse } from "api/course.api";

interface ListCoursesRulesProps {
  subsystemId: string;
}

const ListCoursesRules = ({ subsystemId }: ListCoursesRulesProps) => {
  const { writeSubsystems } = PermissionsToAccess;
  const [coursesRules, setCoursesRules] = useState<CourseRules[]>([]);
  const [status, setStatus] = useState<"empty" | "error" | "fulfilled">(
    "empty"
  );
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [seedOpen, setSeedOpen] = useState(false);
  const [currentCourseRules, setCurrentCourseRules] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const onGetCoursesRules = useCallback(async () => {
    await listCourseRules(subsystemId)
      .then((response) => {
        setCoursesRules(response);
        setStatus("fulfilled");
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        setStatus("error");
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.courseRules[currentStatus || 500] as string,
        });
      });
  }, [subsystemId, setCoursesRules, setStatus, notificationController]);

  useEffect(() => {
    if (coursesRules.length === 0 && status !== "error") {
      onGetCoursesRules();
    }
  }, [coursesRules, status, onGetCoursesRules]);

  const handleEditCourseRules = async (values: CourseRules) => {
    setLoading(true);
    if (!currentCourseRules) {
      notificationController.error({
        message: "No se encontró el id de las reglas del curso",
      });
      return;
    }
    values.subsystem = subsystemId;
    values._id = currentCourseRules;
    await updateCourseRules(values)
      .then(async () => {
        setEditOpen(false);
        await onGetCoursesRules();
        notificationController.success({
          message: "Las reglas del curso se editaron con éxito",
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController.error({
          message: statusMessages.courseRules[currentStatus || 500] as string,
        });
      }).finally(() => setLoading(false));
  };

  const handleCreateCourseRules = async (values: CourseRules) => {
    setLoading(true);
    values.subsystem = subsystemId;
    await createCourseRules(values)
      .then(async () => {
        await onGetCoursesRules();
        setCreateOpen(false);
        notificationController.success({
          message: "Las reglas del curso se crearon con éxito!",
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController.error({
          message: statusMessages.courseRules[currentStatus || 500] as string,
        });
      }).finally(() => setLoading(false));
  };

  const handleCreateSeed = async (values: SeedCourse) => {
    setLoading(true);
    await createSeedCourse(values).then(() => {
      setSeedOpen(false);
      notificationController.success({
        message: "El curso semilla se creo con éxito!",
      });
    }).catch((error) => {
      const currentStatus = (error as ApiError).statusCode;
      notificationController.error({
        message: statusMessages.course[currentStatus || 500] as string,
      });
    }).finally(() => setLoading(false));
  }

  return (
    <>
      <S.TableCard
        id="courseRules-list"
        title={`Reglas de Cursos`}
        padding=".5rem"
        extra={
          CheckAccess(writeSubsystems) && [
            <Row gutter={[12, 12]}>
              <Col xs={12}>
                <Button
                  type="primary"
                  onClick={() => {
                    setSeedOpen(true);
                    form.resetFields();
                  }}
                >
                  Nuevo curso semilla
                </Button>
              </Col>
              <Col xs={12}>
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateOpen(true);
                    form.resetFields();
                  }}
                >
                  Nueva Regla de Curso
                </Button>
              </Col>
            </Row>
          ]
        }
      >
        <Table
          columns={CourseRulesColumns({
            setCurrentCourseRules,
            setEditOpen,
            onGetCoursesRules,
            form,
          })}
          dataSource={coursesRules}
          size="small"
          scroll={{ x: "max-content" }}
          pagination={false}
          bordered
        />
      </S.TableCard>
      <ModalForm<CourseRules>
        loading={loading}
        modalTitle="Nueva Regla de Curso"
        open={createOpen}
        setOpen={setCreateOpen}
        values={courseRulesForm()}
        onFinish={handleCreateCourseRules}
        form={form}
      />
      <ModalForm<CourseRules>
        loading={loading}
        modalTitle="Editar Regla de Curso"
        open={editOpen}
        setOpen={setEditOpen}
        values={courseRulesForm().filter((value) => value.editable)}
        onFinish={handleEditCourseRules}
        form={form}
      />
      <ModalForm<SeedCourse>
        loading={loading}
        modalTitle="Crear Curso Semilla"
        open={seedOpen}
        setOpen={setSeedOpen}
        values={seedCourseForm}
        onFinish={handleCreateSeed}
        form={form}
      />
    </>
  );
};

export default ListCoursesRules;
