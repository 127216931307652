import { httpApi } from "api/http.api";
import { CourseRules } from "types/course-rules";

const controller = "course-rules";

export const createCourseRules = (values: CourseRules): Promise<JSON> =>
  httpApi.post<JSON>(`${controller}/`, { ...values }).then(({ data }) => data);

export const updateCourseRules = (values: CourseRules): Promise<JSON> =>
  httpApi.put<JSON>(`${controller}/`, { ...values }).then(({ data }) => data);

export const listCourseRules = (subsystemId: string): Promise<CourseRules[]> =>
  httpApi
    .get<CourseRules[]>(`${controller}/${subsystemId}`)
    .then(({ data }) => data);

export const deleteCourseRules = (id: string): Promise<JSON> =>
  httpApi.delete(`${controller}/${id}`).then(({ data }) => data);
