import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { list, register, remove, update} from 'api/workcenter.api';
import { WorkCenter } from 'components/workcenters/types';

export interface WorkCenterSlice {
  workcenters: WorkCenter[];
  status: 'empty' | 'fulfilled' | 'error' | 'updating'
}

const initialState: WorkCenterSlice = {
  workcenters: [],
  status: 'empty'
};

export const doRegister = createAsyncThunk('workcenter/register', async (WorkCenterPayload: WorkCenter) =>
  register(WorkCenterPayload).then((res) => res),
);

export const doUpdate = createAsyncThunk(
  "workcenter/update",
  async (WorkcenterPayload: WorkCenter) => update(WorkcenterPayload).then((res) => res)
);

export const doList = createAsyncThunk('workcenter/list', async () =>
  list().then((res) => res)
)

export const doRemove  = async (WorkCenterPayload: WorkCenter) => remove(WorkCenterPayload).then((res) => res);

export const doClear = createAction('workCenter/clear', () => {
  return {
    payload: "clear",
  };
});

const workcenterSlice = createSlice({
  name: 'workcenter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doRegister.fulfilled, (state, action) => {
      state.workcenters.push(action.payload)
      state.status = 'updating' ;
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.workcenters = action.payload;
      state.status = 'fulfilled' ;
    });
    builder.addCase(doUpdate.fulfilled, (state, action) => {
      const index = state.workcenters.findIndex(r => r._id === action.payload._id);
      state.workcenters[index] = action.payload;
    });
    builder.addCase(doClear, (state) => {
      state.workcenters = [];
      state.status = "empty";
    });
  },
});

export default workcenterSlice.reducer;
