import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WorkCenter, initialValues } from "./types";
import { doList as SubsystemList } from "store/slices/subsystemSlice";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Input, Select, Row, Col, Form } from "antd";
import { doRegister } from "store/slices/workCenterSlice";
import { notificationController } from "controllers/notificationController";
import * as S from "./WorkCenter.styles";
import { WORKCENTERS_PATH } from "components/router/AppRouter";
import { OptionsSelect } from "types/optionsSelect";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { doClear as doClearWorkcenters } from "store/slices/workCenterSlice";
import { doClear as doClearEmployees, doList as pricipalDoList } from "store/slices/employeeSlice";
import { FileChangesType } from "types";
import { Document } from "components/common/Document/Document";
import { fileList, getFilesWorkCenter } from "components/Agreements/utils/fileList";
import { doList as doListMunicipalities } from "store/slices/municipalitySlice";
import { SendCustomErrorMessage } from "utils/Status&ErrorMessage";

export const WorkCenterNewForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { employees, status: employeesStateStatus } = useAppSelector((state) => state.employee);
  const { subsystems, status: subsystemStateStatus } = useAppSelector((state) => state.susbsystem)
  const { municipalities, status : municipalityStateStatus } = useAppSelector((state) => state.municipality);

  const [isLoading, setLoading] = useState(false);
  //Select
  const [optionsEmployee, setOptionsEmployee] = useState<OptionsSelect[]>([]);
  const [optionsSubsystem, setOptionsSubsystem] = useState<OptionsSelect[]>([]);
  const [optionsSubsystemLong, setOptionsSubsystemLong] = useState<OptionsSelect[]>([]);
  const [optionsSubsystemPrincipals, setOptionsSubsystemPrincipals] = useState<OptionsSelect[]>([]);
  const [workCenterMunicipalityOptions, setWorkCenterMunicipalityOptions] = useState<OptionsSelect[]>([]);
  const [positionValue, setPositionValue] = useState<string>();
  
  const [valueSubsystemId, setValueSubsystemId] = useState('');
  const [valueEducativeLevel, setValueEducativeLevel] = useState('');

  useEffect(() => {
    if (municipalityStateStatus !== 'fulfilled') {
      dispatch(doListMunicipalities());
    }
    const municipalitiesOptions = municipalities.map((mun) => ({
      label: mun.name,
      value: mun._id!,
      key: mun._id!,
    }));
      setWorkCenterMunicipalityOptions(municipalitiesOptions);
  }, [municipalities, dispatch, municipalityStateStatus]);

  useEffect(() => {
    if (municipalityStateStatus !== 'fulfilled') {
      dispatch(doListMunicipalities());
    }
    const municipalitiesOptions = municipalities.map((mun) => ({
      label: mun.name,
      value: mun._id!,
      key: mun._id!,
    }));
      setWorkCenterMunicipalityOptions(municipalitiesOptions);
  }, [municipalities, dispatch, municipalityStateStatus]);

  const [filesChanges, setfilesChanges] = useState<FileChangesType | null>(null);
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");

  // files

  const onShowDocument = (id: string) => {
    setPdfViewerId(id);
    setShowDocument(true);
  };

  const onUpload = (field: string, id: string | null) => {
    form.setFieldValue(`${field}`, id);
    setfilesChanges({ ...filesChanges, [field]: id });
  };

  useEffect(() => {
    if (employeesStateStatus !== "fulfilled") {
      dispatch(pricipalDoList());
    }
    const principals = employees.map((e) => ({
      label: e.name,
      value: e._id!,
      key: e._id!,
    }));
    setOptionsEmployee(principals);
  }, [employees, dispatch, employeesStateStatus]);

  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(SubsystemList());
    }
    const optionsRaw: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.nameShort ?? " - "} `,
      value: subs.nameShort!,
      key: subs._id!,
    }));
    setOptionsSubsystem(optionsRaw);
    const optionsRawLong: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.name ?? " - "} `,
      value: subs.name!,
      key: subs._id!,
    }));
    setOptionsSubsystemLong(optionsRawLong);
    const optionsRawPrincipals: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.principal ?? " - "} `,
      value: subs.principal!,
      key: subs._id!,
    }));
    setOptionsSubsystemPrincipals(optionsRawPrincipals);
  }, [dispatch, subsystemStateStatus, subsystems]);

  const handleSubsystemChange = (susbSelected : string) => {
    const subsystemSel = subsystems.find((s) => s.nameShort === susbSelected);
    setValueEducativeLevel(
      subsystemSel?.educativeLevel !== undefined ? subsystemSel.educativeLevel : ''
    );
    setValueSubsystemId(subsystemSel?._id !== undefined ? subsystemSel._id : '');
    form.setFieldValue("subsystem",subsystemSel?.name)
    form.setFieldValue("currentSubsystemPrincipal", subsystemSel?.principal)
  }
  const handleSubsystemLongChange = (susbSelected : string) => {
    const subsystemSel = subsystems.find((s) => s.name === susbSelected);
    setValueEducativeLevel(
      subsystemSel?.educativeLevel !== undefined ? subsystemSel.educativeLevel : ''
    )
    setValueSubsystemId(subsystemSel?._id !== undefined ? subsystemSel._id : '');
    form.setFieldValue("subsystemShort",subsystemSel?.nameShort)
    form.setFieldValue("currentSubsystemPrincipal", subsystemSel?.principal)
  }

  const handleSubmit = (values: WorkCenter) => {
    if(valueEducativeLevel === '') {
      notificationController.error({
        message: "El plantel no tiene nivel educativo asignado",
      });
      return
    }
    if(typeof values.municipality !== 'string') {
      notificationController.error({
        message: "Hubo un error asignando el municipio elegido, intenta asignarlo de nuevo",
      });
      return
    }
    const workCenterData = {
      ...values, ...filesChanges ,subsystemId : valueSubsystemId, position: positionValue
    };
    setLoading(true);
    dispatch(doRegister(workCenterData))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Plantel registrado correctamente",
        });
        dispatch(doClearWorkcenters());
        dispatch(doClearEmployees());
        navigate(WORKCENTERS_PATH)
      })
      .catch((error) => {
        SendCustomErrorMessage({ error });
        setLoading(false);
      });
  };

  return (
    <>
      <S.Wrapper>
        <S.SmallCard
        id='employees-new'
        title={
          <>
            <CloseButton onClick={()=>{navigate(WORKCENTERS_PATH)}}></CloseButton>
            <span>&nbsp; {`Nuevo Plantel`}</span>
          </>
        }
        padding='3rem'>
          <BaseForm
            layout='vertical'
            onFinish={handleSubmit}
            requiredMark='optional'
            initialValues={initialValues}
            form={form}
          >
            <Row>
              <Col style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='nameShort'
                  label={`Nombre Corto`}
                  rules={[
                    { required: true, message: "Inrese un nombre corto para el plantel" },
                  ]}
                >
                  <Input
                    placeholder='CBTIS 258'
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={2}>
                <BaseForm.Item
                  name='name'
                  label={`Nombre Completo`}
                  rules={[
                    { required: true, message: "Ingrese el nombre completo del plantel" },
                  ]}
                >
                  <Input
                    placeholder='CENTRO DE BACHILLERATO TECNOLÓGICO INDUSTRIAL Y DE SERVICIOS NÚM. 258'
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={3} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='principal'
                  label={`Director(a) del Plantel`}
                  required
                  rules={[
                    { required: true, message: "Seleccione a un director del plantel" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder='Director(a) del plantel'
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={optionsEmployee}
                    onChange={(value) => {
                      const employee = employees.find(
                        (employee) => employee._id === value
                      );
                      const newPositionValue =
                        employee?.position !== undefined ? employee.position : '';
                      setPositionValue(newPositionValue);
                      form.setFieldsValue({ position: newPositionValue });
                    }}
                  />
                </BaseForm.Item>
                <BaseForm.Item
                  name='position'
                  label={`Posición del director(a)`}
                >
                  <Input
                    value={positionValue}
                    onChange={(e) => setPositionValue(e.target.value)}
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item name='' label='Documentación del plantel' required>
                  {fileList(getFilesWorkCenter(), onShowDocument, onUpload, form)}
                </BaseForm.Item>
                <Document
                  fileId={pdfViewerId}
                  open={showDocument}
                  onCancel={() => {
                    setShowDocument(false);
                    setPdfViewerId('');
                  }}
                  onOk={() => {
                    setShowDocument(false);
                    setPdfViewerId('');
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='subsystemShort'
                  label={`Subsistema / IES (Institución de educación superior)`}
                  rules={[
                    { required: true, message: "Asigne un subsistema" },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    options={optionsSubsystem}
                    onChange={handleSubsystemChange}
                    placeholder='Selecciona un subsistema'
                  />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }}>
                <BaseForm.Item
                  required
                  label={`Nivel educativo del subsistema`}
                >
                  <Input
                    disabled
                    style={{ width: "100%" }}
                    value={valueEducativeLevel}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
            <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='subsystem'
                  label={`Nombre Completo del Subsistema / IES (Institución de educación superior)`}
                  rules={[
                    { required: true, message: "Escoja el nombre completo del subsistema" },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    options={optionsSubsystemLong}
                    onChange={handleSubsystemLongChange}
                    placeholder='Selecciona un subsistema'
                  />
                </BaseForm.Item>
              </Col>
              <Col style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='municipality'
                  label={`Municipio del plantel`}
                  rules={[{ required: true, message: 'Selecciona el municipio del plantel' }]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: 200 }}
                    options={workCenterMunicipalityOptions}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
            <Col flex={1}>
                <BaseForm.Item
                  label={`Director(a) General Actual del subsistema / Rector(a) de la IES`}
                  required={false}
                  name="currentSubsystemPrincipal"
                >
                    <Select
                    showSearch
                    style={{ width: "100%" }}
                    options={optionsSubsystemPrincipals}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ marginRight: 20 }}>
              <BaseForm.Item
                  name="keySep"
                  label={`Clave del Plantel`}
                  rules={[
                    { required: true, message: "Ingrese la clave del plantel" },
                  ]}
                >
                  <Input placeholder="Ingrese la clave" />
                </BaseForm.Item>
              </Col>
            </Row>

            <BaseForm.Item noStyle>
              <S.SubmitButton
                type='primary'
                htmlType='submit'
                size='large'
                loading={isLoading}
              >
                {`Registrar`}
              </S.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </S.SmallCard>
      </S.Wrapper>
    </>
  );
};
