import { useState } from "react";
import * as S from "./subsystem.styles";
import { Subsystem, educativeLevelOptions } from "./types";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch } from "hooks/reduxHooks";
import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import { doUpdate } from "store/slices/subsystemSlice";
import { notificationController } from "controllers/notificationController";
import { SUBSYSTEMS_PATH } from "components/router/AppRouter";
import { useNavigate } from "react-router-dom";
import { SendCustomErrorMessage } from "utils/Status&ErrorMessage";

interface SubsystemEditProps {
  subsystem: Subsystem;
}

export const SubsystemEdit = ({ subsystem }: SubsystemEditProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: Subsystem) => {
    setLoading(true);
    if(!subsystem._id){
      return notificationController.error({message:'No se encontró el id del subsistema'})
    }
    dispatch(doUpdate({...values, subsystemId:subsystem._id}))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Se editó el subsistema correctamente",
        });
        navigate(SUBSYSTEMS_PATH);
      })
      .catch((error) => {
        SendCustomErrorMessage({error});
        setLoading(false);
      });
  };

  return (
    <BaseForm
      form={form}
      layout='vertical'
      onFinish={handleSubmit}
      requiredMark='optional'
      initialValues={subsystem}
    >
      <Row>
        <Col style={{ marginRight: 20 }}>
          <BaseForm.Item
            name='nameShort'
            label={`Nombre Corto`}
            rules={[{ required: true, message: 'Ingresa un nombre corto para el subsistema' }]}
          >
            <Input
              placeholder='CBTIS
'
            />
          </BaseForm.Item>
        </Col>
        <Col flex={2} style={{ marginRight: 20 }}>
          <BaseForm.Item
            name='name'
            label={`Nombre Completo`}
            rules={[{ required: true, message: 'Ingresa el nombre completo del subsistema' }]}
          >
            <Input placeholder='CENTRO DE BACHILLERATO TECNOLÓGICO INDUSTRIAL Y DE SERVICIOS' />
          </BaseForm.Item>
        </Col>
        <Col flex={2} style={{ marginRight: 20 }}>
          <BaseForm.Item
            name='maxCycles'
            label={`Número máximo de ciclos por alumno`}
            rules={[{ required: true, message: 'Aun te falta llenar este campo' }]}
          >
            <InputNumber min={1} />
          </BaseForm.Item>
        </Col>
        <Col>
          <BaseForm.Item
            name='educativeLevel'
            label={`Nivel educativo`}
            rules={[{ required: true, message: 'Selecciona el nivel educativo' }]}
          >
            <Select
              style={{ width: 200 }}
              placeholder='Selecciona un nivel educativo'
              options={educativeLevelOptions}
            />
          </BaseForm.Item>
        </Col>
      </Row>
      <Row>
        <Col flex={1} style={{ marginRight: 20 }}>
          <BaseForm.Item
            name='principal'
            label={`Director(a) general del subsistema / Rector(a) de la IES`}
            required
            rules={[{ required: true, message: 'Ingresa el nombre del director' }]}
          >
            <Input />
          </BaseForm.Item>
        </Col>
      </Row>
      <BaseForm.Item noStyle>
        <S.SubmitButton
          type='primary'
          htmlType='submit'
          size='large'
          loading={isLoading}
        >
          {`Actualizar`}
        </S.SubmitButton>
      </BaseForm.Item>
    </BaseForm>
  );
};
