import { httpApi } from "api/http.api";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { File } from "components/files/types";
import dayjs from "dayjs";
import { ApiError } from "types";

export type Presign = {
  url: string;
  fileId: string;
  storageType: string;
}

const controller = `file`;

export const upload = async (filePayload: FormData): Promise<File[]> => {
  return httpApi
    .post<File[]>(`${controller}`, filePayload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => data);
};

export const deleteFileById = async (id: string, config?: AxiosRequestConfig) : Promise<AxiosResponse | AxiosError> => {
  return httpApi
    .delete<AxiosResponse>(`${controller}/${id}`, config)
    .then((data) => data)
    .catch((e) => e);
}

export const getFileUrlbyId = (id: string): Promise<string> =>
  httpApi.get<string>(`${controller}/geturl/${id}`).then(({ data }) => data);

  export const getFileBufferbyId = (id: string): Promise<Uint8Array> =>
  httpApi.get(`${controller}/getfile/${id}`, { responseType: 'arraybuffer' })
    .then(({ data }) => new Uint8Array(data));

export const getPresignedUrl = (): Promise<Presign> =>
  httpApi.get<Presign>(`${controller}/presignurl`).then(({data}) => data );

export const downloadFiles = async (agreementId: string, sequence: number | ""): Promise<AxiosResponse | unknown> => {
    const response = await httpApi.post(`${controller}/downloadAllFiles/${agreementId}`, {}, {
      responseType: 'blob',
    });

    if (response.status !== 200) {
      throw { statusCode: response.status, message: response.statusText } as ApiError;
    }

    const blob = new Blob([response.data], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `ArchivosDelExpediente_${sequence}_${dayjs().format("DD-MM-YYYY")}.zip`;
    link.click();

    return response;
};