import styled from 'styled-components';
import { Card as CommonCard } from 'components/common/Card/Card';
import { Button } from 'antd';
import { FONT_SIZE, FONT_WEIGHT } from 'styles/themes/constants';
import { StudentStatuses } from "constants/enums/modulesStatuses";

export const Wrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
`;

export const WarningCard = styled(CommonCard)`
  background: #FFF3DB;
  border: 1px solid #F7C462;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

export const SubmitButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const colorStatus = (status: string) => {
  switch (status) {
    case StudentStatuses.GraduatedStatus:
    case StudentStatuses.ActiveStatus:
      return "#329213";
    case StudentStatuses.WaitingStatus:
      return "#FF8C00";
    case StudentStatuses.DeclinedStatus:
      return "#cc002e";
    default:
      return "#000000";
  }
};