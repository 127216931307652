import { filterProps } from "components/common/FilterProps";
import { Company, statusCompanyDict } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Button, Tag, Spin } from "antd";

import { ColumnsType } from "antd/es/table";
import { Key } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export const getColumns = function (
  onDeleteCompany: (data: Company) => void,  loadingDelete?: boolean
): ColumnsType<Company> {
  return [
    {
      title: "Razón social",
      dataIndex: "name",
      key: "index",
      sorter: (a: Company, b: Company) => a.name.localeCompare(b.name),
      ...filterProps,
      onFilter: (value: boolean | Key, record: Company) => {
        return record.name
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Nombre comercial",
      dataIndex: "commercialName",
      key: "index",
      sorter: (a: Company, b: Company) => a.name.localeCompare(b.name),
      ...filterProps,
      onFilter: (value: boolean | Key, record: Company) => {
        return record.name
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: "RFC",
      dataIndex: "taxId",
      key: "taxId",
      sorter: (a: Company, b: Company) =>
        a.taxId && b.taxId
          ? a.taxId.localeCompare(b.taxId)
          : a.taxId
          ? a.taxId.localeCompare("")
          : b.taxId
          ? b.taxId.localeCompare("")
          : "".localeCompare(""),
      ...filterProps,
      onFilter: (value: boolean | Key, record: Company) => {
        return record.taxId
          ? record.taxId.toLowerCase().includes(value.toString().toLowerCase())
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <Tag color={getStatusLights(status)}>{statusCompanyDict[status as keyof typeof statusCompanyDict]}</Tag>
      ),
      sorter: (a: Company, b: Company) =>
      a.status && b.status
        ? a.status.localeCompare(b.taxId)
        : a.status
        ? a.status.localeCompare("")
        : b.status
        ? b.status.localeCompare("")
        : "".localeCompare(""),
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "",
      key: "x",
      render: (data: Company) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Popover
              content={
                <Spin spinning={loadingDelete}>
                  <Button
                  type="primary"
                  danger
                  block
                  onClick={() => {
                    onDeleteCompany(data);
                  }}
                >
                  Confirmar
                </Button>
                </Spin>
              }
              trigger="click"
              placement="left"
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
            </Popover>
          </span>
        );
      },
    },
  ];
};

export const getStatusLights = (status: string) => {
  return status === "Sin Verificar"
    ? "#FF8C00"
    : status === "Rechazada"
    ? "#cc002e"
    : "#329213";
};
