import { requiredAdultFields, requiredFields } from "components/Agreements/constants";
import { Agreement } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Col, Row } from "antd";
import { faCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

type Props = {
  agreement: Agreement;
  isAdult: boolean;
}

const RequiredFieldsCheckList = ({ agreement, isAdult }: Props) => {
  const fields = isAdult ? requiredAdultFields() : requiredFields;
  return (
    <>
      <div className="agreement-checklist">
        <h3>Checklist de campos obligatorios</h3>
        <p>Puedes guardar el expediente en cualquier momento, pero no se podrá enviar a revisión hasta alcanzar el 100%</p>
        <Row>
          { fields.map((f,k) => (
              <Col key={k} xs={24} md={8}>
                {agreement[f.id as keyof Agreement] !== 0 &&
                  agreement[f.id as keyof Agreement] && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ marginRight: 5, color: `green` }}
                    />
                  )}
                {!agreement[f.id as keyof Agreement] && (
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    style={{ marginRight: 5, color: `red` }}
                  />
                )}
                {f.label}
              </Col>
            )) }
        </Row>
      </div>
    </>
  )
}

export default RequiredFieldsCheckList;