import { PermissionsType, } from "components/employees/types";
import { useAppSelector } from "hooks/reduxHooks";
import { UserRoles } from "constants/roles";

export const CheckAccess = (accessRequest: PermissionsType) => {
  const { user } = useAppSelector((state) => state.user);
  if(accessRequest.module === "logout" || accessRequest.module === "dashboard") {
    return true;
  }
  if(user?.role && user.role === UserRoles.AdminRole) {
    return true;
  }
  if(accessRequest.module === "approvedAgreement") {
    return false;
  }
  if (user?.permissions) {
    const hasModuleAccess = user.permissions.some(p => p.module === accessRequest.module);
    const hasPermissionAccess = user.permissions.some(p => p.module === accessRequest.module && p.permission >= accessRequest.permission);
    return hasModuleAccess && hasPermissionAccess;
  }
  return false;
}
