import {
  Button,
  Popover,
  Modal,
  Form,
  Row,
  Col,
  Divider,
} from "antd";
import React, { useEffect, useState } from "react";
import { Agreement } from "components/Agreements/types";
import TextArea from "antd/es/input/TextArea";
import { nextApprovalStep, statusUpdate } from "api/agreements.api";
import { notificationController } from "controllers/notificationController";
import { doList } from "store/slices/agreementSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Link } from "react-router-dom";
import { AGREEMENTS_PATH } from "components/router/AppRouter";
import { doGetApprovalProcess } from "store/slices/configSlice";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import { IsAdultStudent } from "../utils/utils";
import { PermissionsType } from "components/employees/types";
import { requiredAdultFields, requiredFields } from "../constants";
import statusMessages from "statusMessages";
import { ApiError } from "types";
import { getFullName } from "hooks/getFullName";
import { UserRoles } from "constants/roles";
import { AgreementStatuses } from "constants/enums/modulesStatuses";

const { writeAgreements, editApprovedAgreements } = PermissionsToAccess;

type OptionProps = {
  rowSelected: Agreement;
  closeDrawer: (arg0: boolean) => void;
}
export const OptionsDrawerBar = ({
  rowSelected,
  closeDrawer,
}: OptionProps) => {
  const dispatch = useAppDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [nextRevisionBtnVisible, setnextRevisionBtnVisible] = useState(false);
  const [approveBtnVisible, setApproveBtnVisible] = useState(false);
  const [rejectBtnVisible, setRejectBtnVisible] = useState(false);
  const [valuesTextArea, setValuesTextArea] = useState<string>("");
  const [newStatus, setNewStatus] = useState<AgreementStatuses>(AgreementStatuses.DraftStatus);
  const [editRoute, setEditRoute] = useState<string>("edit");
  const [editPermission, setEditPermission] =
    useState<PermissionsType>(writeAgreements);
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.user);

  //config for approval roles
  const { validApprovalRoles, configStatus } = useAppSelector(
    (state) => state.config
  );

  useEffect(() => {
    if (configStatus !== "fulfilled") {
      dispatch(doGetApprovalProcess());
    }
    showNextRevisionBtn();
    showRejectBtn();
    showApproveBtn();
  }, [dispatch, validApprovalRoles, configStatus, rowSelected]);

  useEffect(() => {
    if (rowSelected && rowSelected.approvalStep !== undefined) {
      showNextRevisionBtn();
      showRejectBtn();
      showApproveBtn();
    }
    if (rowSelected && rowSelected.status) {
      checkEditAgreement();
    }
  }, [rowSelected]);

  const isAgreementComplete = () => {
    const agreementSelected: Record<string, unknown> = {
      sequence: rowSelected.sequence,
      studentId: rowSelected.studentId,
      workCenterId: rowSelected.workCenterId,
      companyId: rowSelected.companyId,
      representativeId: rowSelected.representativeId,
      legalRepresentativeId: rowSelected.legalRepresentativeId,
      startDate: rowSelected.startDate,
      endDate: rowSelected.endDate,
      status: rowSelected.status,
      learningAgreementFileId: rowSelected.learningAgreementFileId,
      colaborationAgreementFileId: rowSelected.colaborationAgreementFileId,
      tutorIdFileId: rowSelected.tutorIdFileId,
      tutorAuthLetterFileId: rowSelected.tutorAuthLetterFileId,
      insuranceFileId: rowSelected.insuranceFileId,
      rotationPlanFileId: rowSelected.rotationPlanFileId,
      learningPositionsFieldId: rowSelected.learningPositionsFieldId,
      matrixFieldId: rowSelected.matrixFieldId,
      principalFileId: rowSelected.principalFileId,
      principal: rowSelected.principal,
      principalPosition: rowSelected.principalPosition,
      rotationTimes: rowSelected.rotationTimes,
      rotationPositions: rowSelected.rotationPositions,
      hoursPerWeek: rowSelected.hoursPerWeek,
    };
    const requiredAgreementFields: Record<string, unknown>[] =
      !IsAdultStudent(rowSelected.studentId)
        ? requiredFields
        : requiredAdultFields();
    const hasAllFields = requiredAgreementFields.map((field) => {
      const returns = agreementSelected[field.id as string] ? true : false;
      return returns;
    });
    return !hasAllFields.includes(false);
  };

  const checkEditAgreement = () => {
    if (rowSelected.status === AgreementStatuses.CompletedStatus) {
      setEditPermission(editApprovedAgreements);
      setEditRoute("editapproved");
    } else {
      setEditPermission(writeAgreements);
      setEditRoute("edit");
    }
  };

  const showNextRevisionBtn = () => {
    if (rowSelected.status === AgreementStatuses.CompletedStatus) {
      setnextRevisionBtnVisible(false);
      return false;
    }
    if (
      JSON.stringify(rowSelected.approvalStep) ===
      JSON.stringify(validApprovalRoles[0])
    ) {
      setnextRevisionBtnVisible(false);
      return false;
    }
    if (user?.role && user?.role === UserRoles.AdminRole) {
      setnextRevisionBtnVisible(true);
      return true;
    }
    if (
      JSON.stringify(user?.approvementRole) ===
      JSON.stringify(rowSelected.approvalStep)
    ) {
      setnextRevisionBtnVisible(true);
      return true;
    }
    if (
      rowSelected.status === AgreementStatuses.DraftStatus ||
      (rowSelected.status === AgreementStatuses.CorrectionStatus &&
        (JSON.stringify(user?.approvementRole) ===
          JSON.stringify(validApprovalRoles[0]) ||
          JSON.stringify(user?.approvementRole) ===
            JSON.stringify(validApprovalRoles[1])))
    ) {
      setnextRevisionBtnVisible(true);
      return true;
    }
  };
  const showRejectBtn = () => {
    if (
      rowSelected.status === AgreementStatuses.CompletedStatus ||
      rowSelected.status === AgreementStatuses.CorrectionStatus
    ) {
      setRejectBtnVisible(false);
      return false;
    }
    if (user?.role && user?.role === UserRoles.AdminRole) {
      setRejectBtnVisible(true);
      return true;
    }
    if (
      JSON.stringify(user?.approvementRole) ===
        JSON.stringify(rowSelected.approvalStep) ||
      JSON.stringify(user?.approvementRole) ===
        JSON.stringify(validApprovalRoles[0])
    ) {
      setRejectBtnVisible(true);
      return true;
    }
    setRejectBtnVisible(false);
    return false;
  };
  const showApproveBtn = () => {
    if (user?.role && user?.role === UserRoles.AdminRole
    ) {
      setApproveBtnVisible(true);
      return true;
    }
    if (
      rowSelected.status === AgreementStatuses.CompletedStatus ||
      rowSelected.status === AgreementStatuses.CorrectionStatus
    ) {
      setApproveBtnVisible(false);
      return false;
    }
    if (!isAgreementComplete()) {
      setApproveBtnVisible(false);
      return false;
    }
    if (
      JSON.stringify(user?.approvementRole) ===
        JSON.stringify(rowSelected.approvalStep) &&
      JSON.stringify(rowSelected.approvalStep) ===
        JSON.stringify(validApprovalRoles[0])
    ) {
      setApproveBtnVisible(true);
      return true;
    }
    if (
      JSON.stringify(user?.approvementRole) ===
      JSON.stringify(validApprovalRoles[0])
    ) {
      setApproveBtnVisible(true);
      return true;
    }
    setApproveBtnVisible(false);
    return false;
  };

  const handleOk = (newStatus: AgreementStatuses) => {
    if (
      (newStatus ===AgreementStatuses.CorrectionStatus) &&
      !valuesTextArea
    ) {
      notificationController.warning({
        message: "Escribe las correcciones que necesita",
      });
      return;
    }
    statusUpdate({
      id: rowSelected._id ?? "",
      comment: valuesTextArea,
      status: newStatus,
      approvalStep: rowSelected.approvalStep,
      currentStatus: rowSelected.status,
    })
      .then(() => {
        notificationController.success({
          message: "Expediente Actualizado",
        });
        dispatch(doList())
        .unwrap()
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationController[currentStatus === 404 ? "info" : "error"]({
            message: statusMessages.agreement[currentStatus || 500] as string,
          });
        });
        setModalVisible(false);
        closeDrawer(false);
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.agreement[currentStatus || 500] as string,
        });
        setModalVisible(false);
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValuesTextArea(e.target.value);
  };
  const approveAgreement = () => {
    setLoading(true);
    if (
      rowSelected.companyId.status !== "Verificada" ||
      rowSelected.representativeId.status !== "Verificada"
    ) {
      notificationController.error({
        message: "Error ",
        description:
          "Un expediente no puede ser aprobado si la empresa o sus representantes no se han verificado",
      });
      setLoading(false);
      return false;
    }
    const student = rowSelected.studentId;
    const { name, paternalLastname, maternalLastname, email } = student
    statusUpdate({
      id: rowSelected._id ?? "",
      comment: "Aprobado",
      status: AgreementStatuses.CompletedStatus,
      approvalStep: rowSelected.approvalStep,
      currentStatus: rowSelected.status,
      canvasData: {
        name: getFullName(name, paternalLastname, maternalLastname),
        email: email,
      },
    })
      .then(() => {
        notificationController.success({
          message: "Expediente Actualizado",
        });
        dispatch(doList())
        .unwrap()
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationController[currentStatus === 404 ? "info" : "error"]({
            message: statusMessages.agreement[currentStatus || 500] as string,
          });
        });
        setModalVisible(false);
        closeDrawer(false);
        setApproveBtnVisible(false);
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController.error({
          message: "Ocurrió un error",
          description: statusMessages.agreement[currentStatus || 500] as string,
        });
        setModalVisible(false);
      });
    setLoading(false);
  };

  const nextRevision = () => {
    nextApprovalStep(rowSelected._id ?? "")
      .then(() => {
        notificationController.success({
          message: "expediente enviado a revisión",
        });
        dispatch(doList())
        .unwrap()
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationController[currentStatus === 404 ? "info" : "error"]({
            message: statusMessages.agreement[currentStatus || 500] as string,
          });
        });
        setModalVisible(false);
        closeDrawer(false);
        setnextRevisionBtnVisible(false);
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController[currentStatus === 404 ? "info" : "error"]({
          message: statusMessages.agreement[currentStatus || 500] as string,
        });
        setModalVisible(false);
      });
  };

  const approveBtn = (
    <Popover
      className="btn-dropdown-custom"
      content={
        <Button
          type="primary"
          style={{ backgroundColor: "#329213" }}
          onClick={approveAgreement}
        >
          Confirmar
        </Button>
      }
      trigger="click"
      placement="left"
    >
      <Button type="primary" style={{ backgroundColor: "#3fb618" }}>
        Aprobar
      </Button>
    </Popover>
  );

  const rejectBtn = (
    <Button
      className="btn-dropdown-custom"
      danger
      onClick={() => {
        setModalVisible(true);
        setNewStatus(AgreementStatuses.CorrectionStatus);
      }}
    >
      Necesita correcciones
    </Button>
  );

  const sendToRevisionBtn = (
    <Popover
      className="btn-dropdown-custom"
      content={
        <Button
          type="primary"
          style={{ backgroundColor: "#FF8C00" }}
          onClick={nextRevision}
        >
          Confirmar
        </Button>
      }
      trigger="click"
      placement="left"
    >
      <Button type="primary" style={{ backgroundColor: "#FF8C00" }}>
        Enviar a siguiente revisión
      </Button>
    </Popover>
  );

  const requireRevisionBtn = (
    <Popover
      className="btn-dropdown-custom"
      content={
        <Button
          type="primary"
          style={{ backgroundColor: "#FF8C00" }}
          onClick={() => {
            handleOk(AgreementStatuses.ReviewStatus);
          }}
        >
          Confirmar
        </Button>
      }
      trigger="click"
      placement="left"
    >
      <Button type="primary" style={{ backgroundColor: "#FF8C00" }}>
        Requiere revisión de subsistema
      </Button>
    </Popover>
  );

  const editBtn = (
    <Link to={`${AGREEMENTS_PATH}/${editRoute}/${rowSelected._id}`}>
      <Button className="btn-dropdown-custom" type="primary">
        Editar
      </Button>
    </Link>
  );
  return (
    <>
      <Row gutter={5} justify="space-evenly">
        {approveBtnVisible && <Col flex={1}>{approveBtn}</Col>}
        {CheckAccess(editPermission) && <Col flex={1}>{editBtn}</Col>}
      </Row>
      <Divider style={{ margin: "5px 0px" }} />
      <Row gutter={5} justify="space-evenly">
        {rejectBtnVisible && <Col flex={1}>{rejectBtn}</Col>}
        {nextRevisionBtnVisible && (
          <Col flex={1}>{sendToRevisionBtn}</Col>
        )}
        {rowSelected.inactiveDate && (
          <Col flex={1}>{requireRevisionBtn}</Col>
        )}
      </Row>
      <Modal
        title="Correcciones necesarias o motivo baja"
        open={modalVisible}
        destroyOnClose
        onOk={() => handleOk(newStatus)}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" danger onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            type="primary"
            onClick={() => handleOk(newStatus)}
            style={{ backgroundColor: "#3fb618" }}
            loading={loading}
          >
            Guardar
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          style={{ marginTop: 25 }}
          onFinish={() => handleOk(newStatus)}
        >
          <Form.Item
            label="Motivo"
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <TextArea
              placeholder="Ingresa las correcciones que se necesitan"
              size="small"
              allowClear
              required
              onChange={handleChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
