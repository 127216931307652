import axios, { AxiosError } from 'axios';
import { ApiError } from 'types';
import { readToken } from 'services/localStorage.service';
import * as Sentry from '@sentry/react';

export const httpApi = axios.create({
  baseURL: '/api/',
});

httpApi.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${readToken()}`;

  return config;
});
httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  if (axios.isAxiosError(error)) {
    const { status, data } = error.response as {
      status: number;
      data: {
        message: string;
        status: number;
      };
    };
    data.status = status;
    if (status === 401) {
      const alink = document.createElement('a');
      alink.href = '/auth/logout';
      alink.click();
      return;
    }
    // If axios error is different to 401, throw new error
    throw new ApiError(data.status, data.message);
  }
  Sentry.captureException({
    exception: error,
    message: 'No se encontró el error, un endpoint no funciona bien',
  });
  throw new ApiError(500, 'No se encontró el error');
});

export interface ApiErrorData {
  message: string;
  status: number;
}
