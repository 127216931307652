import React, { useEffect, useState } from "react";
import * as S from "./Employees.styles";
import { Employee, PermissionsType, permissionsDefault, PermissionModulesRelation as moduleAccess, AccessLvls } from "./types";
import { doList } from "store/slices/workCenterSlice";
import { doList as SubsystemList } from "store/slices/subsystemSlice";
import { doGetApprovalProcess } from 'store/slices/configSlice';
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Form, Input, Row, Col, Select, Switch, Divider } from 'antd';
import { doUpdate } from "store/slices/employeeSlice";
import { EMPLOYEES_PATH } from "components/router/AppRouter";
import { useNavigate } from "react-router-dom";
import { notificationController } from "controllers/notificationController";
import { OptionsSelect } from "types/optionsSelect";
import { escapeRegExp } from "lodash";
import { UserRoles } from "constants/roles";

interface EmployeeEditProps {
  employee: Employee;
}

export const EmployeeEdit = ({ employee }: EmployeeEditProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  //data for select 
  const { workcenters,  status: workcenterStateStatus } = useAppSelector((state) => state.workcenter);
  const { subsystems,  status: subsystemStateStatus } = useAppSelector((state) => state.susbsystem);
  const [optionWorkCenter, setOptionWorkCenter] = useState<OptionsSelect[]>([]);
  const [optionsSubsystem, setOptionsSubsystem] = useState<OptionsSelect[]>([]);
  const [valueSubSystem, setValueSubSystem] = useState("");
  const [status, setStatus] = useState(Boolean);

  //config for approval roles
  const {validApprovalRoles, configStatus }  = useAppSelector((state) => state.config);
  const [optionApprovalRoles, setOptionApprovalRoles] = useState<OptionsSelect[]>([]);

  //for access permissions
  const [userPermissions, setUserPermissions] = useState<PermissionsType[]>(permissionsDefault);
  const accessLevelsOptions: OptionsSelect[] = [
    {
      label: "Sin acceso",
      value: AccessLvls.noAccess,
      key: "0"
    },
    {
      label: "Solo lectura",
      value: AccessLvls.readOnly,
      key: "1"
    },
    {
      label: "Lecura y escritura",
      value: AccessLvls.readWrite,
      key: "2"
    }
  ];

  useEffect(() => {
    if (configStatus !== "fulfilled") {
      dispatch(doGetApprovalProcess());
    } else {
      const options = validApprovalRoles.map((role) => ({
        label: role.name!,
        value: role.name!,
        key: role.name!,
      }));
      const deselectOption = {
        label: 'Sin rol (solo puede mandar borradores a revisión)',
        value: '',
        key: 'unset',
      };
      setOptionApprovalRoles([...options, deselectOption]);
    }
  }, [dispatch, validApprovalRoles, configStatus]);

  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(SubsystemList());
    }
    const optionsRaw: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.nameShort ?? " - "} `,
      value: subs.nameShort!,
      key: subs._id!,
    }));
    setOptionsSubsystem(optionsRaw);
  }, [dispatch, subsystemStateStatus, subsystems]);

  useEffect(() => {
    if(employee.subsystem) {
      setValueSubSystem(employee.subsystem ?? "");
      changeSubsystem(employee.subsystem);
    }
    if(employee.permissions){
      const userAcc = employee.permissions
      setUserPermissions(userAcc);
      form.setFieldValue("permission.user",userAcc[moduleAccess.user].permission);
      form.setFieldValue("permission.agreement",userAcc[moduleAccess.agreement].permission);
      form.setFieldValue("permission.student",userAcc[moduleAccess.student].permission);
      form.setFieldValue("permission.company",userAcc[moduleAccess.company].permission);
      form.setFieldValue("permission.workcenter",userAcc[moduleAccess.workcenter].permission);
      form.setFieldValue("permission.subsystem",userAcc[moduleAccess.subsystem] ? userAcc[moduleAccess.subsystem].permission : 0);
      form.setFieldValue("permission.export",userAcc[moduleAccess.export] ? userAcc[moduleAccess.export].permission : 0);
    }
    form.setFieldValue('_id', employee._id);
    form.setFieldValue('name', employee.name);
    form.setFieldValue('email', employee.email ?? "");
    form.setFieldValue('phone', employee.phone ?? "");
    form.setFieldValue('position', employee.position ?? "");
    form.setFieldValue('subsystem', employee.subsystem ?? "");
    form.setFieldValue('workcenter', employee.workcenter?._id);
    form.setFieldValue('role', employee.role ?? UserRoles.LiaisonRole);
    form.setFieldValue('approvementRole', employee.approvementRole ? employee.approvementRole.name : "");
    setStatus(employee.status);
  },[form, employee])


  const handleSubmit = (values: Employee) => {
    setLoading(true);
    values.permissions = userPermissions;
    dispatch(doUpdate(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Se editó el colaborador correctamente",
        });
        navigate(EMPLOYEES_PATH);
      })
      .catch((err:Error) => {
        if (err.message && err.message.includes("status code 427")) {
          notificationController.error({
            message: "Error: Correo electrónico duplicado",
          });
        } else {
          notificationController.error({
            message: "Error al editar colaborador",
          });
        }
        setLoading(false);
      });
  };

   //workcenter
   useEffect(() => {
    if(workcenterStateStatus !== 'fulfilled'){
      dispatch(doList());
    }
    const ogOptions = workcenters.map((workCenter) => ({
      label: workCenter.name!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));
    setOptionWorkCenter(ogOptions);
  }, [dispatch, workcenterStateStatus, workcenters]);

  const handlePermissionChange = (newValue: number, arrayPos: number) => {
    const updatedUserPermissions = [...userPermissions];
    const updatedPermission = {
      ...updatedUserPermissions[arrayPos],
      permission: newValue
    };
    updatedUserPermissions[arrayPos] = updatedPermission;
    setUserPermissions(updatedUserPermissions);
  };

  const handleUserTypeChange = (newValue: UserRoles) => {
    let newUserPermissions;
    if (newValue !== UserRoles.AdminRole) {
      newUserPermissions = [
        {module: "user", permission: 1},
        {module: "agreement", permission: 2},
        {module: "student", permission: 2},
        {module: "company", permission: 2},
        {module: "workcenter", permission: 1},
        {module: "subsystem", permission: 0},
        {module: "export", permission: 1},
      ];
    } else{
      newUserPermissions = userPermissions.map((p)=>({...p, permission:AccessLvls.readWrite}));
    }
    setUserPermissions(newUserPermissions);
    form.setFieldValue("permission.user",newUserPermissions[moduleAccess.user].permission);
    form.setFieldValue("permission.agreement",newUserPermissions[moduleAccess.agreement].permission);
    form.setFieldValue("permission.student",newUserPermissions[moduleAccess.student].permission);
    form.setFieldValue("permission.company",newUserPermissions[moduleAccess.company].permission);
    form.setFieldValue("permission.workcenter",newUserPermissions[moduleAccess.workcenter].permission);
    form.setFieldValue("permission.subsystem",newUserPermissions[moduleAccess.subsystem].permission);
    form.setFieldValue("permission.export",newUserPermissions[moduleAccess.export].permission);
  }

  const filterWorkcenter = (workCenterName: string) => {
    const escapedWorkcName = escapeRegExp(workCenterName);
    const inputString= new RegExp(`${escapedWorkcName}`, 'gi');
    const newOptions = workcenters.filter(value => inputString.test(value.name)).map((workCenter) => ({
      label: workCenter.name!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));
    setOptionWorkCenter(newOptions);
  }

  const changeSubsystem = (subsystemSelected: string) => {
    const optionsRaw: OptionsSelect[] = workcenters
      .filter((workcenter) => workcenter.subsystemShort === subsystemSelected)
      .map((workCenter) => ({
        label: workCenter.name!,
        value: workCenter._id!,
        key: workCenter._id!,
      }));
    setOptionWorkCenter(optionsRaw);
    setValueSubSystem(subsystemSelected);
  };

  const handleStatusChange = (status: boolean) => {
    setStatus(status);
  }

  return (
    <BaseForm
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      requiredMark="optional"
    >
      <BaseForm.Item name="_id" hidden={true} />
      <BaseForm.Item
        name="name"
        key="name"
        label={`Nombre completo`}
        rules={[{ required: true, message: "Este campo es obligatorio" }]}
      >
        <Input placeholder="Ingrese el nombre del colaborador" />
      </BaseForm.Item>
      <BaseForm.Item
        name="email"
        key="email"
        label={`Correo electrónico`}
        rules={[
          { required: true, message: "Este campo es obligatorio" },
          {
            type: "email",
            message: "Por favor, ingresa un correo electrónico válido",
          },
        ]}
        >
          <Input placeholder="Ingrese la dirección de correo electrónico" />
      </BaseForm.Item>
      <BaseForm.Item name="phone" key="phone" label={`Teléfono`}>
        <Input placeholder="Ingrese los 10 digitos sin espacios ni diagonales" />
      </BaseForm.Item>
      <BaseForm.Item name="position" label={`Puesto`}>
        <Input placeholder="Ingrese el puesto del colaborador" />
      </BaseForm.Item>
      <Row>
        <Col style={{ marginRight: 25 }}>
          <BaseForm.Item label={`Subsistema`} name="subsystem" key="subsystem">
            <Select
              style={{ width: 200 }}
              value={valueSubSystem}
              onChange={changeSubsystem}
              placeholder="Sub-Sistema"
              options={optionsSubsystem}
            />
          </BaseForm.Item>
        </Col>
        <Col flex={1}>
          <BaseForm.Item name="workcenter" label={`Plantel`}>
            <Select
              style={{ width: "100%" }}
              placeholder="Plantel"
              showSearch
              options={optionWorkCenter}
              onSearch={filterWorkcenter}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </BaseForm.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col>
          <BaseForm.Item name="status" label="Activo">
            <Switch checked={status} onChange={handleStatusChange}/>
          </BaseForm.Item>
        </Col>
        <Col>
          <BaseForm.Item
            name="role"
            label={`Tipo de usuario`}
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <Select
              style={{ width: "100%" }}
              onChange={handleUserTypeChange}
              options={[
                {
                  label: "Vinculador",
                  value: "vinculador",
                  key: 0,
                },
                {
                  label: "Director de Vinculación",
                  value: "directorDeVinculacion",
                  key: 1,
                },
                {
                  label: "Director / Rector",
                  value: "director/rector",
                  key: 2,
                },
                {
                  label: "Administrador",
                  value: "administrador",
                  key: 3,
                },
              ]}
            />
          </BaseForm.Item>
        </Col>
        <Col>
          <BaseForm.Item
              name="approvementRole"
              label={`Rol de aprobación para expedientes`}
              rules={[
                { required: false, message: "Este campo es obligatorio" },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={optionApprovalRoles}
              />
            </BaseForm.Item>
          </Col>
          <Col>
          <BaseForm.Item
              name="permission.export"
              label={`Permiso para exportar expedientes`}
              rules={[
                { required: false, message: "Este campo es obligatorio" },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={[
                  {label: "No puede exportar", value: AccessLvls.readOnly},
                  {label: "Puede exportar", value: AccessLvls.readWrite},
                ]}
                defaultValue={AccessLvls.readOnly}
                value={userPermissions[moduleAccess.export].permission}
                onChange={(val) => handlePermissionChange(val,moduleAccess.export)}
              />
            </BaseForm.Item>
          </Col>
      </Row>
      <Divider orientation="left">Permisos de acceso</Divider>
            <Row gutter={[20,20]}>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.user"
                  label={`Acceso a colaboradores`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    defaultValue={AccessLvls.readOnly}
                    value={userPermissions[moduleAccess.user].permission}
                    style={{ width: "100%" }}
                    options={accessLevelsOptions}
                    onChange={(val) => handlePermissionChange(val,moduleAccess.user)}
                  />
                </BaseForm.Item>
              </Col>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.agreement"
                  label={`Acceso a expedientes`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                   defaultValue={AccessLvls.readWrite}
                   value={userPermissions[moduleAccess.agreement].permission}
                   style={{ width: "100%" }}
                   options={accessLevelsOptions}
                   onChange={(val) => handlePermissionChange(val,moduleAccess.agreement)}
                  />
                </BaseForm.Item>
              </Col>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.subsystem"
                  label={`Acceso a subsistemas`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                   defaultValue={AccessLvls.noAccess}
                   value={userPermissions[moduleAccess.subsystem] ? userPermissions[moduleAccess.subsystem].permission : 0}
                   style={{ width: "100%" }}
                   options={accessLevelsOptions}
                   onChange={(val) => handlePermissionChange(val,moduleAccess.subsystem)}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row gutter={[20,20]}>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.student"
                  label={`Acceso a alumnos`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    defaultValue={AccessLvls.readWrite}
                    value={userPermissions[moduleAccess.student].permission}
                    style={{ width: "100%" }}
                    options={accessLevelsOptions}
                    onChange={(val) => handlePermissionChange(val,moduleAccess.student)}
                  />
                </BaseForm.Item>
              </Col>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.company"
                  label={`Acceso a empresas`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                   defaultValue={AccessLvls.readWrite}
                   value={userPermissions[moduleAccess.company].permission}
                   style={{ width: "100%" }}
                   options={accessLevelsOptions}
                   onChange={(val) => handlePermissionChange(val,moduleAccess.company)}
                  />
                </BaseForm.Item>
              </Col>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.workcenter"
                  label={`Acceso a planteles`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    defaultValue={AccessLvls.readOnly}
                    value={userPermissions[moduleAccess.workcenter].permission}
                    style={{ width: "100%" }}
                    options={accessLevelsOptions}
                    onChange={(val) => handlePermissionChange(val,moduleAccess.workcenter)}
                  />
                </BaseForm.Item>
              </Col>
            </Row>

      <BaseForm.Item noStyle>
        <S.SubmitButton
          type="primary"
          htmlType="submit"
          size="large"
          loading={isLoading}
        >
          {`Actualizar`}
        </S.SubmitButton>
      </BaseForm.Item>
    </BaseForm>
  );
};
