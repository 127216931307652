import { Button, Space } from "antd";
import { Link } from "react-router-dom";
import { Employee } from "../types";

type OptionProps = {
  rowSelected: Employee;
}
export const OptionsDrawerBar = ({ rowSelected }: OptionProps) => {
  return (
    <>
      <Space>
        <Link to={`/colaboradores/editar/${rowSelected._id}`}>
          <Button type="primary">Editar</Button>
        </Link>
      </Space>
    </>
  );
};
