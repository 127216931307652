import { Student } from "components/students/types";
import { getHoursMinutesString, getWeekDiff, isValidDate } from "hooks/dateTreatment";
import { Agreement } from "../types";
import { FormInstance } from "antd";
import dayjs from "dayjs";

export const IsAdultStudent = (student: Student) => {
    const studentCURP= student.curp;
    const birth= studentCURP!.substring(4,10).match(/(\w{2})(\w{2})(\w{2})/);
    const today = new Date();

    if (birth) {
      const year =
        parseInt(birth[1]) > today.getFullYear() - 2000
          ? parseInt(birth[1]) + 1900
          : parseInt(birth[1]) + 2000;
      const month = parseInt(birth[2]);
      const day = parseInt(birth[3]);

      const minDateToBeAdult = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
      );

      const birthDate= new Date (year, month, day);

      if(minDateToBeAdult.getTime() - birthDate.getTime() >= 0){ //if true student is adult
       return true
      }
     return false;
    }
   };

   export const IsAdultStudentCurp = (curp: string) => {
    const studentCURP= curp;
    const birth= studentCURP!.substring(4,10).match(/(\w{2})(\w{2})(\w{2})/);
    const today = new Date();
 
    if (birth) {
      const year =
        parseInt(birth[1]) > today.getFullYear() - 2000
          ? parseInt(birth[1]) + 1900
          : parseInt(birth[1]) + 2000;
      const month = parseInt(birth[2]);
      const day = parseInt(birth[3]);
 
      const minDateToBeAdult = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
      );
      const birthDate= new Date (year, month, day);
      if(minDateToBeAdult.getTime() - birthDate.getTime() >= 0){ //if true student is adult
       return true
      }
     return false;
    }
   };

   export const estimateTotalHours = (startDate: string | Date, endDate: string | Date, weeklyHours: number) => {
    const weekDiff = getWeekDiff(startDate,endDate);
    const totalHoursRaw = Number((weekDiff * weeklyHours).toFixed(4));
    const hours = Math.trunc(totalHoursRaw);
    if (hours < 0 || weeklyHours < 0) {
      return false;
    }
    return hours;
   };

   export const agreementFormSetter = (agreement: Agreement, form: FormInstance) => {
    form.setFieldValue("workCenterId", agreement.workCenterId ? agreement.workCenterId._id : agreement.student?.workcenter._id);
    form.setFieldValue("principal", agreement.principal?._id);
    form.setFieldValue("studentId", agreement.studentId._id);
    form.setFieldValue("id", agreement._id);
    form.setFieldValue("companyId", agreement.companyId?._id);
    form.setFieldValue("representativeId", agreement.representativeId?._id);
    form.setFieldValue("legalRepresentativeId", agreement.legalRepresentativeId?._id);
    form.setFieldValue("hoursPerWeek", agreement.hoursPerWeek);
    form.setFieldValue("laptopSN", agreement.laptopSN);
    if(agreement.laptopDeliveryDate){
      const laptopDeliveryD = dayjs(agreement.laptopDeliveryDate);
      form.setFieldValue("laptopDeliveryDate", laptopDeliveryD);
   }
    if(agreement.endDate){
       const endD = dayjs(agreement.endDate);
       form.setFieldValue("endDate", endD);
    }
    if(agreement.startDate){
      const startD = dayjs(agreement.startDate);
      form.setFieldValue("startDate", startD);
    }
    form.setFieldValue("principalPosition", agreement.principalPosition);
    form.setFieldValue(
      "learningAgreementFileId",
      agreement.learningAgreementFileId
    );
    form.setFieldValue(
      "colaborationAgreementFileId",
      agreement.colaborationAgreementFileId
    );
    form.setFieldValue(
      "tutorAuthLetterFileId",
      agreement.tutorAuthLetterFileId
    );
    form.setFieldValue(
      "tutorIdFileId",
      agreement.tutorIdFileId
    );
    form.setFieldValue(
      "insuranceFileId",
      agreement.insuranceFileId
    );
    form.setFieldValue(
      "rotationPlanFileId",
      agreement.rotationPlanFileId
    );
    form.setFieldValue(
      "learningPositionsFieldId",
      agreement.learningPositionsFieldId
    );
    form.setFieldValue(
      "matrixFieldId",
      agreement.matrixFieldId
    );
    form.setFieldValue(
      "principalFileId",
      agreement.principalFileId
    );
    form.setFieldValue(
      "laptopAssignmentFileId",
      agreement.laptopAssignmentFileId
    );
    form.setFieldValue(
      "rotationTimes",
      agreement.rotationTimes
    );
    form.setFieldValue(
      "rotationPositions",
      agreement.rotationPositions || ''
    );
   };

  export const handleTimeChange = (form: FormInstance, setTotalHours: (hoursMin:string)=> void) => {
    if (
      form.getFieldValue("startDate") &&
      form.getFieldValue("endDate") &&
      form.getFieldValue("hoursPerWeek")
    ) {
      const hoursMinutes = estimateTotalHours(
        form.getFieldValue("startDate"),
        form.getFieldValue("endDate"),
        form.getFieldValue("hoursPerWeek") ?? 0
      );
      const hourMinutesString = getHoursMinutesString(hoursMinutes)
      setTotalHours(hourMinutesString);
    }
  };

  export const validAgreementDates = (start: string, end: string) => {
    if (!isValidDate(start) || !isValidDate(end)) {
      return false;
    }
    const dataStart = new Date(start.slice(0, 10));
    const dataEnd = new Date(end.slice(0, 10));
    const endTime = dataEnd.getTime();
    if (endTime < dataStart.getTime()) {
      return false;
    }
    return true;
  };
