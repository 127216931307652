import { Space, Tour, TourProps } from "antd";
import { Tourmodules } from "components/employees/types";
import { notificationController } from "controllers/notificationController";
import { doCompleteTour } from "store/slices/userSlice";
import { useAppDispatch } from "hooks/reduxHooks";

type InfoTourProprs = {
  onClose: () => void;
  open: boolean;
  steps: TourProps["steps"];
  block: ScrollLogicalPosition;
  module: (typeof Tourmodules)[number];
  isFirstTime: boolean;
};

export const InfoTour = ({
  onClose,
  open,
  steps,
  block,
  module,
  isFirstTime,
}: InfoTourProprs) => {
  const dispatch = useAppDispatch();
  const handleCloseTour = () => {
    onClose();
    if (isFirstTime) {
      dispatch(doCompleteTour(module))
        .unwrap()
        .catch(() => {
          notificationController.error({
            message: "Error al completar tour",
          });
        });
    }
  };
  return (
    <Space>
      <Tour
        open={open}
        onClose={handleCloseTour}
        steps={steps}
        placement="top"
        arrow={false}
        scrollIntoViewOptions={{ block: block, behavior: "smooth" }}
      />
    </Space>
  );
};
