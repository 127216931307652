export enum StudentStatuses {
  ActiveStatus = 'Activo',
  GraduatedStatus = 'Graduado',
  WaitingStatus = 'En espera',
  DeclinedStatus = 'Baja',
}

export enum AgreementStatuses {
  DraftStatus = 'Borrador',
  CorrectionStatus = 'Necesita correcciones',
  ReviewStatus = 'En revision',
  CompletedStatus = 'Completo',
}