import { ColumnsType } from "antd/es/table";
import { Course } from "types/course";

export const CourseColumns = () => {
  const CourseHeaders: ColumnsType<Course> = [
    {
      title: "Grado",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "ID del curso",
      dataIndex: "canvasCourseId",
      key: "canvasCourseId",
    },
    {
      title: "Nombre del curso",
      dataIndex: "canvasCourseName",
      key: "canvasCourseName",
    },
    {
      title: "ID del curso semilla",
      dataIndex: "seedCourseId",
      key: "seedCourseId",
    },
    {
      title: "Nombre del curso semilla",
      dataIndex: "seedCourseName",
      key: "seedCourseName",
    },
    {
      title: "Plantel",
      dataIndex: "schoolName",
      key: "schoolName",
    },
    {
      title: "Ciclo",
      dataIndex: "cycleCode",
      key: "cycleCode",
    },
  ];
  return CourseHeaders;
};
