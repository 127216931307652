import { listSeedCourses } from "api/course.api";
import { missingFieldMsg } from "constants/global";
import { useEffect, useState } from "react";
import { CanvasCourse } from "types/course";

export const courseRulesForm = () => {
  const [seedCourses, setSeedCourses] = useState<CanvasCourse[]>([]);
  const [seedState, setSeedState] = useState<'empty' | 'error' | 'success'>('empty');

  const handleSeedCourses = async () => {
    await listSeedCourses().then(seedCourses => {
      setSeedState('success');
      setSeedCourses(seedCourses);
    }).catch(() => {
      setSeedState('error');
    });
  }

  useEffect(() => {
    if(seedCourses.length === 0 && seedState === 'empty'){
      handleSeedCourses()
    }
  })

  return [
    {
      editable: true,
      label: "Grado",
      name: "grade",
      type: "inputIntNumber",
      rules: missingFieldMsg,
    },
    {
      editable: true,
      label: "ID de curso semilla (Canvas)",
      name: "seedCourseId",
      type: "select",
      options:seedCourses.map((course) => ({ label: course.name, value: course.id })),
      rules: missingFieldMsg,
    },
  ];
}
