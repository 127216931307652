import { Button, Space, Popover } from "antd";
import { StudentWithAgreement } from "../types";
import { Link } from "react-router-dom";
import { STUDENTS_PATH } from "components/router/AppRouter";
import { AGREEMENTS_PATH } from "components/router/AppRouter";

type OptionProps = {
  rowSelected: StudentWithAgreement;
  handleDeleteStudent: (value: string) => void;
}
export const OptionsDrawerBar = ({ rowSelected, handleDeleteStudent }: OptionProps) => {
  return (
    <>
      <Space>
        <Link to={`${AGREEMENTS_PATH}/nuevo/${rowSelected._id}`}>
          <Button type="primary">Nuevo Expediente</Button>
        </Link>
        <Link to={`${STUDENTS_PATH}/edit/${rowSelected._id}`}>
          <Button type="primary">Editar</Button>
        </Link>
        {!rowSelected.agreementSequence && (
          <Popover
            content={
              <Button 
                type="primary"
                danger
                onClick={() => handleDeleteStudent(rowSelected._id ?? "")}
              >
                  Confirmar
              </Button>
            }
            trigger="click"
            placement="left"
          >
            <Button type="primary" danger>Eliminar</Button>
          </Popover>
          )
        }
      </Space>
    </>
  );
};
