import React from 'react';
import { MobileHeader } from './layouts/MobileHeader';
import { useResponsive } from 'hooks/useResponsive';
// import { InfoMessage } from './InfoMessage';

interface HeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
  isTwoColumnsLayout: boolean;
}

export const Header: React.FC<HeaderProps> = ({ toggleSider, isSiderOpened }) => {
  const { isTablet } = useResponsive();

  return isTablet ? (
      // <InfoMessage message='Sistema en prueba hasta el 4 de agosto'/>
      <></>
  ) : (
   <MobileHeader toggleSider={toggleSider} isSiderOpened={isSiderOpened} />
  );
};
