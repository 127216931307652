import { Button, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

type showDocBtnPros = {
  onClick: () => void;
  label?: string;
  dataCy?: string;
};

export const ShowDocumentBtn = ({ onClick, label, dataCy }: showDocBtnPros) => (
  <Space>
    <Button
      type="link"
      onClick={onClick}
      icon={
        <FontAwesomeIcon
          icon={faFilePdf}
          style={{ color: '#ce0e2c', marginRight: '5px' }}
        />
      }
      data-cy={`${dataCy}-show-document-button`}
    >
      {label ?? 'ver documento'}
    </Button>
  </Space>
);
