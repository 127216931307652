import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { list, register, remove, update, getByCompany, getByRep, getByStudent, UpdateObject} from "api/agreements.api";
import { Agreement } from "components/Agreements/types";
import { initialValues } from "../../components/Agreements/types";
import { UserRoles } from "constants/roles";

export interface AgreementSlice {
  agreements: Agreement[];
  agreement: Agreement;
  status: 'empty' | 'fulfilled' | 'error' | 'updating';
  userRole: UserRoles;
}

const initialState: AgreementSlice = {
  agreements: [],
  agreement: initialValues,
  status: 'empty',
  userRole: UserRoles.LiaisonRole,
};

export const doRegister = createAsyncThunk(
  "agreements/register",
  async (AgreementPayload: Agreement, { rejectWithValue }) => {
    try {
      return await register(AgreementPayload)
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doList = createAsyncThunk(
  "agreements/list",
  async (_, { rejectWithValue }) => {
    try {
      return await list('')
    } catch (error) {
      return rejectWithValue(error)
    }
  }
);

export const doGetByCompany = async (companyId: string) => {
  try {
    return await getByCompany(companyId);
  } catch (error) {
    return Promise.reject(error);
  }
}

export const doGetByRep = async (representativeId: string) => {
  try {
    return await getByRep(representativeId);
  } catch (error) {
    return Promise.reject(error);
  }
}

export const doGetByStudent = async (studentId: string) => {
  try {
    return await getByStudent(studentId);
  } catch (error) {
    return Promise.reject(error);
  }
}

export const doPatch = createAsyncThunk(
  "agreements/update",
  async (AgreementsPayload: UpdateObject, { rejectWithValue }) => {
    try {
      return await update(AgreementsPayload)
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const doRemove = createAsyncThunk(
  "agreements/remove",
  async (AgreementsPayload: Agreement) =>
    remove(AgreementsPayload).then((res) => res)
);
export const doClear = createAction('agreement/clear', () => {
  return {
    payload: "clear",
  };
});

const agreementSlice = createSlice({
  name: "agreement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doRegister.fulfilled, (state, action) => {
      state.agreements.push(action.payload);
    });
    builder.addCase(doRemove.fulfilled, (state, action) => {
      state.status = 'updating';
      const index = state.agreements.findIndex(r => r._id === action.payload._id);
      delete state.agreements[index];
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.agreements = action.payload.agreements;
      state.status = 'fulfilled';
      state.userRole = action.payload.userRole;
    });
    builder.addCase(doPatch.fulfilled, (state, action) => {
      state.agreement = action.payload;
    });
    builder.addCase(doClear, (state) => {
      state.agreements = [];
      state.status = "empty";
      state.userRole=UserRoles.LiaisonRole;
    });
  },
});

export default agreementSlice.reducer;
