import { WorkCenter } from "components/workcenters/types";
import { initialValues as WorkCenetInitial } from "../workcenters/types";
import { CommentsType } from "components/Agreements/types";
import { EducativeLevel } from "components/subsystems/types";
import { StudentStatuses } from "constants/enums/modulesStatuses";
import { ApprovementRoleType } from "components/employees/types";
import { AgreementStatuses } from "constants/enums/modulesStatuses";

export const newAcademicStatus = [
  StudentStatuses.WaitingStatus,
  StudentStatuses.GraduatedStatus,
];

export const editAcademicStatus = [
  ...newAcademicStatus,
  StudentStatuses.DeclinedStatus,
];

export const validAcademicStatuses = [
  ...editAcademicStatus,
  StudentStatuses.ActiveStatus,
];

export const geneders = ["Hombre", "Mujer", "Otro"];

export type CertificateFiles = {
  educativeLevel: EducativeLevel;
  certificateFileId: string;
};

export type HoursByEducativeLevel = {
  educativeLevel: EducativeLevel;
  completedHours: number;
};

export type Student = {
  _id?: string;
  name: string;
  paternalLastname?: string;
  maternalLastname?: string;
  shift?: string;
  email: string;
  curp: string;
  grade?: number;
  career?: string;
  gender?: string;
  phone?: number;
  birthDate?: Date | string;
  tutor?: string;
  academicStatus: (typeof validAcademicStatuses)[number];
  completionDate?: Date | string;
  completedHours?: HoursByEducativeLevel[];
  certificateFiles?: CertificateFiles[];
  workcenter: WorkCenter;
  leaveMotive?: string;
  comments?: CommentsType[];
  canvasId?: number;
  consentLetterFileId?: string;
};

export type StudentWithAgreement = Student & {
  agreementSequence?: number;
  agreementStatus?: AgreementStatuses;
  approvalStep?: ApprovementRoleType;
  companyName?: string;
};

export type StudentCsv = {
  name: string;
  paternalLastName?: string;
  maternalLastName?: string;
  curp?: string;
  subsystem?: string;
  workcenter?: string;
  academicStatus?: string;
  completedHours?: string;
};

export type sendCommentObj = {
  id: string;
  comment: string;
};

export type duplicatedStudent = {
  student?: Student;
  message: string;
};

export const initialValues: Student = {
  name: "",
  email: "",
  curp: "",
  tutor: "",
  academicStatus: StudentStatuses.WaitingStatus,
  workcenter: WorkCenetInitial,
};

export const gradesString = [
  " ",
  "PRIMERO",
  "SEGUNDO",
  "TERCERO",
  "CUARTO",
  "QUINTO",
  "SEXTO",
  "SEPTIMO",
  "OCTAVO",
  "NOVENO",
  "DECIMO",
];
export const DescritpionProps = {
  bordered: true,
  column: { xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
};

export const patternCurp = new RegExp(
  "^([A-ZÑ][AEIOUXÁÉÍÓÚ][A-ZÑ]{2}\\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\\d])(\\d)$"
);
