import { Descriptions, Divider } from "antd";
import { Student, gradesString } from "components/students/types";
import { DescritpionProps } from "../types";
type AgreementStudentPropss = {
  detailStudent: Student;
};
export const AgreementStudent = ({ detailStudent }: AgreementStudentPropss) => {
  return (
    <>
      <Divider orientation='left' className='title-divider'>
        Alumno
      </Divider>
      <Descriptions {...DescritpionProps} size='small'>
        <Descriptions.Item key='name' label='Nombre'>
          {`${detailStudent.name} ${detailStudent.paternalLastname ?? ""} ${detailStudent.maternalLastname ?? ""}`}
        </Descriptions.Item>
        <Descriptions.Item key='email' label='Correo electrónico'>
          {detailStudent.email}
        </Descriptions.Item>
        <Descriptions.Item key='curp' label='CURP'>
          {detailStudent.curp}
        </Descriptions.Item>
        <Descriptions.Item key='gender' label='Género'>
          {detailStudent.gender ?? ""}
        </Descriptions.Item>

        <Descriptions.Item key='grade' label='Periodo escolar'>
          {detailStudent.grade} - {gradesString[detailStudent.grade ?? 0]}
        </Descriptions.Item>
        <Descriptions.Item key='career' label='Carrera'>
          {detailStudent.career ?? ""}
        </Descriptions.Item>
        {/* <Descriptions.Item key='completionDate' label='Fecha de Graduacion'>
          {detailStudent.completionDate
            ? valueDate(detailStudent.completionDate)
            : "No ingresada"}
        </Descriptions.Item> */}
        <Descriptions.Item key='tutor' label='Tutor'>
          {detailStudent.tutor ?? ''}{" "}
        </Descriptions.Item>
        <Descriptions.Item key='status' label='Estado'>
          {detailStudent.academicStatus}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
