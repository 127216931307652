import React, { useState } from "react";
import * as S from "./Company.styles";
import { initialValuesRep, Representative, reptype, typeRelation, typeRepresentative } from "./types";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { Drawer, Form, Input, Select } from "antd";
import { AWSFileUploader } from "components/common/FileManager/AWSFileUploader";
import { useAppDispatch } from "hooks/reduxHooks";
import { doRegister } from "store/slices/representativeSlice";
import { notificationController } from "controllers/notificationController";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";
import { Document } from "components/common/Document/Document";
import * as Sentry from "@sentry/react"

interface RepsAddProps {
  open: boolean;
  onClose: () => void;
  companyId: string;
  type?: typeof typeRepresentative[number];
}

export const RepsAdd = ({ open, onClose, companyId, type="Contact" }: RepsAddProps) => {
  const dispatch = useAppDispatch();
  const [ form ] = Form.useForm();
  //documents
  const [ showDocument, setShowDocument ] = useState(false);
  const [ pdfViewerId, setPdfViewerId ] = useState("");
  const idFileIdHook = Form.useWatch('idFileId', form);
  const powerFileIdHook = Form.useWatch('powerFileId', form);
  const [updating, setUpdating] = useState(false);

  const handleSubmit = async (values: Representative) => {
    setUpdating(true);
    await dispatch(doRegister(values))
    .unwrap()
      .then(() => {
        notificationController.success({
          message: `${typeRelation[type]} agregado exitosamente`,
        });
      })
      .catch(() => {
        notificationController.error({
          message: `Error al agregar ${typeRelation[type]}`,
        });
        Sentry.captureMessage("Not able to add representative");
      });
    setUpdating(false);
    onClose();
  };

  return (
    <Drawer
      title={`Agregar ${typeRelation[type]}`}
      placement='bottom'
      open={open}
      onClose={onClose}
    >
      <BaseForm
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        requiredMark='optional'
        initialValues={{ ...initialValuesRep, type, companyId }}
      >
        <BaseForm.Item name="companyId" hidden={true} />
        <BaseForm.Item
          name='name'
          key='name'
          label={`Nombre(s)`}
          rules={[{ required: true, message: "Este campo es obligatorio" }]}
        >
          <Input placeholder='Ingrese el nombre' />
        </BaseForm.Item>
        <BaseForm.Item
          name='phone'
          key='phone'
          label={`Teléfono`}
        >
          <Input placeholder='Ingrese el telefono de contacto' />
        </BaseForm.Item>
        <BaseForm.Item
          name='email'
          key='email'
          label={`Dirección de Correo Electrónico`}
          rules={[
            {
              type: "email",
              message: "Por favor, ingresa un correo electrónico válido",
            },
          ]}
        >
          <Input placeholder='Ingrese el email de contacto' />
        </BaseForm.Item>
        <BaseForm.Item
          name="type"
          label={`Contacto o representante`}
          rules={[
            { required: true, message: "Este campo es obligatorio" },
          ]}
        >
          <Select
            style={{ width: 150 }}
            options={reptype.map((typeOfRep) => ({
              label: typeOfRep,
              value:typeRelation[typeOfRep],
              key: typeRelation[typeOfRep],
            }))}
          />
        </BaseForm.Item>
        <BaseForm.Item
          name='position'
          key='position'
          label={`Puesto`}
          rules={[{ required: true, message: "Este campo es obligatorio" }]}
        >
          <Input placeholder='Ingrese el puesto' />
        </BaseForm.Item>
        <BaseForm.Item
          label={`Identificación Oficial`}
          name='idFileId'
        >
           { idFileIdHook && (
            <ShowDocumentBtn
            onClick={() => {
              setPdfViewerId(form.getFieldValue("idFileId") as string);
              setShowDocument(true);
            }}
          />
          )}
          <AWSFileUploader
            title="Identificación Oficial"
            bucket= 'representative-id'
            isReplacing={form.getFieldValue("idFileId")}
            onUploadCompleted={(id: string | null) => {
              form.setFieldValue("idFileId", id);
            }}
          />
        </BaseForm.Item>
        <BaseForm.Item
          label={`Poder Notarial`}
          name='powerFileId'
        >
          { powerFileIdHook && (
            <ShowDocumentBtn
            onClick={() => {
              setPdfViewerId(form.getFieldValue("powerFileId") as string);
              setShowDocument(true);
            }}
          />
          )}
          <AWSFileUploader
            title="Poder Notarial"
            bucket= 'representative-power'
            isReplacing={form.getFieldValue("powerFileId")}
            onUploadCompleted={(id: string | null) => {
              form.setFieldValue("powerFileId", id);
            }}
          />
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <S.SubmitButton type='primary' htmlType='submit' size='large' disabled={updating}>
            {`Agregar`}
          </S.SubmitButton>
        </BaseForm.Item>
        <Document
            fileId={pdfViewerId}
            open={showDocument}
            onCancel={() => setShowDocument(false)}
            onOk={() => setShowDocument(false)}
          />
      </BaseForm>
    </Drawer>
  );
};
