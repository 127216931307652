import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Company, Representative, statusCompanyDict, typeRelation, typeRepresentative } from "./types";
import { useEffect, useState } from "react";
import { notificationController } from "controllers/notificationController";
import { Button, Popover, Tag, Tooltip } from "antd";
import { doClear, doList as doListReps } from "store/slices/representativeSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getStatusLights } from "./utils";
import { CommentsModal } from "./CommentsModal";
import { statusUpdate } from "api/reps.api";
import { SendCustomErrorMessage } from "utils/Status&ErrorMessage";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { UserRoles } from "constants/roles";

type OptionProps = {
  representative: Representative;
  idCompany: Company;
  type?: typeof typeRepresentative[number];
}
export const StatusUpdateRep = ({ representative, idCompany, type }: OptionProps) => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.user);
  const { status: repstatus } = useAppSelector(
    (state) => state.representative
  );
  const [loading, setLoading] = useState(false);
  const [currentStatus, setStatus] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (repstatus !== `fulfilled`) {
      dispatch(doListReps());
    }
  }, [dispatch, repstatus]);

  function capitalizeFirstLetter(str: string) {
    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
  }

  const typeLabel = type ? typeRelation[capitalizeFirstLetter(type)] : 'representante';

  const toggle = () => {
    setActive(!active);
  };
  useEffect(() => {
    setStatus(representative.status);
  }, [representative, dispatch]);

  const sendReqChangeStatus = (status: string) => {
    setLoading(true);
    statusUpdate({
        id: representative._id?.toString() ?? '',
        status: status,
        comment: '',
    })
        .then(() => {
            notificationController.success({
                message: `${typeLabel} Actualizado(a)`,
            });
            setStatus(status);
            dispatch(doClear());
        })
        .catch((error) => {
            SendCustomErrorMessage({error: error});
        });
    setLoading(false);
  };

  return (
    <>
      {user?.role && user.role === UserRoles.AdminRole ? (
        <Popover
          style={{
            backgroundColor: "grey",
            WebkitBoxShadow: " 0px 1px 18px 0px rgba(0,0,0,0.75)",
            boxShadow: "0px 1px 18px 0px rgba(0,0,0,0.75)",
            MozBoxShadow: " 0px 1px 18px 0px rgba(0,0,0,0.75)",
          }}
          placement="left"
          content={[
            <Button
              type="primary"
              style={{ backgroundColor: "#3fb618", marginInline: 20 }}
              size="small"
              loading={loading}
              onClick={() => {
                sendReqChangeStatus("Verificada");
              }}
            >
              Verificar
            </Button>,
            <Button
              type="primary"
              loading={loading}
              danger
              size="small"
              onClick={() => {
                toggle();
              }}
            >
              Datos incompletos
            </Button>,
          ]}
          trigger="click"
        >
          <Button
            type="primary"
            size="small"
            style={{ backgroundColor: getStatusLights(currentStatus) }}
            icon={
              <Tooltip title="Click Para cambiar estado">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ color: "rgba(0,0,0,.45)", marginRight: 5 }}
                />
              </Tooltip>
            }
          >
            {statusCompanyDict[currentStatus as keyof typeof statusCompanyDict]}
          </Button>
        </Popover>
      ) : (
        <Tag color={getStatusLights(currentStatus)}>{statusCompanyDict[currentStatus as keyof typeof statusCompanyDict]}</Tag>
      )}
      <CommentsModal
        active={active}
        toggle={toggle}
        rep={representative}
        selectedRow={idCompany}
        statusCompany={idCompany.status}
      ></CommentsModal>
    </>
  );
};
