import React from 'react';
import { LoginForm } from 'components/auth/LoginForm/LoginForm';
import { PageTitle } from 'components/common/PageTitle/PageTitle';

const LoginPage: React.FC = () => {

  return (
    <>
      <PageTitle>{`Ingresar`}</PageTitle>
      <img className='logo-login' src='/logo.svg' alt="logo" />
      <LoginForm />
    </>
  );
};

export default LoginPage;
