import styled from "styled-components";
import { Card as CommonCard } from "components/common/Card/Card";
import { Button } from "antd";
import { FONT_SIZE, FONT_WEIGHT } from "styles/themes/constants";
import { AgreementStatuses } from "constants/enums/modulesStatuses";

export const Wrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
`;

export const SmallCard = styled(CommonCard)`
  margin: 1rem 1rem;
`;

export const SubmitButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const colorStatus = (status: string) => {
  switch (status) {
    case AgreementStatuses.CompletedStatus:
      return { color: "#329213" };
    case AgreementStatuses.DraftStatus:
      return { color: "#b36d19" };
    case AgreementStatuses.ReviewStatus:
      return { color: "#FF8C00" };
    case AgreementStatuses.CorrectionStatus:
      return { color: "#cc002e" };
    default:
      return { color: "#555859" };
  }
};

export const colorCompletedHours = (hours: number) => {
  if(hours >= 1000) {
    return { color: "#329213" };
  }
  return { color: "#cc002e" };
};

export const TableData = {
  rowHover: {
    backgroundColor: "red",
  },
};
