import { Button, Popover, Table, Spin } from "antd";
import React, { useEffect, useState } from "react";
import * as S from "./subsystem.styles";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { doList, doRemove } from "store/slices/subsystemSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Subsystem } from "components/subsystems/types";
import { SUBSYSTEMS_PATH } from "components/router/AppRouter";
import { filterProps } from "components/common/FilterProps";
import { ColumnsType } from "antd/es/table/interface";
import { SubsystemDetail } from "./SubsystemDetail";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const { writeSubsystems } = PermissionsToAccess;

export const SubsystemList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { subsystems, status: subsystemStateStatus } = useAppSelector(
    (state) => state.susbsystem
  );
  const [subsystemColumns, setSubsystemColumns] = useState<Subsystem[]>();
  const [selectedRowData, setSelectedRowData] = useState<Subsystem | null>(
    null
  );
  const [showDetailDrawer, setShowDetailDrawer] = useState(false);
  const [spin, setSpin] = useState<boolean>(true);

  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(doList());
    }
  }, [dispatch, subsystemStateStatus]);

  useEffect(() => {
    setSubsystemColumns(subsystems);
  }, [subsystems]);

  useEffect(() => {
    if (subsystemStateStatus === "fulfilled") {
      setSpin(false);
    }
  }, [subsystemStateStatus]);

  const onDeleteSubsys = async (data: Subsystem) => {
    const dataFiltered = subsystems.filter((subs) => subs._id === data._id);
    await doRemove(dataFiltered[0]);
    setSubsystemColumns((pre) => {
      return pre!.filter((subs) => subs._id !== data._id);
    });
    return true;
  };
  const columns: ColumnsType<Subsystem> = [
    {
      title: "Subsistema / IES",
      dataIndex: "nameShort",
      key: "nameShort",
      sorter: (a, b) =>
        a && b
          ? a.nameShort.localeCompare(b.nameShort)
          : a.nameShort
          ? a.nameShort.localeCompare("")
          : b.nameShort
          ? b.nameShort.localeCompare("")
          : "".localeCompare(""),
      ...filterProps,
      onFilter: (value, record) => {
        return record.nameShort
          ? record.nameShort
              .toLowerCase()
              .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Nombre Completo",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) =>
        a && b
          ? a.name.localeCompare(b.name)
          : a.name
          ? a.name.localeCompare("")
          : b.name
          ? b.name.localeCompare("")
          : "".localeCompare(""),
      ...filterProps,
      onFilter: (value, record) => {
        return record.name
          ? record.name
              .toLowerCase()
              .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Nivel educativo",
      dataIndex: "educativeLevel",
      key: "educativeLevel",
      sorter: (a, b) => {
        if (a && b && a.educativeLevel && b.educativeLevel) {
          return a.educativeLevel.localeCompare(b.educativeLevel);
        } else if (a && a.educativeLevel) {
          return -1;
        } else if (b && b.educativeLevel) {
          return 1;
        } else {
          return 0;
        }
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.educativeLevel
          ? record.educativeLevel
              .toLowerCase()
              .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Director(a) general / Rector(a)",
      dataIndex: "principal",
      key: "principal",
      sorter: (a, b) => {
        if (a && b && a.principal && b.principal) {
          return a.principal.localeCompare(b.principal);
        } else if (a && a.principal) {
          return -1;
        } else if (b && b.principal) {
          return 1;
        } else {
          return 0;
        }
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.principal
          ? record.principal
              .toLowerCase()
              .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Número máximo de ciclos por alumno",
      dataIndex: "maxCycles",
      key: "maxCycles",
    },
    {
      title: "Acción",
      align: "center",
      dataIndex: "",
      key: "x",
      render: (data: Subsystem) => {
        return (
          <div>
            <span
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Popover
                content={
                  <Button
                    type="primary"
                    danger
                    block
                    onClick={() => {
                      onDeleteSubsys(data);
                    }}
                  >
                    Confirmar
                  </Button>
                }
                trigger="click"
                placement="left"
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ color: "red" }}
                />
              </Popover>
            </span>
          </div>
        );
      },
    },
  ];
  
  return (
    <>
      <S.Wrapper>
        <S.Card
          id="subsystems-list"
          title={`Subsistemas / IES (Instituto de Educación Superior)`}
          padding="1.25rem"
          extra={
            CheckAccess(writeSubsystems) && [
              <Button
                type="primary"
                onClick={() => {
                  navigate(`${SUBSYSTEMS_PATH}/nuevo`);
                }}
              >
                Nuevo Subsistema / IES
              </Button>,
            ]
          }
        >
          <Spin spinning={spin} tip="Cargando..." size="large">
            <Table
              dataSource={subsystemColumns}
              columns={
                CheckAccess(writeSubsystems)
                  ? columns
                  : columns.filter((col) => col.title !== "Acción")
              }
              rowKey="_id"
              tableLayout="fixed"
              onRow={(record, ) => {
                return {
                  onClick: () => {
                    setSelectedRowData(record);
                    setShowDetailDrawer(true);
                  },
                };
              }}
            />
          </Spin>
        </S.Card>
        <SubsystemDetail
          onClose={() => {
            setShowDetailDrawer(false);
          }}
          selectedRow={selectedRowData}
          open={showDetailDrawer}
        />
      </S.Wrapper>
    </>
  );
};
