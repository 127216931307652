import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faArrowsLeftRight, 
  faListUl,
  faCircleDown,
  faGauge,
  faSchool,
  faUsers,
  faBriefcase,
  faLandmark,
  faRightFromBracket,
  faClock,
  faGraduationCap
} from '@fortawesome/free-solid-svg-icons'

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const StudentsNavigationItems: SidebarNavigationItem[] = [
  {
    title: "Todos",
    key: "allStudents",
    url: "/alumnos",
    icon: <FontAwesomeIcon icon={faListUl} />,
  },
  {
    title: "Activos",
    key: "activeStudents",
    url: "/alumnos/activos",
    icon: <FontAwesomeIcon icon={faArrowsLeftRight} />,
  },
  {
    title: "En espera",
    key: "waitingStudents",
    url: "/alumnos/en-espera",
    icon: <FontAwesomeIcon icon={faClock} />,
  },
  {
    title: "Graduados",
    key: "graduatedStudents",
    url: "/alumnos/graduados",
    icon: <FontAwesomeIcon icon={faGraduationCap} />,
  },
  {
    title: "Baja",
    key: "declinedStudents",
    url: "/alumnos/bajas",
    icon: <FontAwesomeIcon icon={faCircleDown} />,
  },
]

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: "Inicio",
    key: "dashboard",
    // TODO use path variable
    url: "/",
    icon: <FontAwesomeIcon icon={faGauge} />,
  },
  {
    title: "Colaboradores",
    key: "employees",
    url: "/colaboradores",
    icon: <FontAwesomeIcon icon={faSchool} />,
  },
  {
    title: "Base de Datos de Alumnos",
    key: "alumnos",
    icon: <FontAwesomeIcon icon={faUsers} className="submenuIcon"/>,
    children: StudentsNavigationItems,
  },
  {
    title: "Empresas",
    key: "empresas",
    url: `/empresas`,
    icon: <FontAwesomeIcon icon={faBriefcase} />,
  },
  {
    title: "Planteles",
    key: "workcenters",
    url: "/planteles",
    icon: <FontAwesomeIcon icon={faSchool} />,
  },
  {
    title: "Subsistemas / IES",
    key: "subsystems",
    url: "/subsistemas",
    icon: <FontAwesomeIcon icon={faLandmark} />,
  },
  {
    title: "Salir",
    key: "logout",
    url: "/auth/logout",
    icon: <FontAwesomeIcon icon={faRightFromBracket} />,
  }
];
