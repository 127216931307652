import {
  Card,
  Descriptions,
  Divider,
  Empty,
  Row,
  Space,
  Spin,
  Button,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Agreement } from "components/Agreements/types";
import { WorkCenter } from "components/workcenters/types";
import {
  Student,
  gradesString,
  DescritpionProps,
  HoursByEducativeLevel,
} from "../types";
import { getEdad, getHoursMinutesString, valueDate } from "hooks/dateTreatment";
import { colorStatus } from "../Student.styles";
import { AgreementsList } from "components/dashboard/AgreementsList";
import {
  doList as agreementsDoList,
} from "store/slices/agreementSlice";
import { CommentsStudent } from "./CommentsStudent";
import { CommentsOptions } from "./CommentsOptions";
import { colorCompletedHours } from "components/Agreements/Agreements.styles";
import { Document } from "components/common/Document/Document";
import { notificationController } from "controllers/notificationController";
import { createCanvasUser } from "api/student.api";
import { StudentStatuses } from "constants/enums/modulesStatuses";
import statusMessages from "statusMessages";
import { ApiError } from "types";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";
import { useNavigate } from "react-router-dom";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import { AGREEMENTS_PATH } from "components/router/AppRouter";
import "./student-detail.css";

type StudentDetailProps = {
  selectedRow: Student;
}

export const StudentDetail = ({ selectedRow }: StudentDetailProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [canvasId, setCanvasId] = useState(selectedRow.canvasId);
  const [isCanvasUpdating, setIsCanvasUpdating] = useState<boolean>(false);
  //agreements
  const { agreements, status } =
    useAppSelector((state) => state.agreement);
  const [activeStudentAgreements, setActiveStudentAgreements] =
    useState<Agreement[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLeaveMotive, setShowLeaveMotive] = useState<boolean>(false);
  // document
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");

  const filterAgreementByStudent = () => {
    setActiveStudentAgreements(
      agreements.filter((agre) => {
        return agre.studentId._id === selectedRow._id;
      })
    );
  };

  const handleRegisterStudentInCanvas = async () => {
    setIsCanvasUpdating(true);
    const { name, paternalLastname, maternalLastname, email } =
      selectedRow;
    const canvasData = {
      name: `${name}${paternalLastname ? " " + paternalLastname : ""}${
        maternalLastname ? " " + maternalLastname : ""
      }`,
      email: email,
    };
    await createCanvasUser(canvasData)
      .then((res) => {
        setIsCanvasUpdating(false);
        setCanvasId(res.canvasId);
        notificationController.success({
          message: "El estudiante se ha registrado en canvas con éxito!",
        });
        setCanvasId(res.canvasId);
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationController.error({
          message: "Ocurrió un error",
          description: statusMessages.student[currentStatus || 500] as string,
        });
        setIsCanvasUpdating(false);
      });
  };

  useEffect(() => {
    if (selectedRow.leaveMotive && selectedRow.academicStatus === StudentStatuses.DeclinedStatus) {
      setShowLeaveMotive(true);
    } else {
      setShowLeaveMotive(false);
    }
  }, []);

  useEffect(() => {
    if (status !== "fulfilled") {
      setIsLoading(true);
      dispatch(agreementsDoList());
    } else {
      filterAgreementByStudent();
      setIsLoading(false);
    }
  }, [dispatch, status, agreements]);


  //workcenter data
  const workCenterData = (item: WorkCenter): ReactNode => {
    return (
      <>
        <Divider orientation="left" className="title-divider">
          Plantel
        </Divider>
        <Descriptions bordered size="small">
          <Descriptions.Item span={3} key="subsystem" label="Subsistema / IES">
            {item.subsystem ?? "No hay Registro"}
          </Descriptions.Item>
          <Descriptions.Item span={3} key="workName" label="Plantel">
            {item.nameShort ?? ""}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            key="directorSubsystem"
            label="Director(a) general del subsistema / IES"
          >
            {item.currentSubsystemPrincipal ?? ""}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  return (
    <>
      <Spin spinning={isCanvasUpdating} tip="Cargando..." size="large">
        <Descriptions {...DescritpionProps} size="small">
          <Descriptions.Item key={"canvasid"} label="Canvas Id">
            {canvasId ?? (
              <Button
                className="link-text"
                type="link"
                onClick={handleRegisterStudentInCanvas}
              >
                Registrar en Canvas
              </Button>
            )}
          </Descriptions.Item>
          <Descriptions.Item key="name" label="Nombre(s)" span={1}>
            {`${selectedRow.name}`}
          </Descriptions.Item>
          <Descriptions.Item key="name" label="Apellido paterno" span={1}>
            {`${selectedRow.paternalLastname ?? ""}`}
          </Descriptions.Item>
          <Descriptions.Item key="name" label="Apellido materno" span={1}>
            {`${selectedRow.maternalLastname ?? ""}`}
          </Descriptions.Item>
          <Descriptions.Item key="status" label="Estado académico">
            <p style={{ color: colorStatus(selectedRow.academicStatus) }}>
              {selectedRow.academicStatus ?? "Sin registro"}
            </p>
          </Descriptions.Item>
          <Descriptions.Item key="CURP" label="CURP">
            {selectedRow.curp}
          </Descriptions.Item>
          <Descriptions.Item key="gender" label="Género">
            {selectedRow.gender ?? ""}
          </Descriptions.Item>
          <Descriptions.Item key="edad" label="Edad y Fecha de nacimiento">
            {selectedRow.birthDate
              ? `${getEdad(selectedRow.birthDate)} Años - ${valueDate(
                  selectedRow.birthDate,
                  false
                )}`
              : "No ingresada"}
          </Descriptions.Item>
          <Descriptions.Item key="completionDate" label="Fecha de finalización Edual">
            {selectedRow.completionDate
              ? valueDate(selectedRow.completionDate)
              : "No ingresada"}
          </Descriptions.Item>
          <Descriptions.Item span={3} key="email" label="Correo electrónico">
            {selectedRow.email}
          </Descriptions.Item>
          <Descriptions.Item key="phone" label="Teléfono">
            {selectedRow.phone ? selectedRow.phone : "Sin teléfono Registrado"}
          </Descriptions.Item>
          <Descriptions.Item key="career" label="Carrera">
            {selectedRow.career}
          </Descriptions.Item>
          <Descriptions.Item key="grade" label="Periodo escolar">
            {selectedRow.grade} - {gradesString[selectedRow.grade ?? 0]}
          </Descriptions.Item>
          <Descriptions.Item key="tutor" label="Nombre del Responsable">
            {selectedRow.tutor ?? ""}
          </Descriptions.Item>
          <Descriptions.Item
            key="completedHours"
            label="Horas completadas"
            span={3}
          >
            {StudentHours(selectedRow.completedHours)}
          </Descriptions.Item>
          {selectedRow.certificateFiles &&
            selectedRow.certificateFiles.length > 0 &&
            selectedRow.certificateFiles.map((certificate) => (
              <Descriptions.Item
                span={3}
                key="certificateFileId"
                label={`Certificado de finalización (${certificate.educativeLevel})`}
              >
                {certificate ? (
                  <ShowDocumentBtn
                    label={certificate.educativeLevel}
                    onClick={() => {
                      setPdfViewerId(certificate.certificateFileId);
                      setShowDocument(true);
                    }}
                  />
                ) : (
                  <>No se ha cargado el Certificado de finalización</>
                )}
              </Descriptions.Item>
            ))}
          {showLeaveMotive && (
            <Descriptions.Item key="leaveMotive" label="Motivo de baja">
              {selectedRow.leaveMotive ?? ""}
            </Descriptions.Item>
          )}
        </Descriptions>
        {canvasId && (
          <Button style={{marginTop: '10px'}} type="primary" onClick={handleRegisterStudentInCanvas}>
            Registrar en los cursos de canvas
          </Button>
        )}
        <Document
          fileId={pdfViewerId}
          open={showDocument}
          onCancel={() => setShowDocument(false)}
          onOk={() => setShowDocument(false)}
        />
        {selectedRow.workcenter
          ? workCenterData(selectedRow.workcenter)
          : "Contacta al Administrador"}
        <br />
        <Card className="card-agreements" title={`Expedientes del alumno`}>
          <Spin spinning={isLoading}>
            <>
            {activeStudentAgreements && activeStudentAgreements.length > 0 && (
              <AgreementsList agreements={activeStudentAgreements} />
            )}
            {activeStudentAgreements &&
              activeStudentAgreements.length == 0 && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
              )}
              {CheckAccess(PermissionsToAccess.writeAgreements) && [
                <Button
                  type='primary'
                  onClick={() => {
                    navigate(`${AGREEMENTS_PATH}/nuevo/${selectedRow._id}`);
                  }}
                >
                  Nuevo Expediente
                </Button>
              ]}
            </>
          </Spin>
        </Card>
        <Descriptions
          className="title-comment-header"
          bordered
          size="small"
          title={
            <Divider orientation="left" className="title-divider">
              Comentarios
            </Divider>
          }
          extra={
            <CommentsOptions
              selectedRow={selectedRow}
              visibleRegresar={false}
            />
          }
        ></Descriptions>
        {selectedRow.comments && <CommentsStudent selectedRow={selectedRow} />}
      </Spin>
    </>
  );
};

export const StudentInfo = ({ selectedRow }: StudentDetailProps) => {
  return (
    <>
      <Descriptions {...DescritpionProps} size="small" column={3}>
        <Descriptions.Item key="name" label="Nombre del estudiante" span={3}>
          {`${selectedRow.name} ${selectedRow.paternalLastname} ${selectedRow.maternalLastname}`}
        </Descriptions.Item>
        <Descriptions.Item key="name" label="Apellido paterno" span={3}>
          {`${selectedRow.paternalLastname ?? ""}`}
        </Descriptions.Item>
        <Descriptions.Item key="name" label="Apellido materno" span={3}>
          {`${selectedRow.maternalLastname ?? ""}`}
        </Descriptions.Item>
        <Descriptions.Item key="status" label="Estado académico" span={3}>
          <p style={{ color: colorStatus(selectedRow.academicStatus) }}>
            {selectedRow.academicStatus ?? "Sin registro"}
          </p>
        </Descriptions.Item>
        <Descriptions.Item key="CURP" label="CURP" span={3}>
          {selectedRow.curp}
        </Descriptions.Item>
        <Descriptions.Item key="gender" label="Género" span={3}>
          {selectedRow.gender ?? ""}
        </Descriptions.Item>
        <Descriptions.Item
          key="edad"
          label="Edad y Fecha de nacimiento"
          span={3}
        >
          {selectedRow.birthDate
            ? `${getEdad(selectedRow.birthDate)} Años - ${valueDate(
                selectedRow.birthDate,
                false
              )}`
            : "No ingresada"}
        </Descriptions.Item>
        <Descriptions.Item span={3} key="email" label="Correo electrónico">
          {selectedRow.email}
        </Descriptions.Item>
        <Descriptions.Item key="phone" label="Teléfono" span={3}>
          {selectedRow.phone ? selectedRow.phone : "Sin teléfono Registrado"}
        </Descriptions.Item>
        <Descriptions.Item key="career" label="Carrera" span={3}>
          {selectedRow.career}
        </Descriptions.Item>
        <Descriptions.Item key="grade" label="Periodo escolar" span={3}>
          {selectedRow.grade} - {gradesString[selectedRow.grade ?? 0]}
        </Descriptions.Item>
        <Descriptions.Item key="tutor" label="Nombre del Responsable" span={3}>
          {selectedRow.tutor ?? ""}
        </Descriptions.Item>
        <Descriptions.Item
          key="completedHours"
          label="Horas completadas"
          span={3}
        >
          {StudentHours(selectedRow.completedHours)}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Descriptions bordered size="small" title="Plantel">
        <Descriptions.Item
          span={3}
          key="subsystem"
          label="Subsistema del plantel"
        >
          {(selectedRow.workcenter as WorkCenter).subsystem ??
            "No hay Registro"}
        </Descriptions.Item>
        <Descriptions.Item span={3} key="workName" label="Plantel actual">
          {(selectedRow.workcenter as WorkCenter).nameShort ?? ""}
        </Descriptions.Item>
        <Descriptions.Item
          span={3}
          key="directorSubsystem"
          label="Director(a) general del subsistema / IES"
        >
          {(selectedRow.workcenter as WorkCenter).currentSubsystemPrincipal ??
            ""}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export const StudentHours = (
  StudentHoursObject: HoursByEducativeLevel[] | undefined
) =>
  StudentHoursObject
    ? StudentHoursObject.map((item) => (
        <Row>
          <Space>
            {item.educativeLevel === "SUPERIOR" ? "ES" : "EMS"}
            {">"}
            <p style={colorCompletedHours(item.completedHours ?? 0)}>
              {item.completedHours
                ? getHoursMinutesString(item.completedHours)
                : "-"}
            </p>
          </Space>
        </Row>
      ))
    : "Sin registro";
