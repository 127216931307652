import React, { useState } from "react";
import * as S from "./Company.styles";
import { Company, initialValues } from "./types";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch } from "hooks/reduxHooks";
import { Drawer, Form, Input } from 'antd';
import { doList } from "store/slices/companySlice";
import { notificationController } from "controllers/notificationController";
import { AWSFileUploader } from "components/common/FileManager/AWSFileUploader";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";
import { Document } from "components/common/Document/Document";
import { register } from "api/company.api";
import { sendNotificationError } from "utils/Status&ErrorMessage";

interface CompanyProps {
  onCompanySaved?: (companySaved: Company) => void;
  onClose: () => void;
  open: boolean;
}

export const CompanyNew = ({ onCompanySaved, onClose, open }: CompanyProps) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
   //documents
  const [ showDocument, setShowDocument ] = useState(false);
  const [ pdfViewerId, setPdfViewerId ] = useState("");
  const incFileIdHook = Form.useWatch('incFileId', form);
  const fiscalFileIdHook = Form.useWatch('fiscalFileId', form);

  const handleSubmit = (values: Company) => {
    setLoading(true);
   register(values)
     .then((companySaved) => {
       notificationController.success({
         message: 'Se agregó la empresa correctamente',
       });
       if (onCompanySaved) {
         onCompanySaved(companySaved);
       }
       form.resetFields();
       onClose();
       dispatch(doList());
     })
     .catch((error) => {
       sendNotificationError({ error });
     });
      setLoading(false);
  };

  return (
    <Drawer
      title={`Nueva Empresa`}
      placement='right'
      open={open}
      destroyOnClose
      onClose={onClose}
      width={`33%`}
    >
      <BaseForm
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        requiredMark='optional'
        initialValues={initialValues}
      >
        <BaseForm.Item
          name='name'
          key='name'
          label={`Razón social`}
          rules={[{ required: true, message: "Este campo es obligatorio" }]}
        >
          <Input placeholder='Ingrese la razón social de la empresa en mayúsculas' />
        </BaseForm.Item>
        <BaseForm.Item
          name='commercialName'
          key='commercialName'
          label={`Nombre comercial`}
          rules={[{ required: false, message: "Este campo es obligatorio" }]}
        >
          <Input placeholder='Ingrese el nombre comercial de la empresa' />
        </BaseForm.Item>
        <BaseForm.Item
          name='taxId'
          key='taxId'
          label={`RFC`}
          rules={[{ required: true, message: "Este campo es obligatorio" }]}
        >
          <Input placeholder='Ingrese el RFC para verificar los datos fiscales' />
        </BaseForm.Item>
        <BaseForm.Item
          name='industry'
          key='industria'
          label={`Sector productivo`}
          rules={[{ required: true, message: "Este campo es obligatorio" }]}
        >
          <Input placeholder='Sector productivo (ej, construccion, hoteleria, etc)' />
        </BaseForm.Item>
        <BaseForm.Item label={`Acta Constitutiva`} name='incFileId'>
        { incFileIdHook && (
            <>
              <ShowDocumentBtn
                onClick={() => {
                  setPdfViewerId(incFileIdHook);
                  setShowDocument(true);
                }}
                label="ver nuevo documento"
              />
            </>
          ) }
          <AWSFileUploader
            isReplacing={form.getFieldValue("incFileId")}
            title="Acta Constitutiva"
            bucket="company-inc"
            onUploadCompleted={(id: string | null) => {
              form.setFieldValue("incFileId", id);
            }}
          />
        </BaseForm.Item>
        <BaseForm.Item label={`Constancia de Situación Fiscal`} name='fiscalFileId'>
        { fiscalFileIdHook && (
              <ShowDocumentBtn
                label="ver nuevo documento"
                onClick={() => {
                  setPdfViewerId(fiscalFileIdHook);
                  setShowDocument(true);
                }}
              />
          ) }
          <AWSFileUploader
            title="Constancia de Situación Fiscal"
            bucket="company-fiscal"
            isReplacing={form.getFieldValue("fiscalFileId")}
            onUploadCompleted={(id: string | null) => {
              form.setFieldValue("fiscalFileId", id);
            }}
          />
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <S.SubmitButton
            type='primary'
            htmlType='submit'
            size='large'
            loading={isLoading}
          >
            {`Registrar`}
          </S.SubmitButton>
        </BaseForm.Item>
        <Document
            fileId={pdfViewerId}
            open={showDocument}
            onCancel={() => setShowDocument(false)}
            onOk={() => setShowDocument(false)}
          />
      </BaseForm>
    </Drawer>
  );
};
